import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export interface AlertDialogProp {
    open: boolean;
    handleClose?: () => void,
    handleSubmit?: () => void,
    closeButtonText?: string,
    agreeButtonText?: string,
    title: string,
    description?: string,
    hideConfirm?: boolean;

}

export default function AlertDialog(props: AlertDialogProp) { 

    return (
        <Dialog
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {props.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {props.description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={props.handleClose}>{props.closeButtonText || 'Disagree'}</Button>
            {!props.hideConfirm && <Button onClick={props.handleSubmit} autoFocus>
              {props.agreeButtonText || 'Agree'}
            </Button>}
          </DialogActions>
        </Dialog>
    );
  }