import React, { type ReactElement } from 'react';
import { Roles } from '../../utils/enums/Roles';
import { Navigate, Outlet } from 'react-router-dom';
import UnAuthorized from '../../pages/UnAuthorized/UnAuthorized';

export interface PrivateRouteProp {
  userRole?: Roles;
  routerRoles: Roles[];
  isLoggedIn: boolean;
}

export default function PrivateRoute({
  isLoggedIn,
  userRole,
  routerRoles,
}: PrivateRouteProp): ReactElement {
  const checkRoleMapping = () => {
    if (!userRole) return <Outlet />;
    if (routerRoles.includes(userRole)) return <Outlet />;
    else return <UnAuthorized />;
  };
  return !isLoggedIn ? <Navigate to="/login" /> : checkRoleMapping();
}
