import { ForkEffect, all, call, put, select, takeLatest } from "redux-saga/effects";
import { IExpense, IExpenseParams, IExpenseReq, expenseActions } from "./expense-slice";
import { RootState } from "../store";
import { GetUrlOutput, uploadData } from "aws-amplify/storage";
import { createExpense, deleteExpense, getExpenses, getExpensesSummary, updateExpense } from "../../api/expense-api";
import { PaginationWrapper } from "../../utils/dtos/pagination-wrapper";
import { AxiosResponse } from "axios";
import { getImageUrl } from "../../api/s3-bucket-api";
import { SummaryResponse } from "../../utils/dtos/summary-response.dto";

function* watchCreateExpenseAsync({payload}: {payload: Partial<IExpenseReq> & {images?: File[], _id?: string, updateEnable?: boolean}}) {
    const imageNames: string[] = [];
    try {
      if (payload.images) {
        yield put(expenseActions.updateComponentState({status: 'uploading...'}));
        const userId: string = yield select((state: RootState) => state.user._id);

        const imageUploadSagas = payload.images.map((image, index) => {
            const fileExtention = image.name.split('.').slice(-1)?.[0];
            const imageName = `${userId}/expense-image-${index}-${Date.now()}.${fileExtention}`;
            imageNames.push(imageName);

            const uploadImage =  uploadData({
                key: imageName,
                data: image,
                options: {
                    accessLevel: 'private'
                }
            });
    
            return call(async () => uploadImage.result);
        });

        yield all(imageUploadSagas);
        
      }

      yield put(expenseActions.updateComponentState({status: 'submitting...'}));

      if (payload.receiptImages && payload.receiptImages.length > 0) {
        payload.receiptImages = [...payload.receiptImages, ...imageNames];
      } else {
        payload.receiptImages = imageNames;
      }
      
      delete payload.images;

      if (payload.updateEnable) {
        const _id = payload._id!;

        delete payload._id;
        delete payload.updateEnable;
        yield call(updateExpense, _id, payload as Partial<IExpenseReq>);
      } else {
        yield call(createExpense, payload);
      }

      yield put(expenseActions.updateComponentState({isExpenseCreateLoading: false}));
      yield put(expenseActions.toggleExpenseModal(false));
      yield put(expenseActions.toggleExpenseUpdateModal(false));
      const expenseFilterParams: Partial<IExpenseParams> = 
                yield select((state: RootState) => state.expense.expenseFilterParams);
      yield put(expenseActions.fetchExpenseAsync(expenseFilterParams));
      yield put(expenseActions.loadSummaryAsync(expenseFilterParams));
    } catch (err: any) {
        yield put(expenseActions.updateComponentState({
            isExpenseCreateLoading: false,
            expenseError: err.response?.data?.errors?.[0] || err.response?.data?.message || 'Server error'}))
        console.log('create expense error', {error: err.response?.data?.errors?.[0] || err.response?.data?.message || 'Server error'})
    }
    
  }

  export function* watchFetchExpense({payload}: {payload: Partial<IExpenseParams>}) {
    try {
      const response: AxiosResponse<PaginationWrapper<IExpense[]>> = yield call(getExpenses, payload); 
      yield put(expenseActions.storeExpenseRecords(response.data));
    } catch (err: any) {
      yield put(expenseActions.updateComponentState({
        isExpenseFetchLoading: false,
        expenseFetchError: err.response?.data?.errors?.[0] || err.response?.data?.message || 'Server error'}))
    }
  }

  function* getExpenseImages({payload}: {payload: string[]}) {
    const imageNameFetchCalls = payload.map(imageName => call(getImageUrl, imageName, {accessLevel: 'private'}))
    const signedImageUrls: GetUrlOutput[] = yield all(imageNameFetchCalls)
    
    yield put(expenseActions.updateImageUrls(signedImageUrls.map(urlObj => urlObj?.url?.toString())));
  }

  function* watchExpenseSummary({payload}: {payload: Partial<IExpenseParams>}) {
    try {
      const summaryData: AxiosResponse<SummaryResponse> = yield call(getExpensesSummary, payload);
      yield put(expenseActions.storeSummary(summaryData.data));
    } catch {
      console.log('summary data error');
      yield put(expenseActions.updateComponentState({isExpenseSummaryLoading  : false}))
    }
  }

  function* watchDeleteExpense({payload}: {payload: string}) {
    try {
      yield put(expenseActions.updateComponentState({isExpenseFetchLoading  : true}));
      yield call(deleteExpense, payload);
      const expenseFilterParams: Partial<IExpenseParams> = 
                yield select((state: RootState) => state.expense.expenseFilterParams);
      yield put(expenseActions.fetchExpenseAsync(expenseFilterParams));
      yield put(expenseActions.loadSummaryAsync(expenseFilterParams));
      yield put(expenseActions.updateComponentState({isExpenseDeleteLoading  : false, isExpenseFetchLoading  : false}));
    } catch {
      yield put(expenseActions.updateComponentState({isExpenseDeleteLoading  : false, isExpenseFetchLoading  : false}));
    }
  }
  
  export function* watchExpenseSagas(): Generator<ForkEffect, void> {
    yield takeLatest(expenseActions.createExpenseAsync, watchCreateExpenseAsync);
    yield takeLatest(expenseActions.fetchExpenseAsync, watchFetchExpense);
    yield takeLatest(expenseActions.getImageUrlsAsync, getExpenseImages);
    yield takeLatest(expenseActions.loadSummaryAsync, watchExpenseSummary);
    yield takeLatest(expenseActions.deleteExpenseAsync, watchDeleteExpense);
  }
  const expenseSagas = watchExpenseSagas;
  export default expenseSagas;