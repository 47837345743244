import { authReducer } from './auth/authSlice';
import { signupReducer } from './signup/signupSlice';
import { combineReducers } from '@reduxjs/toolkit';
import { userReducer } from './user/userSlice';
import { tagReducer } from './tag/tag-slice';
import { expenseReducer } from './expense/expense-slice';
import { recurrentReducer } from './recurrent/recurrent-slice';

const rootReducer = combineReducers({
  auth: authReducer,
  signup: signupReducer,
  user: userReducer,
  tag: tagReducer,
  expense: expenseReducer,
  recurrent: recurrentReducer,
});

export default rootReducer;
