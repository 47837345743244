import { Box, Button, Divider, IconButton, InputAdornment, MenuItem, Modal, Select, SxProps, TextField, TextareaAutosize, Theme, Typography, useTheme } from "@mui/material";
import {styled as materialStyled} from "@mui/material/styles";
import { theme } from "../../theme/theme";
import { Close } from "@mui/icons-material";
import { useFormik } from "formik";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useAppSelector } from "../../redux/hooks";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { tagActions } from "../../redux/tag/tag-slice";
import { TagBox } from "../TagBox/TagBox";
import { ImageSelectBox } from "../ImageSelectBox/ImageSelectBox";
import { ImageViewBox } from "../ImageViewBox/ImageViewBox";
import AlertDialog from "../AlertDialog/AlertDialog";
import { IExpense, IExpenseReq, expenseActions } from "../../redux/expense/expense-slice";
import _ from "lodash";
import { DatePicker, DateValidationError, PickerChangeHandlerContext } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { resizeFile } from "../../utils/compressImage";
import { RecurrentBox } from "../RecurrentBox/RecurrentBox";
import { recurrentActions } from "../../redux/recurrent/recurrent-slice";
import ClearIcon from '@mui/icons-material/Clear';

export interface CreateExpenceProps {
    open: boolean;
    updateEnable?: boolean;
    expense?: IExpense;
    onClose: () => void;
}

const modalStyles: SxProps<Theme> = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    maxHeight: '97vh',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '10px',
    pt: 2,
    px: 4,
    pb: 3,
    scrollbarWidth: 'none', /* Firefox */
    '&::-webkit-scrollbar': {
      display: 'none', /* WebKit */
    },
    [theme.breakpoints.down('sm')] : {
        width: '95%',
    }
};

const CustomButton = materialStyled(LoadingButton)(({ theme }: { theme: Theme }) => ({
    borderRadius:'5px',
    height:'43px',
    padding:'5px 32px',
    gap:'10px',
    textAlign:'center',
    textTransform:'none',
    color: theme.palette.white.main,
    fontWeight: 100,
    letterSpacing: 1
}));

const CustomTextField = materialStyled(TextField)(({ theme }: { theme: Theme }) => ({
    width:'100%',
    '& .MuiInputBase-root':{
      height: '38px',
      borderRadius:'10px',
    },
}));

const StyledDatePicker = materialStyled(DatePicker)(({ theme }: { theme: Theme }) => ({
    width:'100%',
    '& .MuiInputBase-root':{
      height: '38px',
      borderRadius:'10px',
    },
}));

const CustomeSelect = materialStyled(Select)(({padding}:{padding: number})=>({
    padding: padding, 
    "& .MuiSelect-select": {
    padding: padding,
    },
    "& .MuiSelect-icon": {
    padding: padding,   
    },
    borderRadius: 10,
}));

export function CreateExpense(props: CreateExpenceProps) {
    const { expense, updateEnable } = props;
    const theme = useTheme();
    const tags = useAppSelector(state => state.tag.tags);
    const recurrents = useAppSelector(state => state.recurrent.recurrents);
    const {componentState, imageUrls} = useAppSelector(state => state.expense);
    const dispatch = useDispatch();
    const [imageList, setImageList] = useState<{path: string, file: File}[]>([]);
    const [openWarning, setOpenWarning] = useState(false);
    const [isRecurrent, setIsRecurrent] = useState(!!props.expense?.recurrentPayment);

    //This is for update
    const [existImages, setExistImages] = useState<{name: string, url: string}[]>([]);

    const error = _.get(componentState, 'expenseError', '');
    const isLoading = _.get(componentState, 'isExpenseCreateLoading', false) as boolean;
    const submitStatus = _.get(componentState, 'status', false) as boolean;

    const formik = useFormik({
        initialValues: {
            name: updateEnable ? expense!.name : '',
            description: updateEnable ? expense?.description || '' : '',
            amount: updateEnable ? expense?.amount?.toString() || '' : '',
            tagId: updateEnable ? expense?.tags?._id : '',
            recurrentId: updateEnable ? expense?.recurrentPayment?._id : '',
            expenseDate: updateEnable ? dayjs(expense?.expenseDate) : dayjs(),
        },
        validationSchema: yup.object({
          name: yup
            .string()
            .max(30)
            .required('Expense short description is required'),
          description: yup
            .string().optional(),
          amount: yup.number().required('Amount is required'),
          tagId: !isRecurrent ? yup.string().required('Tag is required') : yup.string().optional(),
          recurrentId: isRecurrent ? yup.string().required('Recurrent is required') : yup.string().optional(),
        }),
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values) => {
            const images = imageList.map(image => image.file)
            let expenseRecord = {
                ...values, 
                tagId: !isRecurrent ? values.tagId : '',
                recurrentId: isRecurrent ? values.recurrentId : '',
                amount: parseFloat(values.amount), 
                expenseDate: values.expenseDate?.toDate(), 
                images} as Partial<IExpenseReq> & {images: File[], _id?: string, updateEnable?: boolean};
            if (updateEnable && expense) {
                expenseRecord._id = expense._id;
                expenseRecord.receiptImages = existImages.map(img => img.name);
                expenseRecord.updateEnable = updateEnable;
            }
            dispatch(expenseActions.createExpenseAsync(expenseRecord))
        },
    });
    
    useEffect(() => {
        dispatch(tagActions.getTagAsync());
        dispatch(recurrentActions.getRecurrentAsync());
    }, [dispatch]);

    useEffect(() => {
        //this is image manipulation for update
        if (updateEnable && expense!.receiptImages) {
           const imageObjs:{name: string, url: string}[] = []
           for(const index in imageUrls) {
                const existImage = {name: expense!.receiptImages[+index], url: imageUrls[+index]};
                imageObjs.push(existImage)
            } 
            setExistImages(imageObjs);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageUrls]);

    const onImageAdd = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        if (imageList.length >= 5) {
            setOpenWarning(true);
            return;
        }
        const file = event.target.files?.[0];

        if (file) {
            
        const resizedFile = await resizeFile(file, 1000, 1000);
        // Use FileReader to read the selected image as data URL
            const reader = new FileReader();

                reader.onload = async (e) => {
                    // Update the state with the data URL of the selected image
                    const imageObject = {
                        path: e?.target?.result as string, 
                        file: resizedFile,
                    }
                    setImageList([...imageList, imageObject]);
                };
            
            reader.readAsDataURL(file);
        }
    }

    const onDeleteImage = (index: number) => {
        setTimeout(() => {
            let images = imageList;
            images.splice(index, 1);
            setImageList([...images]);
        }, 200);
        
    }

    //This is for update expense
    const onDeleteExistImageImage = (index: number) => {
        setTimeout(() => {
            let images = existImages;
            images.splice(index, 1);
            setExistImages([...images]);
        }, 200);
        
    }

    const onCloseExpenseModal = () => {
        formik.resetForm();
        setImageList([]);
        props.onClose();
    }

    const handleDateChange = (date: Dayjs | null, context: PickerChangeHandlerContext<DateValidationError>) => {
        const error = context.validationError;
        if (!error) {
            formik.setFieldValue('expenseDate', date);
            return;
        }
        formik.setErrors({expenseDate: error})
        
      };

    const openTagModal = () => {
        dispatch(tagActions.toggleTagModal(true));
    }

    const openRecurrentModal = () => {
        dispatch(recurrentActions.toggleRecurrentModal(true));
    }

    const handleShortDescClear = () => {
        formik.setValues({
            ...formik.values, name: ''
        })
    }

    useEffect(() => {
        formik.setValues({...formik.values, 
            tagId: updateEnable ? expense?.tags?._id : '',
            recurrentId: updateEnable ? expense?.recurrentPayment?._id : '',
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRecurrent])

    useEffect(() => {

        if (formik.values.recurrentId && !formik.values.name) {
            const recurrentId = formik.values.recurrentId;
            const recurrent = recurrents?.find(recurrent => recurrent._id === recurrentId);
            formik.setValues({...formik.values, name: recurrent?.name||''});
        }

        if (formik.values.tagId && !formik.values.name) {
            const tagId = formik.values.tagId;
            const tag = tags?.find(tag => tag._id === tagId);
            formik.setValues({...formik.values, name: tag?.name||''});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.recurrentId, formik.values.tagId])

    return (
        <Modal
            open={props.open}
            aria-labelledby="create expense"
            aria-describedby="This modal use to create expense">
            <Box sx={{...modalStyles}}>
                <Close onClick={onCloseExpenseModal} sx={{position: 'absolute', right: '10px', cursor:"pointer"}}/>
                <Typography variant='h5' 
                        fontSize='22px'  
                        fontWeight='600'   
                        textAlign='left' 
                        sx={{opacity: 0.8}}>
                    {updateEnable ? 'Update' : 'Create'} Expense
                </Typography>
                <Box mt={2} display='flex' flexDirection='column'>
                    <Typography mb={0.5} variant='meta' color={'gray'} >Select type </Typography>
                    <Box display='flex' flexDirection='row'>
                        <Button sx={{marginRight: 1}} onClick={() => setIsRecurrent(false)} variant={!isRecurrent ? 'contained' : 'outlined'} color='primary'>
                            Normal
                        </Button>
                            <Divider orientation="vertical" flexItem />
                        <Button onClick={() => setIsRecurrent(true)} variant={isRecurrent ? 'contained' : 'outlined'} color='primary'> Recurrent</Button>
                    </Box>
                </Box>
                {!isRecurrent && <Box mt={2} display='flex' flexDirection='column'>
                    <Typography mb={0.5} variant='meta' color={'gray'} >Select Tag* </Typography>
                    <CustomeSelect
                        type='text'
                        name="tagId"
                        placeholder='Select a tag'
                        fullWidth
                        value={formik.values.tagId}
                        padding={formik.values.tagId ? 0 : 4}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.tagId && Boolean(formik.errors.amount)}
                        variant="outlined"
                        displayEmpty
                        renderValue={(selected) =>{
                            const tag = tags?.find((x: any) => x._id === selected);
                            if (!tag) { return <em>Select Tag</em>}
                            return <TagBox color={tag!.color} tagName={tag!.name} id={tag!._id} isNoneBordered={true}/>
                        }}
                    >
                         <MenuItem disabled value="">
                            <em>Select Tag</em>
                        </MenuItem>
                        {tags?.map(tag => (
                            <MenuItem key={tag._id} value={tag._id} style={{padding: 0}}>
                                <TagBox id={tag._id} 
                                        color={tag.color} 
                                        tagName={tag.name}
                                        isNoneBordered={true}/>
                            </MenuItem>
                        ))}
                    </CustomeSelect>
                </Box>}

                {!isRecurrent && <Box display='flex' flexDirection='column' alignItems="flex-end">
                    <Button sx={{width: '70px'}} onClick={openTagModal}>Add Tag</Button>
                </Box>}

                {isRecurrent && <Box mt={2} display='flex' flexDirection='column'>
                    <Typography mb={0.5} variant='meta' color={'gray'} >Select Recurrent* </Typography>
                    <CustomeSelect
                        type='text'
                        name="recurrentId"
                        placeholder='Select a tag'
                        fullWidth
                        value={formik.values.recurrentId}
                        padding={formik.values.recurrentId ? 0 : 4}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.recurrentId && Boolean(formik.errors.recurrentId)}
                        variant="outlined"
                        displayEmpty
                        renderValue={(selected) =>{
                            const recurrent = recurrents?.find(x => x._id === selected);
                            if (!recurrent) { return <Typography sx={{opacity: 0.6}} >Select Recurrent</Typography>}
                            return <RecurrentBox recurrent={recurrent}
                                     width={'none'}
                                    isNoneBordered onlyMainInfo/>
                        }}
                    >
                         <MenuItem disabled value="">
                            Select Recurrent
                        </MenuItem>
                        {recurrents?.map(recurrent => (
                            <MenuItem key={recurrent._id} value={recurrent._id} style={{padding: 0}}>
                                <RecurrentBox recurrent={recurrent}
                                     width={'none'}
                                    isNoneBordered onlyMainInfo/>
                            </MenuItem>
                        ))}
                    </CustomeSelect>
                </Box>}

                {isRecurrent && <Box display='flex' flexDirection='column' alignItems="flex-end">
                    <Button sx={{width: '120px'}} onClick={openRecurrentModal}>Add Recurrent</Button>
                </Box>}
                
                <Box mb={2} display='flex' flexDirection='column'>
                    <Typography mb={0.5} variant='meta' color={'gray'} >Expend amount* </Typography>
                    <CustomTextField
                        type='number'
                        name="amount"
                        placeholder='Expend amount'
                        fullWidth
                        value={formik.values.amount}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.amount && Boolean(formik.errors.amount)}
                        helperText={formik.touched.amount && formik.errors.amount}
                        variant="outlined"
                        FormHelperTextProps={{style:{...theme.typography.meta}}}
                    />
                </Box>
                <Box my={2} display='flex' flexDirection='column'>
                    <Typography mb={0.5} variant='meta' color={'gray'} >Expense Short Description* </Typography>
                    <CustomTextField
                        type='text'
                        name="name"
                        placeholder='Short Description'
                        fullWidth
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        variant="outlined"
                        FormHelperTextProps={{style:{...theme.typography.meta}}}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {formik.values.name && (
                                  <IconButton onClick={handleShortDescClear} size="small">
                                    <ClearIcon />
                                  </IconButton>
                                )}
                              </InputAdornment>
                            ),
                          }}
                    />
                </Box>
                <Box my={2} display='flex' flexDirection='column'>
                    <Typography mb={0.5} variant='meta' color={'gray'} >Description </Typography>
                    <TextareaAutosize
                        name="description"
                        placeholder='Description'
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={{width: '100%', 
                                padding: 10, 
                                borderRadius:10, 
                                borderColor: theme.palette.secondary.dark,
                                fontSize: 15,
                                fontFamily: 'satoshi'
                            }}
                        minRows={2}
                    />
                </Box>

                <Box my={2} display='flex' flexDirection='column'>
                    <Typography mb={0.5} variant='meta' color={'gray'} >Select Expense Date </Typography>
                    <StyledDatePicker
                        name="expenseDate" 
                        defaultValue={dayjs()} 
                        onChange={handleDateChange}
                        value={formik.values.expenseDate}
                        disableFuture={true}
                        />
                </Box>
                <Box my={2}>
                    <Typography variant='meta' color={'gray'} >Reciept Images </Typography>
                    <Box mt={1} display='flex' flexDirection='row' flexWrap="wrap">
                        {updateEnable && existImages.map((imageObj, index) => 
                            <ImageViewBox key={index} width="80px" height="100px" src={imageObj.url} onDelete={() => onDeleteExistImageImage(index)}></ImageViewBox>)}
                        {imageList.map((image, index) => (
                            <ImageViewBox key={index} width="80px" height="100px" src={image.path} onDelete={() => onDeleteImage(index)}></ImageViewBox>
                        ))}
                        <ImageSelectBox width="80px" height="100px" onChange={onImageAdd}></ImageSelectBox>
                    </Box>
                </Box>  
                
                <Box mt={4} display='flex' justifyContent='flex-end' flexDirection="row" gap={1}>
                    <Button onClick={onCloseExpenseModal}>Close</Button>
                    <CustomButton
                        loading={isLoading}
                        variant="contained"
                        color="primary"
                        onClick={() => formik.handleSubmit()}>
                        {updateEnable ? 'Update' : 'Create'} Expense
                    </CustomButton>
                </Box>
                {error && <Typography display='flex' justifyContent='flex-end' variant='meta' color={theme.palette.error.main} mr={3}>{error }</Typography>}
                {submitStatus && isLoading && <Typography display='flex' justifyContent='flex-end' variant='meta' color={theme.palette.primary.main} mr={3}>{submitStatus}</Typography>}
                <AlertDialog open={openWarning} 
                            title="Too much Images" 
                            description="Maximum 5 images allowed" 
                            closeButtonText="OK"
                            handleClose={() => setOpenWarning(false)}
                            hideConfirm></AlertDialog>
            </Box>
        </Modal>
    )
}