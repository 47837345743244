import React, {useEffect, type ReactElement, type ReactNode } from 'react';
import { Box, IconButton, Menu, MenuItem, Toolbar, Typography, List, ListItem, useTheme, Theme, CSSObject, Button } from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { authActions } from '../../redux/auth/authSlice';
import {theme} from "../../theme/theme";
import {styled as materialStyled} from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import {Link, useNavigate} from "react-router-dom";
import QuestionCircleSVG from "../../assets/img/question-circle-linear.svg";
import { SIDEBAR_OPTIONS } from '../../utils/sidebar-schema';
import {useAuth} from "../../context/AuthContext";
import MenuIcon from '@mui/icons-material/Menu';
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import AvatarEdit from "../AvatarEdit/AvatarEdit";
import Logo from "../../assets/img/logo.png";
import { userActions } from '../../redux/user/userSlice';
import { Add } from '@mui/icons-material';
import { CreateExpense } from '../CreateExpence/CreateExpence';
import { expenseActions } from '../../redux/expense/expense-slice';
import { TagManageModal } from '../../pages/ManageTag/TagManageModal';
import { tagActions } from '../../redux/tag/tag-slice';
import { RecurrentManageModal } from '../../pages/RecurrentPayments/RecurrentManageModal';
import { recurrentActions } from '../../redux/recurrent/recurrent-slice';
import { MobileTabs } from '../MobileTabs/MobileTabs';

interface BlankLayoutProps {
  children: ReactNode;
}


const AppName = materialStyled(Typography)(({ theme }: { theme: Theme }) => ({
  textAlign:'center',
  color:theme.palette.shades.logo,
  fontWeight: 700,
  [theme.breakpoints.down('sm')]: {
    
  }
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `0px`,
  [theme.breakpoints.up('sm')]: {
    width: drawerWidth,
  },
});


interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const drawerWidth = 240;
const AppBar = materialStyled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  marginLeft: 0,
  width: `100%`,
}));

const Drawer = materialStyled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const FooterNote = materialStyled(Typography)(({ theme }: { theme: Theme }) => ({
  textAlign:'center',
  color: `${theme.palette.info.main}66`
}));

const CustomContainer = materialStyled(Box)(({ theme }: { theme: Theme }) => ({
  overflow: 'auto',
  height: 'calc(100vh - 64px)',
  flexGrow: 1,
  padding:'25px',
  marginTop: '64px',
  borderRadius:'40px 0 0 0',
  backgroundColor: theme.palette.secondary.main,
  [theme.breakpoints.down('sm')]: {
    borderRadius:'0',
    padding:'0px',
  }
}));

const CustomButton = materialStyled(Button)(({ theme, isselected }: { theme: Theme, isselected:string }) => ({
  width: '200px',
  height:'40px',
  justifyContent:'left',
  paddingLeft:'15px',
  borderRadius:'10px',
  textTransform:'none',
  backgroundColor:isselected==='true'? theme.palette.secondary.main: 'inherit',
  color: isselected==='true'? theme.palette.primary.main : theme.palette.info.main
}));

const imageBoxstyle = {
  width: '28px',
  marginTop: '5px', 
  marginRight: '13px', 
  [theme.breakpoints.down('sm')]: {
    width:'18px',
    marginTop: '3px',
    marginRight: '8px',  
  }
}

function DashboardLayout({ children }: BlankLayoutProps): ReactElement {
  const { user } = useAuth();
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const {expenseModalOpen} = useAppSelector(state => state.expense);
  const {tagModalOpen, selectedTagInfo} = useAppSelector(state => state.tag);
  const {recurrentModalOpen, selectedRecurrentInfo} = useAppSelector(state => state.recurrent);

  const selectedOption = window.location.pathname;  

  useEffect(() => {
    if(!user._id) {
      dispatch(userActions.getUserAsync());
    }
  }, [dispatch, user._id]);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleSidebarClick = (path:string) => {
    mobileOpen && handleDrawerToggle();
    navigate(path, { replace: true });
  }
  const handleLogOut = () => {
    dispatch(authActions.logOutAsync())
  }

  const handleMyProfileOnClick = () => {
    navigate('/profile', { replace: true })
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleExpenseModalToggle = (state: boolean) => {
    dispatch(expenseActions.toggleExpenseModal(state));
  }

  const closeTagModal = () => {
    dispatch(tagActions.updateSelectedTagInfo({name: '', color: '', _id: '',description: '', isUpdated: false}));
    dispatch(tagActions.toggleTagModal(false));
  }

  const closeRecurrentModal = () => {
    dispatch(recurrentActions.updateSelectedRecurrentInfo({name: '', amount: 0, _id: '',type: 0, isUpdated: false}));
    dispatch(recurrentActions.toggleRecurrentModal(false));
  }

  return <Box display='flex'>
    <CssBaseline />
    <AppBar sx={{border:'none'}} elevation={0} variant='outlined' color='transparent' position="fixed" open={mobileOpen}>
      <Toolbar sx={{width: '100vw'}}>
        <IconButton //drawer open close icon
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' }, opacity: '50%' }}>
            <MenuIcon />
        </IconButton>
        <Box component='img' src={Logo} sx={imageBoxstyle}/>
        <AppName variant='h4' sx={{display: { xs: 'none', sm: 'flex' }}}>Fintrack</AppName>
        <AppName variant='h5' sx={{display: { xs: 'flex', sm: 'none', fontWeight: '700' }}}>Fintrack</AppName>
        {/* Create expense button */}
        <Box sx={{position: 'absolute', 
                  left: drawerWidth, 
                  marginLeft: 5, 
                  marginTop: 1, 
                  [theme.breakpoints.down('sm')]: { display: 'none'}}}
                  >
          <CustomButton
                sx={{marginTop: 2, marginBottom: 3, display: 'flex'}}
                startIcon={<Add/>}
                variant="outlined"
                isselected={'true'}
                theme={theme}
                color="primary"
                onClick={() => handleExpenseModalToggle(true)}>
            Add an Expense
          </CustomButton>
        </Box>
        
        <Box flexGrow={1} />
        <Box sx={{ display: 'flex' }}>
          <Typography marginLeft={1} variant='bodyBold' color='black'  
              sx={{ display: { xs: 'none', md: 'flex' }, marginTop: '8px', marginRight: '10px' }}>Hello, {user?.name}!
          </Typography>
          <Box marginLeft={2} flexGrow={0}>
            <Box display='flex' alignItems='center' onClick={handleOpenUserMenu}>
              <AvatarEdit
                avatar={user?.signedProfileUrl}
                editable={false}
                name={user?.name}
                font={theme.typography.h6}
                size='40px'
              />
              
              <IconButton sx={{ p: 0 }}>
                <ExpandMoreIcon />
              </IconButton>
            </Box>

            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{vertical: 'top', horizontal: 'right'}}
              keepMounted
              transformOrigin={{vertical: 'top', horizontal: 'right'}}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={() => {handleCloseUserMenu(); handleMyProfileOnClick()}}>
                <Typography variant='bodyRegular' color='info' textAlign="center">My Profile</Typography>
              </MenuItem>
              <MenuItem onClick={()=>{handleLogOut(); handleCloseUserMenu()}}>
                <Typography variant='bodyRegular' color='info' textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>

    <Drawer 
      sx={{'& .MuiPaper-root':{justifyContent:'space-between', border:'none'}}} 
      variant="permanent" open={mobileOpen}>
      <Box>
        <List sx={{marginTop:'60px', justifyContent:'center'}}>
          {Object.values(SIDEBAR_OPTIONS)
                .filter(option => option.path !== "")
                .map((option) => {
              return <ListItem key={option.name} disablePadding sx={{ display: 'block',mb:1 }}>
                <Box key={'Dashboard'} display='flex' alignItems='center'>
                  <Box sx={{width:'5px', height:'20px', borderRadius:'0 10px 10px 0',
                    backgroundColor: option.path===selectedOption? theme.palette.primary.main : 'transparent'}} ></Box>
                  <Box sx={{width:'inherit', px: 2}}>
                    <CustomButton
                      theme={theme}
                      isselected={`${option.path === selectedOption}`}
                      startIcon={<Box sx={{marginTop: 1}}><option.icon sx={{fontSize: '25px', marginRight: '5px', opacity: '70%'}}/></Box>}
                      onClick={() => handleSidebarClick(option.path)}
                    >
                      {/* <Box component='img' width='32px' src={option.path===selectedOption? option.icon.selected : option.icon.unselected}></Box> */}
                      <Typography variant={option.path===selectedOption? 'bodyBold' : 'bodyRegular'}>{option.name}</Typography>
                    </CustomButton>
                  </Box>
                </Box>
              </ListItem>
          })}
        </List>
      </Box>

      <Box paddingX={2.5} mb={1}>
        <Box mb={2} display='flex' justifyContent='left' alignItems='center'>
          <Box mx={2} component='img' src={QuestionCircleSVG}></Box>
          <Link style={{ textDecoration: 'none', color:theme.palette.info.main}} to={'/#'}><Typography variant='bodyRegular'>Support</Typography></Link>
        </Box>
        <FooterNote variant='subtitle1'>{new Date().getFullYear()} Arky Technologies.</FooterNote>
        <FooterNote variant='subtitle1'>All rights reserved.</FooterNote>
      </Box>
    </Drawer>
    {/* This is to hide side nav when click on free area */}
    {mobileOpen && <CustomContainer sx={{ background: theme.palette.black.main, 
        zIndex: 10, 
        height: '100vh', 
        width: '100vw',
        position: 'absolute',
        opacity: 0.6,
        [theme.breakpoints.up('sm')]: {
          display: 'none'
        }
      }}
      onClick={handleDrawerToggle}>
    </CustomContainer>}

    <CustomContainer>
      {children}
    </CustomContainer>
    <Box sx={{
            position: 'absolute',
            background: theme.palette.white.main,
            bottom: 0,
            display: "flex",
            alignItems: 'center',
            width: '100vw',
            flexDirection: 'column',
            borderTop: 'solid 1px',
            borderTopColor: theme.palette.grey[200],
            paddingBottom: 0.2,
            [theme.breakpoints.up('sm')]: {display:'none'}
          }}>
      <MobileTabs/>
    </Box>
    

    {expenseModalOpen && <CreateExpense open={true} onClose={() => handleExpenseModalToggle(false)}></CreateExpense>}
    <TagManageModal open={tagModalOpen} 
                      tagName={selectedTagInfo.name} 
                      color={selectedTagInfo.color} 
                      id={selectedTagInfo._id}
                      isUpdate={selectedTagInfo.isUpdated} 
                      description={selectedTagInfo.description}
                      handleClose={closeTagModal}/>

    <RecurrentManageModal open={recurrentModalOpen} 
                      recurrentName={selectedRecurrentInfo.name} 
                      amount={selectedRecurrentInfo.amount} 
                      id={selectedRecurrentInfo._id}
                      isUpdate={selectedRecurrentInfo.isUpdated} 
                      type={selectedRecurrentInfo.type}
                      handleClose={closeRecurrentModal}/>
  </Box>;
}

export default React.memo(DashboardLayout);
