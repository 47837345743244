import { Box, Divider, IconButton, Typography } from '@mui/material';
import React, { useState, type ReactElement } from 'react';
import {styled as materialStyled, SxProps, useTheme} from "@mui/material/styles";
import { Theme } from '@mui/system';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import Person2Icon from '@mui/icons-material/Person2';
import { TabHeading } from './enum/tab-headings.enum';
import ProfileForm from '../../components/ProfileForm/ProfileForm';
import InAppPassworReset from '../../components/InAppPassworReset/InAppPassworReset';

const ContainerBox = materialStyled(Box)(({ theme }: { theme: Theme }) => ({
  backgroundColor:'white',
  maxWidth:'500px',
  borderRadius:'20px',
  boxShadow: `0px 4px 32px 0px ${theme.palette.shades.shadow}`,
  [theme.breakpoints.down('sm')]: {
    padding: '1px 15px 15px',
    overflowX: 'hidden',
    minWidth: 'calc(100vw - 30px)',
    borderRadius:'0px',
    height: `calc(100vh - 124px)`,
    marginTop: '1px'
  }
}));

export default function Profile(): ReactElement {
  const tabLayouts = [ 
    {name: 'Profile', icon: Person2Icon},
    {name: 'ResetPassword', icon: EnhancedEncryptionIcon}
  ];
  
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(0);

  const isIndexEqual = (index: number) => selectedTab === index;

  const tabHeaderStyle = (index: number): SxProps<Theme> => ({
    mr: 1, 
    color: isIndexEqual(index) ? theme.palette.primary.main: theme.palette.grey[800],
    opacity: isIndexEqual(index) ? 1: 0.7,
  });

  return (
    <ContainerBox>
      <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-around'>
        {/* Top navigation tabs */}
        {tabLayouts.map((item, index) => (
            <React.Fragment key={index}>
              <Box display='flex' 
                flexDirection='row' 
                alignItems='center' 
                onClick={() => setSelectedTab(index)} 
                sx={{padding: '5px', cursor: 'pointer'}}>
                <IconButton 
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  sx={tabHeaderStyle(index)}>
                    <item.icon />
                </IconButton>
                <Typography sx={tabHeaderStyle(index)}>{item.name}</Typography>
                
              </Box>
              {/* devider */}
              {index !== tabLayouts.length -1 && <Box component='div' bgcolor='primary.main' width='1px' height='30px' sx={{ opacity: 0.5 }}/>}
            </React.Fragment>
          ))}
      </Box>
      <Divider sx={{borderColor: `${theme.palette.primary.main}50`}}/>
      {selectedTab === TabHeading.PROFILE && <ProfileForm/>}
      {selectedTab === TabHeading.RESET_PASS && <InAppPassworReset/>}
    </ContainerBox>
  );
}
