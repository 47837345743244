import { put, call, takeLatest, ForkEffect, select } from 'redux-saga/effects';
import { IUser, userActions } from './userSlice';
import { fetchUserCall, updateUserCall } from '../../api/userApi';
import { AxiosResponse } from 'axios';
import { RootState } from '../store';
import { GetUrlOutput, uploadData, } from 'aws-amplify/storage';
import { getImageUrl } from '../../api/s3-bucket-api';

function* watchUserInfo() {
  try{
      const userResponse:AxiosResponse<IUser> = yield call(fetchUserCall);
      if (userResponse.data.profileImage) {
        yield call(getProfileImage, {payload: userResponse.data.profileImage});
      }

      yield put(userActions.loadUser(userResponse.data));
  } catch (err:any) {
      console.log(err)
      yield put(userActions.updateComponentState({ 
        isPageLoading: false, 
        error: err.response?.data?.errors?.[0] || err.response?.data?.message || 'Server error'
      }));
  }
  
}

function* watchUserUpdateInfo({payload}: { payload: Partial<IUser> & {image?: File}}) {
  try {
     let imageName;
     if (payload.image) {
      const userId: string = yield select((state: RootState) => state.user._id);

      imageName = `${userId}/profile-image-${Date.now()}`;

      const uploadImage =  uploadData({
        key: imageName,
        data: payload.image,
        options: {
          accessLevel: 'private'
        }
      });

      yield call(async () => uploadImage.result);
     }

     payload.profileImage = imageName;
     delete payload.image; //delete above extra image parameter

     const response: AxiosResponse<IUser> = yield call(updateUserCall, payload);
     yield put(userActions.loadUser(response.data));
  } catch(err:any) {
    yield put(userActions.updateComponentState({ 
      isUpdatePageLoading: false, 
      error: err.response?.data?.errors?.[0] || err.response?.data?.message || 'Server error'
    }));
  }

}

function* getProfileImage({payload}: {payload: string}) {
  
  const imageUrl:GetUrlOutput = yield call(getImageUrl, payload, {accessLevel: 'private'});
  yield put(userActions.addUserImage(imageUrl.url));
} 

export function* watchUserSagas(): Generator<ForkEffect, void> {
  yield takeLatest(userActions.getUserAsync, watchUserInfo);
  yield takeLatest(userActions.updateUserAsync, watchUserUpdateInfo);
}
const userSagas = watchUserSagas;
export default userSagas;
