import React, { type ReactElement } from 'react';
import {TextField, Typography, Box, Theme, useTheme, Button} from '@mui/material';
import {Link} from 'react-router-dom';
import {styled as materialStyled} from "@mui/material/styles";
import {useFormik} from "formik";
import * as yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import SendBoldSVG from '../../assets/img/send-bold.svg';
import GreenCheckCircleSVG from '../../assets/img/check-circle-bold-duotone.svg';
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { signupActions } from '../../redux/signup/signupSlice';
import { RootState } from '../../redux/store';
import * as _ from "lodash";

const CustomTextField = materialStyled(TextField)(({ theme }: { theme: Theme }) => ({
  width:'310px',
  '& .MuiInputBase-root':{
    height: '38px',
    borderRadius:'5px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '297px',
  },
}));

const ContainerBox = materialStyled(Box)(({ theme }: { theme: Theme }) => ({
  backgroundColor:'white',
  width:'374px',
  borderRadius:'10px',
  boxShadow: `0px 4px 32px 0px ${theme.palette.shades.shadow}`,
  [theme.breakpoints.down('sm')]: {
    width: 'calc(100vw - 20px)',
  },
}));

const CustomButton = materialStyled(LoadingButton)(({ theme }: { theme: Theme }) => ({
  borderRadius:'5px',
  marginBottom:'25px',
  width:'310px',
  height:'40px',
  padding:'12px 32px',
  gap:'10px',
  textAlign:'center',
  textTransform:'none',
  [theme.breakpoints.down('sm')]: {
    width: '297px',
  },
}));

export default function ForgotPassword(): ReactElement {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const {componentState: signupState} = useAppSelector((state: RootState) => state.signup);
  const isSentSuccess = _.get(signupState, 'isResetLinkSentSuccess', false) as boolean;
  const isLoading = _.get(signupState, 'isForgetPasswordLoading', false) as boolean;
  const error = _.get(signupState, 'error', '') as boolean;


  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('Please enter a valid email address')
        .required('Email is required')
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: ({email}) => {
      dispatch(signupActions.forgetPasswordResetLinkAsync({email}));
    },
  });

  const onResendClick = () => {
    dispatch(signupActions.updateComponentState({isResetLinkSentSuccess: false}))
  }

  if(!isSentSuccess){
    return (
        <ContainerBox>
          <Typography mt={4} variant='h5' gutterBottom 
              fontSize='20px' 
              fontWeight='700' textAlign='left' color="primary"
              sx={{marginLeft: '30px', opacity: 0.9}}>Forgot Password</Typography>
          <Box display='flex' flexDirection='column' justifyContent='space-around' alignItems='center' height='90%'>
            <Typography hidden={!error} variant='meta' sx={{width: { xs: '297px', sm: '310px' }}} component='p' gutterBottom textAlign='left' color='error'>{error}</Typography>
            
            <Box my={2} display='flex' flexDirection='column'>
              <Typography mb={0.5} variant='meta'>Email <Typography component="span" color='primary'>&nbsp;*</Typography></Typography>
              <CustomTextField
                type='email'
                name="email"
                placeholder='username@mail.com'
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                FormHelperTextProps={{style:{...theme.typography.meta}}}
                variant="outlined"
              />
            </Box>


            <Box mt={1} columnGap={3} display='flex' flexDirection='column' justifyContent='space-around'>
              <CustomButton
                loading={isLoading}
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => formik.handleSubmit()}
                endIcon={<Box component='img' src={SendBoldSVG} alt='send-bold-icon'></Box>}
              >
                Send Reset Link
              </CustomButton>

              <Box mb={3} display='flex' justifyContent='center'>
                <Link style={{ textDecoration: 'none'}} to={'/login'}>
                  <Typography variant='meta' sx={{ fontSize: 13, color: theme.palette.linkBlue, fontWeight: 800}}>Remember Your Password?</Typography>
                </Link>
              </Box>
            </Box>

          </Box>
        </ContainerBox>
    );
  }
  else{
    return (
        <ContainerBox>
          <Box component='img' display='flex' margin='auto' paddingTop={4} src={GreenCheckCircleSVG} alt='green-check-circle-icon'></Box>
          <Typography mt={3} variant='h5' textAlign='center'>Password Reset Link Sent!</Typography>
          <Box m={2}>
            <Typography variant='bodyRegular' component='p' textAlign='center'>Password reset link has been sent to</Typography>
            <Typography variant='button' component='p' textAlign='center'>{formik.values.email}.</Typography>
            <Typography my={2} variant='bodyRegular' component='p' textAlign='center'>
              Please check your inbox and click on the <br/> link to reset <br/> your password.
            </Typography>
            <Box mb={6} display='flex' justifyContent='center'>
              <Button style={{ textDecoration: 'none'}} onClick={onResendClick} >
                <Typography variant='meta' sx={{ fontSize: 13, color: theme.palette.linkBlue, fontWeight: 800}}>
                  Not your email address?
                </Typography>
              </Button>
            </Box>
          </Box>
        </ContainerBox>
    );
  }
}
