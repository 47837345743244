import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Theme, Typography, useMediaQuery } from '@mui/material';
import {SxProps, styled as materialStyled} from "@mui/material/styles";
import { Divider } from '@mui/material';
import { useDispatch } from 'react-redux';
import { theme } from '../../theme/theme';
import { ExpenseFilter } from '../../components/Filters/ExpenseFilter';
import { useAppSelector } from '../../redux/hooks';
import { expenseActions } from '../../redux/expense/expense-slice';
import dayjs, { Dayjs } from 'dayjs';
import { debounce } from 'lodash';
import { TagBox } from '../../components/TagBox/TagBox';
import RefreshIcon from '@mui/icons-material/Refresh';
import _ from 'lodash';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { RecurrentBox } from '../../components/RecurrentBox/RecurrentBox';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import { convertNumberToCurrenct } from '../../utils/text-utils';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useNavigate } from 'react-router-dom';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';

const ContainerBox = materialStyled(Box)(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.white.main,
  maxWidth:'100%',
  width: '100%',
  borderRadius:'20px',
  padding: '1px 20px 20px',
  boxShadow: `0px 4px 32px 0px ${theme.palette.shades.shadow}`,
  [theme.breakpoints.down('sm')]: {
    padding: '1px 15px 15px',
    paddingBottom: '0px',
    overflowX: 'hidden',
    minWidth: 'calc(100vw - 30px)',
    borderRadius:'0px',
    height: `calc(100vh - 124px)`,
    marginTop: '1px',
  }
  
}));

const filterIconStyle = (theme: Theme): SxProps => ({
  color: theme.palette.black.main,
  opacity: 0.7,
  marginTop: 1,
  [theme.breakpoints.up('sm')]: {
    display: 'none'
  }
})

const StyledButton = materialStyled(Button)(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    height: '25px',
    borderRadius:'7px',
    marginBottom: '10px',
    marginLeft: '5px',
    padding: '7px'
  }));

function Summary() {
  const filter = useAppSelector(state => state.expense.expenseFilterParams);
  const { componentState, expensesSummary, isExpenseFilterChange} = useAppSelector(state => state.expense);
  const {daysRangeType, tagIds, fromDate, toDate, recurrentIds} = filter;
  const {fromDate: selectedFromDate,toDate: selectedToDate, totalAmount, tagsExpenses, 
        tagTotalAmount, recurrentTotalAmount, recurrentExpense } = expensesSummary;

  const isLoading = _.get(componentState, 'isExpenseSummaryLoading', false);

  const dispatch = useDispatch();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const navigate = useNavigate();

  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isOpenTagPaymentDetails, setIsOpenTagPayment] = useState(true);
  const [isOpenRecurrentPaymentDetails, setIsOpenRecurrentPayment] = useState(false);


  const debouncedFetchExpense = debounce(() => {
    dispatch(expenseActions.loadSummaryAsync({
      daysRangeType: daysRangeType, 
      fromDate: (fromDate as Dayjs)?.toDate(),
      toDate: (toDate  as Dayjs)?.toDate(),
      tagIds: tagIds,
      recurrentIds: recurrentIds
    }))
  }, 1000);

  useEffect(() => {
    debouncedFetchExpense();
    return () => {
      // Ensure the debounced function is canceled when the component unmounts
      debouncedFetchExpense.cancel();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daysRangeType,tagIds, fromDate, toDate, recurrentIds]);

  const refreshAction = () => {
    debouncedFetchExpense()
  }

  const clickOnTag = (tagId: string) => {
    dispatch(expenseActions.setFilterParams({...filter, tagIds: tagId, recurrentIds: undefined}));
    navigate('/expences');
  }

  const clickOnRecurrent = (recurrentId: string) => {
    dispatch(expenseActions.setFilterParams({...filter, recurrentIds: recurrentId, tagIds: undefined}));
    navigate('/expences');
  }

  const resetFilterParams = () => {
    dispatch(expenseActions.resetFilterParams());
  }

  return (
    <ContainerBox>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box  display='flex' flexDirection='row' justifyContent='flex-start'>
          <Typography mt={2} variant='h5' fontSize='28px' fontWeight='600' 
                    letterSpacing='1px' textAlign='left' mb={1} color="primary"
                    sx={{[theme.breakpoints.down('sm')]: {fontSize: '23px'}}}>
              Summary
          </Typography>
          
          {isLoading && !isLargeScreen && <Box mb={1.5} ml={1} display='flex' flexDirection='row' alignItems={'end'}>
            <CircularProgress size={25} thickness={6}/>
          </Box>}

          {!isLoading && !isLargeScreen && <Box mt={1} display='flex' flexDirection='row' alignItems={'end'}
            onClick={refreshAction}>
            <StyledButton startIcon={<RefreshIcon/>} variant='outlined' color="info"> Refresh</StyledButton>
          </Box>}

          {isExpenseFilterChange && !isLargeScreen && <Box mt={1} display='flex' flexDirection='row' alignItems={'end'}
            onClick={resetFilterParams}>
            <StyledButton startIcon={<FilterListIcon/>} variant='outlined' color="error"> Clear</StyledButton>
          </Box>}
        </Box>
        <Button sx={filterIconStyle(theme)} onClick={() => setFilterOpen(!isFilterOpen)}>
          {isFilterOpen ? <FilterAltOffOutlinedIcon/> : <FilterAltOutlinedIcon/>}
        </Button>
        
      </Box>
      
      {<Divider/>}
      {(isLargeScreen || isFilterOpen) && <ExpenseFilter refreshAction={refreshAction} 
                                                          isLoading={isLoading as boolean &&  isLargeScreen}
                                                          onClose={() => setFilterOpen(!isFilterOpen)}/>}

      <Box display="flex" flexDirection="column" my={2} position="relative" sx={{color: theme.palette.primary.main}}>
        <Box display="flex" flexDirection="column" flexWrap="wrap"> 
          <Box  mt={1} display="flex" flexDirection="column" sx={{gap: "5px"  }}>
            <Box>
              <CalendarMonthIcon sx={{fontSize: '15px', marginRight: '5px', opacity: 0.8}}/>
              <Typography variant='meta' mt={1} fontWeight={800}>From: </Typography>
              <Typography variant='meta' mt={1}>{dayjs(selectedFromDate).format('YYYY-MM-DD HH:mm:ss')}</Typography>
            </Box>
            <Box>
              <CalendarMonthIcon sx={{fontSize: '15px',  marginRight: '5px', opacity: 0.8}}/>
              <Typography variant='meta' mt={1} fontWeight={800}>To: </Typography>
              <Typography variant='meta' mt={1}>{dayjs(selectedToDate).format('YYYY-MM-DD HH:mm:ss')}</Typography>
            </Box>
            
          </Box>
          {/* tag payment summary */}
          {tagsExpenses && <Box mt={2} display="flex" flexDirection="row" justifyContent="start" gap="5px" maxWidth="400px">
            <LocalOfferOutlinedIcon sx={{fontSize: '20px', opacity: 0.8, marginTop: '3px'}}/>
            <Box display="flex" flexDirection="column" maxWidth="400px" width="100%">
              <Box  display="flex" flexDirection="row" maxWidth="400px" justifyContent="space-between"  onClick={() => setIsOpenTagPayment(!isOpenTagPaymentDetails)}>
                <Typography variant='meta' fontWeight={800} fontSize="15px" sx={{opacity: 0.8,}}>
                    Tag wise total
                </Typography>
                <Button sx={{padding: 0, minWidth: '24px'}}>
                  {isOpenTagPaymentDetails ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                </Button>
              </Box>
              {isOpenTagPaymentDetails &&<Box>
                {tagsExpenses?.map(tagExp => (<Box key={tagExp._id} display="flex" flexDirection="row" maxWidth="400px" justifyContent="space-between">
                  <TagBox onClick={() => clickOnTag(tagExp._id)} color={tagExp.color} id={tagExp._id} tagName={tagExp.name} isNoneBordered></TagBox>
                  <Typography variant='meta' mt={1}>{convertNumberToCurrenct(tagExp.totalAmount)} Rs</Typography>
                  
                </Box>))}
                
              </Box>}
              <Box mt={1} display="flex" flexDirection="row" maxWidth="400px" justifyContent="space-between">
                <Typography  sx={{opacity: 0.7, fontWeight: '600', fontSize: "14px"}}>Total tag amount: </Typography>
                <Typography  sx={{opacity: 0.7, fontWeight: '600', fontSize: "15px"}}>{convertNumberToCurrenct(tagTotalAmount)} Rs</Typography>
              </Box>
            </Box>
          </Box>}
          
          {/* recurrent payment summary */}
          {recurrentExpense && <Box mt={3} mb={2} display="flex" flexDirection="row" justifyContent="start" gap="5px" maxWidth="400px">
            <CreditCardIcon sx={{fontSize: '20px', opacity: 0.8}}/>
            <Box display="flex" flexDirection="column" maxWidth="400px" width="100%">
              <Box  display="flex" flexDirection="row" maxWidth="400px" justifyContent="space-between" onClick={() => setIsOpenRecurrentPayment(!isOpenRecurrentPaymentDetails)}>
                <Typography variant='meta' fontWeight={800} fontSize="15px" sx={{opacity: 0.8,}}>
                  Recurrent wise total
                </Typography>
                <Button sx={{padding: 0, minWidth: '24px'}}>
                  {isOpenRecurrentPaymentDetails ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                </Button>
              </Box>
              
              {isOpenRecurrentPaymentDetails && <Box>
                {recurrentExpense?.map(recurrent => (
                  <Box key={recurrent._id} display="flex" flexDirection="row" maxWidth="400px" justifyContent="space-between">
                    <RecurrentBox onClick={() => clickOnRecurrent(recurrent._id)} recurrent={recurrent} width={'none'} isNoneBordered onlyMainInfo/>
                    <Typography variant='meta' mt={1}>{convertNumberToCurrenct(recurrent.totalAmount)} Rs</Typography>
                    
                  </Box>))}
                
              </Box>}
              <Box mt={1} display="flex" flexDirection="row" maxWidth="400px" justifyContent="space-between">
                <Typography sx={{opacity: 0.7, fontWeight: '600', fontSize: "14px"}} >Total recurrent amount:</Typography>
                <Typography sx={{opacity: 0.7, fontWeight: '600', fontSize: "15px"}} >{convertNumberToCurrenct(recurrentTotalAmount)} Rs</Typography>
              </Box>
            </Box>
          </Box>}
          
          <Divider />
          <Box mt={1} display="flex" flexDirection="row" maxWidth="400px" justifyContent="space-between">
            <Typography fontWeight='600' >Total amount: </Typography>
            <Typography fontWeight='600' >{convertNumberToCurrenct(totalAmount)} Rs</Typography>
          </Box>

        </Box>
        
      </Box>

    </ContainerBox>
  );
}

export default Summary;