import { Box, MenuItem, Modal, Select, SxProps, TextField, Typography } from "@mui/material";
import Close from '@mui/icons-material/Close';
import {Theme, styled as materialStyled, useTheme} from "@mui/material/styles";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as yup from 'yup';
import { theme } from "../../theme/theme";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/hooks";
import _ from 'lodash';
import { RecurrentType } from "../../utils/enums/recurrent-type";
import { recurrentActions } from "../../redux/recurrent/recurrent-slice";

export interface RecurrentManageModalProps {
    open: boolean;
    isUpdate: boolean;
    amount: number,
    recurrentName: string,
    id?: string,
    type: number,
    handleClose: () => void
} 

const modalStyles: SxProps<Theme> = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    borderRadius: '10px',
    [theme.breakpoints.down('sm')] : {
        width: '90%',
    }
  };

  const CustomTextField = materialStyled(TextField)(({ theme }: { theme: Theme }) => ({
    width:'100%',
    '& .MuiInputBase-root':{
      height: '38px',
      borderRadius:'10px',
    },
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
        display: "none",
    },
    "& input[type=number]": {
        MozAppearance: "textfield",
    },
  }));

  const CustomButton = materialStyled(LoadingButton)(({ theme }: { theme: Theme }) => ({
    borderRadius:'5px',
    marginBottom:'5px',
    height:'43px',
    padding:'12px 32px',
    gap:'10px',
    textAlign:'center',
    textTransform:'none',
    color: theme.palette.white.main,
    fontWeight: 100,
    letterSpacing: 1
  }));

  const CustomeSelect = materialStyled(Select)(({padding}:{padding: number})=>({
    padding: padding,
    width: '100%',
    "& .MuiSelect-select": {
    padding: padding,
    },
    "& .MuiSelect-icon": {
    padding: padding,   
    },
    borderRadius: 10,
  }));

export function RecurrentManageModal(props: RecurrentManageModalProps) {
    const theme = useTheme();
    const dispatch = useDispatch();

    const {componentState} = useAppSelector(state => state.recurrent);
    const recurrentError = _.get(componentState, 'recurrentError', '');
    const isRecurrentsCreateLoading = _.get(componentState, 'isRecurrentsCreateLoading', false) as boolean;


    const formik = useFormik({
        initialValues: {
          recurrentName: props.recurrentName || '',
          amount: props.amount,
          type: props.type || RecurrentType.BILL
        },
        validationSchema: yup.object({
            recurrentName: yup
                .string()
                .max(50)
                .required('Name is required'),
            amount: yup
                 .number().optional(),
            type: yup
                .number().required(),
        }),
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values) => {
            if (props.isUpdate) {
                dispatch(recurrentActions.createUpdateRecurrentAsync({name: values.recurrentName!, amount: +values.amount, _id: props.id, type: values.type!}));
                return;
            }
            dispatch(recurrentActions.createUpdateRecurrentAsync({name: values.recurrentName!, amount: +values.amount, type: values.type!}));
        },
      });
    
    useEffect(() => {
        formik.setValues({recurrentName: props.recurrentName || '', amount: props.amount || 0, type: props.type || RecurrentType.BILL});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.recurrentName, props.amount, props.type])


    const handleClose = () => {
        formik.resetForm();
        props?.handleClose();
    }

    const getRecurrentType = () => {
        return [
            {name: 'Bill', value: RecurrentType.BILL},
            {name: 'Subscription', value: RecurrentType.SUBSCRIPTION},
            {name: 'Other', value: RecurrentType.OTHER},
        ]
    }

    return (
        <Modal
            open={props.open}
            aria-labelledby="update recurrent"
            aria-describedby="This modal use to create update recurrent payments"
            >
            <Box sx={{ ...modalStyles }}>
                <Close onClick={() => handleClose()} sx={{position: 'absolute', right: '10px', cursor:"pointer"}}/>
                <Typography variant='h5' 
                        fontSize='22px'  
                        fontWeight='600'   
                        textAlign='left' 
                        sx={{opacity: 0.8}}>
                    {props.isUpdate ? 'Update' : 'Create'} Recurrent Payment
                </Typography>
                <Box my={2} display='flex' flexDirection='column'>
                    <Typography mb={0.5} variant='meta' color={'gray'} >Recurrent Payment Name * </Typography>
                    <CustomTextField
                        type='text'
                        name="recurrentName"
                        placeholder='Recurrent Payment Name'
                        fullWidth
                        value={formik.values.recurrentName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.recurrentName && Boolean(formik.errors.recurrentName)}
                        helperText={formik.touched.recurrentName && formik.errors.recurrentName}
                        variant="outlined"
                        FormHelperTextProps={{style:{...theme.typography.meta}}}
                    />
                </Box>
                <Box my={2} display='flex' flexDirection='column'>
                    <Typography mb={0.5} variant='meta' color={'gray'} >Amount </Typography>
                    <CustomTextField
                        type='number'
                        name="amount"
                        placeholder='Amount'
                        fullWidth
                        value={formik.values.amount}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.amount && Boolean(formik.errors.amount)}
                        helperText={formik.touched.amount && formik.errors.amount}
                        variant="outlined"
                        FormHelperTextProps={{style:{...theme.typography.meta}}}
                        InputProps={{
                            inputProps: { min: 0 }
                        }}
                    />
                </Box>
                <Box my={2} display='flex' flexDirection='column'>
                    <Typography mb={0.5} variant='meta' color={'gray'} >Recurrent Type *</Typography>
                    <CustomeSelect
                        type='text'
                        name="type"
                        value={formik.values.type}
                        placeholder='Select a recurrent type'
                        fullWidth
                        variant="outlined"
                        padding={3}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    >
                        {getRecurrentType().map(({name, value}) => (
                            <MenuItem key={value} value={value}>{name}</MenuItem>
                        ))}
                    </CustomeSelect>
                </Box>
                {recurrentError && <Typography  mb={0.5} variant='meta' color={theme.palette.error.main}>{recurrentError}</Typography>}
                <Box mt={4} display='flex' justifyContent='flex-end'>
                    <CustomButton
                        loading={isRecurrentsCreateLoading}
                        variant="contained"
                        color="primary"
                        onClick={() => formik.handleSubmit()}>
                        {props.isUpdate ? 'Update' : 'Create'}
                    </CustomButton>
                </Box>
            </Box>
        </Modal>
    )
}