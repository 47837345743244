import { AxiosResponse } from "axios";
import { backendApiInstance } from "./backendApiInstance";
import { IUser } from "../redux/user/userSlice";

export async function fetchUserCall(): Promise<AxiosResponse<IUser>> {
    const baseUrl = process.env.REACT_APP_CLOUD_URL
    return backendApiInstance.get(baseUrl + '/users/info');
}

export async function updateUserCall(payload: Partial<IUser>): Promise<AxiosResponse<IUser>> {
  const baseUrl = process.env.REACT_APP_CLOUD_URL
  return backendApiInstance.post(baseUrl + '/users/info', payload);
}