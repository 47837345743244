import React, { useEffect, useState } from 'react';
import {
  Box,
  TextField,
  Typography,
  useTheme
} from "@mui/material";
import {styled as materialStyled, Theme} from "@mui/material/styles";
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useFormik } from 'formik';
import { RootState } from '../../redux/store';
import * as yup from 'yup';
import { LoadingButton } from '@mui/lab';
import * as _ from "lodash";
import AvatarEdit from '../AvatarEdit/AvatarEdit';
import { useAuth } from '../../context/AuthContext';
import { IUser, userActions } from '../../redux/user/userSlice';

export interface ProfileFormPros {

} 

const CustomTextField = materialStyled(TextField)(({ theme }: { theme: Theme }) => ({
  width:'100%',
  '& .MuiInputBase-root':{
    height: '38px',
    borderRadius:'10px', 
  },
}));

const CustomButton = materialStyled(LoadingButton)(({ theme }: { theme: Theme }) => ({
  borderRadius:'5 px',
  marginBottom:'5px',
  height:'43px',
  padding:'12px 32px',
  gap:'10px',
  textAlign:'center',
  textTransform:'none',
}));

function ProfileForm() {
  const theme = useTheme();
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const { company, jobTitle, name, componentState, email, financialMonthStartDate } = useAppSelector((state: RootState) => state.user);

  const error = _.get(componentState, 'error', '');
  const isLoading = _.get(componentState, 'isUpdatePageLoading', false) as boolean;

  const [profileImage, setProfileImage] = useState<string>('');
  const [imageFile, setImageFile] = useState<File | undefined>(undefined);

  const handleImageChange = (event:  React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setImageFile(file);

    if (file) {
      // Use FileReader to read the selected image as data URL
      const reader = new FileReader();

      reader.onload = (e) => {
        // Update the state with the data URL of the selected image
        setProfileImage(e?.target?.result as string);
      };
      
      reader.readAsDataURL(file);
    }
  };


  const formik = useFormik({
    initialValues: {
      name: name,
      email: email,
      jobTitle: jobTitle,
      company: company,
      financialMonthStartDate: financialMonthStartDate || 1,
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .required('Name is required'),
      email: yup
        .string(),
      jobTitle: yup
        .string().optional(),
      company: yup
        .string().optional(),
      financialMonthStartDate: yup
        .number().min(1).max(30).optional()
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      const payload: any = {};

      //to only add filled values
      Object.entries(values).forEach(([key, value]) => {
        if (value && key !== 'email') {
            payload[key] = value;
        }
      });
      payload.image = imageFile;
      dispatch(userActions.updateUserAsync(payload as Partial<IUser> & {image?: File}));
    },
  });

  useEffect(() => {
    formik.setValues({company, jobTitle, name, email, financialMonthStartDate});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, jobTitle, name, email, financialMonthStartDate]) 

  return (
      <Box mb={2} sx={{paddingTop: '20px !important', padding: { xs: '10px', sm: '30px' }, }} >
        <Typography variant='h5' fontSize='28px'  fontWeight='600' letterSpacing='1px' textAlign='left' sx={{opacity: 0.8}}>Profile</Typography>
        <Box display='flex' flexDirection='column' justifyContent='space-around'  height='90%'>
          <Box>
            <Box my={2}>
              <AvatarEdit
                avatar={user?.signedProfileUrl}
                editable={true}
                name={user?.name}
                font={theme.typography.h2}
                size='100px'
                selectedAvatar={profileImage || ''}
                onChange={(value) => handleImageChange(value)}
              />
            </Box>
            <Box mb={2} display='flex' flexDirection='column'>
              <Typography mb={0.5} variant='meta' color={'gray'} >Email </Typography>
              <CustomTextField
                type='email'
                name="email"
                placeholder='username@mail.com'
                fullWidth
                disabled={true}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                variant="outlined"
                FormHelperTextProps={{style:{...theme.typography.meta}}}
              />
              <Typography mt={0.5} variant='meta' color={'gray'} fontSize='13px' >Can not change email </Typography>
            </Box>
            <Box mb={2} display='flex' flexDirection='column'>
              <Typography mb={0.5} variant='meta' >Name <Typography component="span" color='primary'>&nbsp;*</Typography></Typography>
              <CustomTextField
                type='text'
                name="name"
                placeholder='name'
                fullWidth
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                variant="outlined"
                FormHelperTextProps={{style:{...theme.typography.meta}}}
              />
            </Box>
            <Box mb={2} display='flex' flexDirection='column'>
              <Typography mb={0.5} variant='meta' >Job Title</Typography>
              <CustomTextField
                name="jobTitle"
                placeholder='Ex: Software Engineer'
                fullWidth
                value={formik.values.jobTitle}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.jobTitle && Boolean(formik.errors.jobTitle)}
                helperText={formik.touched.jobTitle && formik.errors.jobTitle}
                variant="outlined"
                FormHelperTextProps={{style:{...theme.typography.meta}}}
              />
            </Box>
            <Box mb={2} display='flex' flexDirection='column'>
              <Typography mb={0.5} variant='meta' >Company</Typography>
              <CustomTextField
                name="company"
                placeholder='Company'
                fullWidth
                value={formik.values.company}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.company && Boolean(formik.errors.company)}
                helperText={formik.touched.company && formik.errors.company}
                variant="outlined"
                FormHelperTextProps={{style:{...theme.typography.meta}}}
              />
            </Box>
            <Box display='flex' flexDirection='column'>
              <Typography variant='meta' >Financial Month Start Date</Typography>
              <CustomTextField
                name="financialMonthStartDate"
                placeholder='Financial Month Start Date'
                type='number'
                fullWidth
                value={formik.values.financialMonthStartDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.financialMonthStartDate && Boolean(formik.errors.financialMonthStartDate)}
                helperText={formik.touched.financialMonthStartDate && formik.errors.financialMonthStartDate}
                variant="outlined"
                FormHelperTextProps={{style:{...theme.typography.meta}}}
              />
              {!Boolean(formik.errors.financialMonthStartDate) && <Typography mb={0.5} fontSize={'10px'} variant='meta' >
                The day that you consider as new financial month Ex: salary recieving day
              </Typography>}
            </Box>
          </Box>
          <Typography mt={2} mb={0} variant='meta' hidden={!error} component='p' gutterBottom textAlign='start' color='error'>{error}!</Typography>
          <Box mt={4} mb={3} sx={{width:"100%"}} display='flex' flexDirection='column' alignItems='flex-end'>
            <CustomButton
              sx={{width:"100px"}}
              loading={isLoading}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => formik.handleSubmit()}
            >
              Save
            </CustomButton>
          </Box>
        </Box>
      </Box>
  );
}

export default ProfileForm;