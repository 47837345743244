import React, { useEffect, useState } from 'react';
import {Theme, styled as materialStyled, useTheme} from "@mui/material/styles";
import { Box, CircularProgress, Divider, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Add from '@mui/icons-material/Add';
import { useAppSelector } from '../../redux/hooks';
import { useDispatch } from 'react-redux';
import { tagActions } from '../../redux/tag/tag-slice';
import { TagBox } from '../../components/TagBox/TagBox';
import _ from 'lodash';
import AlertDialog from '../../components/AlertDialog/AlertDialog';

const ContainerBox = materialStyled(Box)(({ theme }: { theme: Theme }) => ({
  // display: 'flex',
  backgroundColor:'white',
  maxWidth:'600px',
  borderRadius:'20px',
  boxShadow: `0px 4px 32px 0px ${theme.palette.shades.shadow}`,
  padding: '20px',
  [theme.breakpoints.down('sm')]: {
    overflow: 'hidden',
    minWidth: 'calc(100vw - 30px)',
    borderRadius:'0px',
    height: `calc(100vh - 124px)`,
    marginTop: '1px',
    paddingTop: '10px'
  }
}));

const CustomButton = materialStyled(LoadingButton)(({ theme }: { theme: Theme }) => ({
  borderRadius:'10px',
  marginBottom:'5px',
  height:'43px',
  padding:'12px 32px',
  gap:'10px',
  textAlign:'center',
  textTransform:'none',
}));

function ManageTag() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const tagModalOpen = useAppSelector(state => state.tag.tagModalOpen);
  const {tags, componentState} = useAppSelector(data => data.tag);

  const isTagLoading = _.get(componentState, 'isTagsLoading', false);
  const [deleteTag, setDeleteTag] = useState({name: '', id: '', isOpen: false});

  const openModal = () => {
    dispatch(tagActions.toggleTagModal(true));
  }

  const handleUpdate = ({name,color, id, description}: {name: string, color: string, id: string, description?: string}) => {
    dispatch(tagActions.updateSelectedTagInfo({name, color,_id: id, description: description||'', isUpdated: true}));
    dispatch(tagActions.toggleTagModal(true));
  }

  const handleDeleteTag = (id: string, name: string) => {
    setDeleteTag({name: name, id: id, isOpen: true})
  }

  const handleDeleteTagWarningClose = () => {
    setDeleteTag({name: '', id: '', isOpen: false})
  }

  const handleDeleteTagWarningAgree = () => {
    dispatch(tagActions.deleteTagAsync(deleteTag.id));
    setDeleteTag({name: '', id: '', isOpen: false});
  }

  useEffect(() => {
    dispatch(tagActions.getTagAsync());
  },[dispatch])

  useEffect(() => {
    if (!tagModalOpen) {
      dispatch(tagActions.updateSelectedTagInfo({name: '', color: '',_id: '',description: '', isUpdated: false}));
    }
    
  },[tagModalOpen, dispatch]);


  return (
    <ContainerBox>
      <Box mb={2}>
        <Typography mt={1} variant='h5' fontSize='28px' fontWeight='600' 
                    letterSpacing='1px' textAlign='left' mb={1} color="primary"
                    sx={{[theme.breakpoints.down('sm')]: {fontSize: '23px'}}}>
              Manage Tags
          </Typography>
        <Divider sx={{borderColor: `${theme.palette.secondary.dark}50`, marginBottom: '5px'}}/>

        <Typography variant='meta' fontSize='14px'  textAlign='left' 
                      sx={{opacity: 0.8, [theme.breakpoints.down('sm')]: {
                            textAlign: 'center'
                          }}}>
          Create new tag to classify your expenses
        </Typography>

        <Box sx={{[theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}}>
          <CustomButton
                sx={{marginTop: 2, marginBottom: 3, display: 'flex'}}
                startIcon={<Add/>}
                loading={false}
                variant="outlined"
                color="primary"
                onClick={openModal}>
            Create new tag
          </CustomButton>
        </Box>
        <Divider sx={{borderColor: `${theme.palette.secondary.dark}50`, marginBottom: '5px', marginTop: '5px'}}/>
        <Box display='flex' flexWrap='wrap' mt={3} sx={{wordWrap: 'normal'}}>
          {tags?.map(tag => <TagBox key={tag._id}
                                    id={tag._id}
                                    color={tag.color} 
                                    tagName={tag.name}
                                    description={tag.description} 
                                    isEditable={true} 
                                    handleEdit={handleUpdate}
                                    handleDelete={handleDeleteTag}
                                    />)}
          {isTagLoading && <Box ml={2} mt={1}><CircularProgress size={30}/></Box>}
        </Box>
      </Box>
      <AlertDialog open={deleteTag.isOpen} 
                   title='Warning: Delete Tag'
                   description={`Are you sure, you want to delete tag: ${deleteTag.name}`}
                   handleClose={handleDeleteTagWarningClose}
                   handleSubmit={handleDeleteTagWarningAgree}
                   closeButtonText='Close'
                   agreeButtonText='Delete'/>
    </ContainerBox>
  );
}

export default ManageTag;