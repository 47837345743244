import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import routes from './router/routes';
import { useAuth } from './context/AuthContext';
import { theme } from './theme/theme';
import { ThemeProvider } from '@mui/material';
import CircularProgress from "@mui/material/CircularProgress";
import {useAppSelector} from "./redux/hooks";
import {RootState} from "./redux/store";
import BlankLayout from "./components/Layouts/BlankLayout";
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

function App(): React.JSX.Element {
  const { isLogin, authChallenges} = useAuth();
  const router = createBrowserRouter(routes(isLogin, undefined, {authChallenges}));
  
  const { isPageLoading }  = useAppSelector((state: RootState) => state.auth.componentState);

  return (
    <ThemeProvider theme={theme}>
      {isPageLoading ? <BlankLayout><CircularProgress size={80}/></BlankLayout> : <RouterProvider router={router} />}
    </ThemeProvider>
  );
}

export default App;
