import { put, call, takeLatest, ForkEffect } from 'redux-saga/effects';
import { createUpdateTagCall, deleteUserTagsCall, getUserTagsCall } from '../../api/tags-apis';
import { ITag, tagActions } from './tag-slice';
import { AxiosResponse } from 'axios';

function* watchCreateUpdateTag({ payload }: { payload: {name: string, color: string, _id?:string, description?: string}}) {
  try {
    yield call(createUpdateTagCall, payload);
    yield put(tagActions.updateComponentState({
      isTagsCreateLoading: false, 
      tagError: ''
    }))
    yield put(tagActions.toggleTagModal(false));
    yield put(tagActions.getTagAsync());
  } catch (err: any) {
    yield put(tagActions.updateComponentState({
      isTagsCreateLoading: false, 
      tagError: err.response?.data?.errors?.[0] || err.response?.data?.message || 'Server error'
    }));
  }
}

function* watchUsersTags() {
  try {
    const tagsResponse: AxiosResponse<ITag[]> = yield call(getUserTagsCall);
    yield put(tagActions.loadTags(tagsResponse.data));
  } catch (err: any) {
    yield put(tagActions.updateComponentState({
      isTagsLoading: false, 
      tagError: err.response?.data?.errors?.[0] || err.response?.data?.message || 'Server error'
    }));
  }
}

function* watchDeleteTag({payload}: {payload: string}) {
  try {
    yield call(deleteUserTagsCall, payload);
    yield put(tagActions.getTagAsync());
  } catch (err: any) {
    console.log('delete tag error', {error: err.response?.data?.errors?.[0] || err.response?.data?.message || 'Server error'})
  }
  
}

export function* watchUserSagas(): Generator<ForkEffect, void> {
  yield takeLatest(tagActions.createUpdateTagAsync, watchCreateUpdateTag);
  yield takeLatest(tagActions.getTagAsync, watchUsersTags);
  yield takeLatest(tagActions.deleteTagAsync, watchDeleteTag)
}
const tagSagas = watchUserSagas;
export default tagSagas;
