import { Box, Button, Modal, SxProps, Theme } from "@mui/material";
import {styled as materialStyled} from "@mui/material/styles";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import ShareIcon from '@mui/icons-material/Share';
import DownloadIcon from '@mui/icons-material/Download';
import { theme } from "../../theme/theme";
import { fileToBlob } from "../../utils/compressImage";

export interface ImageViewBoxProps {
    width: string;
    height: string;
    src: string;
    onDelete?: () => void;
}

const ImageBox = materialStyled(Box)(({theme}: {theme: Theme}) => ({
    borderRadius: 10,
    margin: 5,
    position: 'relative',
}));

const imageStyle = (props: ImageViewBoxProps): React.CSSProperties => ({
    width: props.width || 100, 
    height: props.height || 100,
    objectFit: 'cover',
    borderRadius: 10
});

const IconButton = materialStyled(Button)(({theme}: {theme: Theme}) => ({
    position: 'absolute',
    minWidth: '0',
    padding: 3,
    margin: 3,
    borderRadius: 50,
    width: 25,
    height: 25,
    // backgroundColor: theme.palette.white.main
}))

export const ImageViewBox = (props: ImageViewBoxProps) => {
    const handleShare = async () => {
        if (props.src) {
            try {
              const response = await fetch(props.src);
              const blob = await response.blob();
              const reader = new FileReader();
              reader.onloadend = () => {
                const file = reader.result;
                console.log(file);
              };
              reader.readAsDataURL(blob);
            } catch (error) {
              console.error('Error sharing:', error);
            }
        }
      };
      const handleDownload = () => {
        if (props.src) {
          const a = document.createElement('a');
          a.href = props.src;
          a.download = `downloaded_image-${Date.now()}.jpeg`; // Set the desired file name
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      };
    const [isFullView, setIsFullView] = useState<boolean>(false);
    const imageBox = (
       <ImageBox sx={{width: props.width || 100, height: props.height || 100}} >
            <img src={props.src} alt="selected pic" style={imageStyle(props)} onClick={() => setIsFullView(true)}/>
            {props.onDelete && <IconButton sx={{bottom: 0,right: 0,}} variant="contained">
                <DeleteOutlineIcon fontSize="small" color="secondary"  sx={{zIndex: 5}} onClick={props.onDelete}/>
            </IconButton>}
        </ImageBox> 
    );
    const fullViewImage = (
        <Modal open={true} onClose={() => setIsFullView(true)} sx={{display: 'flex', alignItems: 'center',justifyContent: 'center', backgroundColor: theme.palette.black.main}}>
            <Box sx={{display: 'flex', alignItems: 'center',justifyContent: 'center'}}>
                <IconButton sx={{top: 10,right: 70,}} variant="text" onClick={()=> handleShare()}>
                    <ShareIcon sx={{color: theme.palette.white.main}}></ShareIcon>
                </IconButton>
                <IconButton sx={{top: 10,right: 35,}} variant="text" onClick={()=> handleDownload()}>
                    <DownloadIcon sx={{color: theme.palette.white.main}}></DownloadIcon>
                </IconButton>
                <IconButton sx={{top: 10,right: 0,}} variant="text" onClick={()=> setIsFullView(false)}>
                    <CloseIcon></CloseIcon>
                </IconButton>
                <img src={props.src} alt="Enlarged" style={{width: '90vw', height: '90vh', objectFit: 'contain'}} />
            </Box>

        </Modal>
    );
    return isFullView ? fullViewImage : imageBox;
}