import { SidebarOption, SidebarOptionMobile } from './enums/sidebar-option';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

export const SIDEBAR_OPTIONS = {
  [SidebarOption.DASHBOARD]: {
    name: SidebarOption.DASHBOARD,
    mobileName: SidebarOptionMobile.DASHBOARD,
    path: '/dashboard',
    icon: DonutSmallIcon,
    roles: []
  },
  [SidebarOption.EXPENCES]: {
    name: SidebarOption.EXPENCES,
    mobileName: SidebarOptionMobile.EXPENCES,
    path: '/expences',
    icon: MonetizationOnIcon,
    roles:[]
  },
  [SidebarOption.SUMMARY]: {
    name: SidebarOption.SUMMARY,
    mobileName: SidebarOptionMobile.SUMMARY,
    path: '/summary',
    icon: AssignmentIcon,
    roles:[]
  },
  [SidebarOption.MANAGE_TAGS]: {
    name: SidebarOption.MANAGE_TAGS,
    mobileName: SidebarOptionMobile.MANAGE_TAGS,
    path: '/manage-tags',
    icon: LocalOfferIcon,
    roles:[]
  },
  [SidebarOption.RECURENT_PAYMENT]: {
    name: SidebarOption.RECURENT_PAYMENT,
    mobileName: SidebarOptionMobile.RECURENT_PAYMENT,
    path: '/recurent-payment',
    icon: CreditCardIcon,
    roles:[]
  },
  [SidebarOption.CREATE_EXPENSE]: {
    name: '',
    mobileName: '',
    path: '',
    icon: AddOutlinedIcon,
    roles:[]
  }
}