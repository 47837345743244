import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UpdatePasswordInput } from 'aws-amplify/auth';
import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";

export interface IAuthSlice {
  isLogin: boolean;
  email: string;
  password: string;
  authChallanges: string[],
  componentState: {[name: string]: undefined|string|boolean}
}

const initialState: IAuthSlice = {
  isLogin: false,
  email: '',
  password: '',
  authChallanges: [],
  componentState: {}
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    
    logout: (state) => {
      state.isLogin = false;
    },
    login: (state) => {
      state.isLogin = true;
      state.email = '';
      state.password = '';
      state.componentState.isPageLoading = false;
      state.authChallanges = [];
      state.componentState.error = '';
    },
    addChallenge: (state, action: PayloadAction<string>) => {
      state.authChallanges.push(action.payload);
      state.componentState.isPageLoading = false;
    },
    logInAsync: (state, action: PayloadAction<{ email: string; password: string}>) => {
      state.componentState.isPageLoading = true;
      state.email = action.payload.email;
      state.password = action.payload.password;
    },
    updateComponentState: (state, action: PayloadAction<{[name: string]: undefined|string|boolean}>) => {
      state.componentState = {...state.componentState, ...action.payload};
    },
    logOutAsync: (state) => {},
    changePasswordAsync: (state, action: PayloadAction<UpdatePasswordInput>) => {
      state.componentState.isChangePasswordLoading = true;
      state.componentState.error = '';
    }, //for after login
  },
});
  const authPersistConfig = {
    key: 'auth',
    storage: storage,
    whitelist: ['isLogin','accessToken','refreshToken']
  }

const authActions = authSlice.actions;
const authReducer = persistReducer(authPersistConfig, authSlice.reducer)

export { authActions, authReducer};