import React, { useState } from 'react';
import {
  Alert,
  Box,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {styled as materialStyled} from "@mui/material/styles";
import { LoadingButton } from '@mui/lab';
import { useFormik } from 'formik';
import * as yup from 'yup';
import * as _ from "lodash";
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { authActions } from '../../redux/auth/authSlice';
import { Visibility, VisibilityOff } from '@mui/icons-material';

export interface InAppPassworResetProps {

} 
const CustomTextField = materialStyled(TextField)(({ theme }: { theme: Theme }) => ({
  width:'100%',
  '& .MuiInputBase-root':{
    height: '38px',
    borderRadius:'5px',
    width: '100%'
  },
}));

const CustomButton = materialStyled(LoadingButton)(({ theme }: { theme: Theme }) => ({
  borderRadius:'5px',
  marginBottom:'5px',
  height:'43px',
  padding:'12px 32px',
  gap:'10px',
  textAlign:'center',
  textTransform:'none',
  width: '200px'
}));

function InAppPassworReset(props: InAppPassworResetProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { componentState} = useAppSelector((state: RootState) => state.auth);

  const error = _.get(componentState, 'error', false);
  const isLoading = _.get(componentState, 'isChangePasswordLoading', false) as boolean;
  const isChangePasswordSuccess = _.get(componentState, 'isChangePasswordSuccess', false) as boolean;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const validationSchema = yup.object().shape({
    currentPassword: yup.string().required('Current Password is required'),
    password: yup.string()
        .min(8, 'Password must be 8 characters long')
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .matches(/[^\w]/, 'Password requires a symbol')
        .required('Password is required')
        .notOneOf([yup.ref('currentPassword'), null], 'New Password must be different from Current Password'),
    confirmPassword: yup.string()
      .oneOf([yup.ref('password')], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: ({currentPassword, password}) => {
        dispatch(authActions.changePasswordAsync({oldPassword: currentPassword, newPassword: password}));
    },
  });

  const handleSnackBarClose = () => {
    dispatch(authActions.updateComponentState({ isChangePasswordSuccess: false }));
  }

  return (
    <Box mb={2} sx={{padding: { xs: '10px', sm: '20px' }}} >
        <Snackbar
            anchorOrigin={{ vertical: isMobile ? 'top': 'bottom', horizontal: 'left' }}
            open={isChangePasswordSuccess}
            onClose={handleSnackBarClose}
            autoHideDuration={5000}
          >
            <Alert
                onClose={handleSnackBarClose}
                severity="success"
                variant="filled"
                sx={{ width: '100%' }}>
              Password updated successfully!
            </Alert>
        </Snackbar>
        <Typography variant='h5' fontSize='25px'  fontWeight='600' letterSpacing='1px' textAlign='left' sx={{opacity: 0.8}}>
          Change Password
        </Typography>
        <Box mt={2} display='flex' flexDirection='column' justifyContent='space-around' alignItems='center' height='90%'>
          <Box width='100%'>
            <Typography variant='meta' hidden={!error} component='p' gutterBottom textAlign='left' color='error' width='297px'>{error}</Typography>
            <Box mb={2} display='flex' flexDirection='column'>
              <Typography mb={0.5} variant='meta' >Current Password <Typography component="span" color='primary'>&nbsp;*</Typography></Typography>
              <CustomTextField
                type={showCurrentPassword ? 'text' : 'password'}
                name="currentPassword"
                placeholder='Current Password'
                fullWidth
                value={formik.values.currentPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.currentPassword && Boolean(formik.errors.currentPassword)}
                helperText={formik.touched.currentPassword && formik.errors.currentPassword}
                variant="outlined"
                FormHelperTextProps={{style:{...theme.typography.meta}}}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                        onMouseLeave={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                      >{showCurrentPassword ? <VisibilityOff /> : <Visibility />}</IconButton>
                    </InputAdornment>
                }}
              />
            </Box>
            <Box mb={2} display='flex' flexDirection='column'>
              <Typography mb={0.5} variant='meta' >Password <Typography component="span" color='primary'>&nbsp;*</Typography></Typography>
              <CustomTextField
                type={showPassword ? 'text' : 'password'}
                name="password"
                placeholder='password'
                fullWidth
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                variant="outlined"
                FormHelperTextProps={{style:{...theme.typography.meta}}}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseLeave={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                      >{showPassword ? <VisibilityOff /> : <Visibility />}</IconButton>
                    </InputAdornment>
                }}
              />
            </Box>
            <Box display='flex' flexDirection='column'>
              <Typography mb={0.5} variant='meta' >Confirm Password <Typography component="span" color='primary'>&nbsp;*</Typography></Typography>
              <CustomTextField
                name="confirmPassword"
                placeholder='confirm password'
                fullWidth
                type={showPassword ? 'text' : 'password'}
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                variant="outlined"
                FormHelperTextProps={{style:{...theme.typography.meta}}}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseLeave={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                      >{showPassword ? <VisibilityOff /> : <Visibility />}</IconButton>
                    </InputAdornment>
                }}
              />
            </Box>
          </Box>

          <Box mt={4} mb={2} width="100%" display='flex' flexDirection='column' alignItems='flex-end'>
            <CustomButton
              loading={isLoading}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => formik.handleSubmit()}
            >
              Change Password
            </CustomButton>
          </Box>
        </Box>
      </Box>
  )
}

export default InAppPassworReset;