import React, { type ReactElement } from 'react';
import {styled as materialStyled} from "@mui/material/styles";
import { Box, Button, Theme } from '@mui/material';
import { theme } from '../../theme/theme';
import { expenseActions } from '../../redux/expense/expense-slice';
import { useDispatch } from 'react-redux';

const ContainerBox = materialStyled(Box)(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.white.main,
  maxWidth:'100%',
  width: '100%',
  borderRadius:'20px',
  padding: '1px 20px 20px',
  boxShadow: `0px 4px 32px 0px ${theme.palette.shades.shadow}`,
  [theme.breakpoints.down('sm')]: {
    padding: '1px 15px 15px',
    overflowX: 'hidden',
    minWidth: 'calc(100vw - 30px)',
    borderRadius:'0px',
    marginTop: '1px'
  }
  
}));

export default function Dashboard(): ReactElement {
  const dispatch = useDispatch();

  const handleExpenseModalToggle = () => {
    dispatch(expenseActions.toggleExpenseModal(true));
  }

  return (
    <ContainerBox sx={{[theme.breakpoints.up('sm')]: {display: 'none'}}}>
      <Box display="flex" flexDirection="column" alignItems="center" pt={2} sx={{[theme.breakpoints.up('sm')]: {display: 'none'}}}>
        <Button variant='outlined' 
                sx={{width: '100%', height: '38px'}}
                onClick={handleExpenseModalToggle}>Add Expense</Button>
      </Box>
      
    </ContainerBox>
  );
}
