import { Box, Modal, SxProps, TextField, Typography } from "@mui/material";
import Close from '@mui/icons-material/Close';
import {Theme, styled as materialStyled, useTheme} from "@mui/material/styles";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as yup from 'yup';
import { theme } from "../../theme/theme";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { tagActions } from "../../redux/tag/tag-slice";
import { useAppSelector } from "../../redux/hooks";
import _ from 'lodash';

export interface TagManageModalProps {
    open: boolean;
    isUpdate?: boolean;
    color?: string,
    tagName?: string,
    id?: string,
    description?: string,
    handleClose: () => void
} 

const modalStyles: SxProps<Theme> = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    borderRadius: '10px',
    [theme.breakpoints.down('sm')] : {
        width: '90%',
    }
  };

  const colorBoxStyles = (selectedColor: string): SxProps<Theme> => ({ 
    width: 70, 
    height: 70, 
    backgroundColor: selectedColor, 
    borderRadius: 1,
    border: '1px solid #aaa'
  })

  const CustomTextField = materialStyled(TextField)(({ theme }: { theme: Theme }) => ({
    width:'100%',
    '& .MuiInputBase-root':{
      height: '38px',
      borderRadius:'10px',
    },
  }));

  const CustomButton = materialStyled(LoadingButton)(({ theme }: { theme: Theme }) => ({
    borderRadius:'5px',
    marginBottom:'5px',
    height:'43px',
    padding:'12px 32px',
    gap:'10px',
    textAlign:'center',
    textTransform:'none',
    color: theme.palette.white.main,
    fontWeight: 100,
    letterSpacing: 1
  }));

export function TagManageModal(props: TagManageModalProps) {
    const colorInputRef = useRef<HTMLInputElement | null>(null);
    const theme = useTheme();
    const dispatch = useDispatch();

    const {componentState} = useAppSelector(state => state.tag);
    const tagError = _.get(componentState, 'tagError', '');
    const isTagsCreateLoading = _.get(componentState, 'isTagsCreateLoading', false) as boolean;

    
    const [selectedColor, setSelectedColor] = useState<string>(props.color || theme.palette.white.main);

    const formik = useFormik({
        initialValues: {
          tagName: props.tagName || '',
          description: props.description || '',
        },
        validationSchema: yup.object({
          tagName: yup
            .string()
            .max(30)
            .required('Tag is required'),
          description: yup
            .string().optional(),
        }),
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: (values) => {
            if (props.isUpdate) {
                dispatch(tagActions.createUpdateTagAsync({name: values.tagName!, color: selectedColor, _id: props.id, description: values.description}));
                return;
            }
            dispatch(tagActions.createUpdateTagAsync({name: values.tagName!, color: selectedColor, description: values.description}));
        },
      });
    
    useEffect(() => {
        formik.setValues({tagName: props.tagName || '', description: props.description || ''});
        setSelectedColor(props.color || theme.palette.white.main);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.color, props.tagName, theme.palette.white.main])

    useEffect(() => { //reset colos when close the modal
        if (!props.open) {
            setSelectedColor(theme.palette.white.main);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open])

    const handleColorclick = () => {
        // Open the color input box by triggering a click event
        if (colorInputRef.current) {
          colorInputRef.current.click();
        }
    };

    const onChangeColor = (e: ChangeEvent<HTMLInputElement>) => {
        setSelectedColor(e.target?.value || '');
        formik.setFieldValue('color', selectedColor);
    }

    const handleClose = () => {
        formik.resetForm();
        props?.handleClose();
    }
    return (
        <Modal
            open={props.open}
            aria-labelledby="update tags"
            aria-describedby="This modal use to create update tags"
            >
            <Box sx={{ ...modalStyles }}>
                <Close onClick={() => handleClose()} sx={{position: 'absolute', right: '10px', cursor:"pointer"}}/>
                <Typography variant='h5' 
                        fontSize='22px'  
                        fontWeight='600'   
                        textAlign='left' 
                        sx={{opacity: 0.8}}>
                    {props.isUpdate ? 'Update' : 'Create'} Tag
                </Typography>
                <Box sx={{position:'relative'}} >
                    <Box display='flex' flexDirection='row' alignItems='center'>
                        <Box onClick={handleColorclick} mt={2} sx={{...colorBoxStyles(selectedColor)}}></Box>
                        <Typography variant="meta" ml={4} sx={{fontWeight: 600}}>Color: {selectedColor}</Typography>
                    </Box>
                    <input ref={colorInputRef} onChange={onChangeColor} value={props.color || ''} type="color" style={{ opacity:0, position:'absolute', top:'30px'}}/>
                </Box>
                <Box my={2} display='flex' flexDirection='column'>
                    <Typography mb={0.5} variant='meta' color={'gray'} >Tag Name </Typography>
                    <CustomTextField
                        type='text'
                        name="tagName"
                        placeholder='Tag Name'
                        fullWidth
                        value={formik.values.tagName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.tagName && Boolean(formik.errors.tagName)}
                        helperText={formik.touched.tagName && formik.errors.tagName}
                        variant="outlined"
                        FormHelperTextProps={{style:{...theme.typography.meta}}}
                    />
                </Box>
                <Box my={2} display='flex' flexDirection='column'>
                    <Typography mb={0.5} variant='meta' color={'gray'} >Description </Typography>
                    <CustomTextField
                        type='text'
                        name="description"
                        placeholder='Description'
                        fullWidth
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.description && Boolean(formik.errors.description)}
                        helperText={formik.touched.description && formik.errors.description}
                        variant="outlined"
                        FormHelperTextProps={{style:{...theme.typography.meta}}}
                    />
                </Box>
                {tagError && <Typography  mb={0.5} variant='meta' color={theme.palette.error.main}>{tagError}</Typography>}
                <Box mt={4} display='flex' justifyContent='flex-end'>
                    <CustomButton
                        loading={isTagsCreateLoading}
                        variant="contained"
                        color="primary"
                        onClick={() => formik.handleSubmit()}>
                        {props.isUpdate ? 'Update' : 'Create'} Tag
                    </CustomButton>
                </Box>
            </Box>
        </Modal>
    )
}