import { AxiosResponse } from "axios";
import { IExpense, IExpenseParams, IExpenseReq } from "../redux/expense/expense-slice";
import { backendApiInstance } from "./backendApiInstance";
import { PaginationWrapper } from "../utils/dtos/pagination-wrapper";
import { SummaryResponse } from "../utils/dtos/summary-response.dto";

export async function createExpense(expenseRequest: Partial<IExpenseReq>) : Promise<AxiosResponse<IExpense>> {
    return backendApiInstance.post(`/expense/expenses`, expenseRequest);
}

export async function updateExpense(expenseId: string, expenseRequest: Partial<IExpenseReq>) : Promise<AxiosResponse<IExpense>> {
    return backendApiInstance.put(`/expense/expenses/${expenseId}`, expenseRequest);
}

export async function deleteExpense(expenseId: string) : Promise<AxiosResponse<IExpense>> {
    return backendApiInstance.delete(`/expense/expenses/${expenseId}`);
}

export async function getExpenses(requestParams: Partial<IExpenseParams>): Promise<AxiosResponse<PaginationWrapper<IExpense[]>>> {
    return backendApiInstance.get(`/expense/expenses`, {params: requestParams});
}

export async function getExpensesSummary(requestParams: Partial<IExpenseParams>): Promise<SummaryResponse> {
    return backendApiInstance.get(`/expense/expenses/summary`, {params: requestParams});
}