import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";
import { IExpense } from '../expense/expense-slice';

export interface IRecurrent {
  _id: string,
  name: string,
  amount: number,
  type: number,
  lastExpense?: IExpense,
  paidLastMonth?: boolean,
}

export interface IRecurrentSlice {
  componentState: {[name: string]: undefined|string|boolean},
  recurrents?: IRecurrent[];
  recurrentModalOpen: boolean;
  selectedRecurrentInfo: IRecurrent & {isUpdated: boolean}
}

const initialState: IRecurrentSlice = {
  recurrents: undefined,
  componentState: {},
  recurrentModalOpen: false,
  selectedRecurrentInfo: {name: '', amount: 0, _id: '', type: 0, isUpdated: false}
};

const recurrentSlice = createSlice({
  name: 'recurrent',
  initialState,
  reducers: {
    getRecurrentAsync:(state) => {
      state.componentState.isRecurrentLoading = true;
    },
    storeRecurrents: (state, action: PayloadAction<IRecurrent[]>) => {
      state.recurrents = action.payload;
      state.componentState.isRecurrentLoading = false;
    },
    toggleRecurrentModal: (state, action: PayloadAction<boolean>) => {
      state.recurrentModalOpen = action.payload;
    },
    createUpdateRecurrentAsync: (state, action: PayloadAction<{_id?: string, name: string, amount?: number, type: number,}>) => {
      state.componentState.isRecurrentsCreateLoading = true;
      state.componentState.recurrentError = '';
    },
    deleteRecurrentAsync: (state, action: PayloadAction<string>) => {
    },
    updateComponentState: (state, action: PayloadAction<{[name: string]: undefined|string|boolean}>) => {
      state.componentState = {...state.componentState, ...action.payload};
    },
    updateSelectedRecurrentInfo: (state, action: PayloadAction<IRecurrent & {isUpdated: boolean}>) => {
      state.selectedRecurrentInfo = action.payload;
    }
  }
});
  const recurrentPersistConfig = {
    key: 'recurrent',
    storage: storage,
    whitelist: []
  }

const recurrentActions = recurrentSlice.actions;
const recurrentReducer = persistReducer(recurrentPersistConfig, recurrentSlice.reducer);

export { recurrentActions, recurrentReducer };