import React, {ChangeEvent, useRef} from 'react';
import {Avatar, Box, IconButton,  useTheme} from "@mui/material";
import EditSVG from "../../assets/img/edit.svg";

interface AvatarEditProps {
  editable:boolean;
  selectedAvatar?:string;
  size:string;
  name:string;
  avatar?:string;
  font: React.CSSProperties;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

function AvatarEdit({
  editable,
  selectedAvatar,
  size,
  name,
  avatar,
  font,
  onChange,
}:AvatarEditProps) {
  const theme = useTheme();
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const bgColors = [
    theme.palette.shades.purpleBorder,
    '#ff8a80','#ff5252','#ff1744','#d50000',
    '#ff80ab','#ff4081','#f50057','#c51162',
    '#ea80fc','#e040fb','#d500f9','#aa00ff',
    '#b388ff','#7c4dff','#651fff','#6200ea',
    '#8c9eff','#536dfe','#3d5afe','#304ffe',
    '#82b1ff','#448aff','#2979ff','#2962ff',
    '#80d8ff','#40c4ff','#00b0ff','#0091ea',
    '#84ffff','#18ffff','#00e5ff','#00b8d4',
    '#a7ffeb','#64ffda','#1de9b6','#00bfa5',
    '#b9f6ca','#69f0ae','#00e676','#00c853',
    '#ccff90','#b2ff59','#76ff03','#64dd17',
    '#f4ff81','#eeff41','#c6ff00','#aeea00',
    '#ffff8d','#ffff00','#ffea00','#ffd600',
    '#ffe57f','#ffd740','#ffc400','#ffab00',
    '#ffd180','#ffab40','#ff9100','#ff6d00',
    '#ff9e80','#ff6e40','#ff3d00','#dd2c00'
  ]

  const getBgColor = (name:string) => {
    const count = name.split(" ").join("").split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
    return bgColors[count % bgColors.length]
  }

  const getNameCharacter = (name:string) => {
    if(!name) return '';
    else if(name.split(' ').length === 1) return name.split(' ')[0].charAt(0).toLocaleUpperCase();
    else return `${name.split(' ')[0].charAt(0).toLocaleUpperCase()}${name.split(' ')[1].charAt(0).toLocaleUpperCase()}`;
  }
  const handleFileUploadBtnClick = () => {
    if(hiddenFileInput && hiddenFileInput.current){
      hiddenFileInput.current.click();
    }
  };

  return (
        <Box sx={{position:'relative', width:size, height:size}}>
          <Avatar
            alt="profile picture"
            src={selectedAvatar? selectedAvatar : avatar || ''}
            sx={{position:'absolute', width: size, height: size, backgroundColor: getBgColor(name), ...font }}
          >{getNameCharacter(name)}</Avatar>
          <label hidden={!editable} >
            <IconButton component="span" sx={{position:'absolute', bottom:'75%', left:'75%', padding:'0'}} onClick={handleFileUploadBtnClick} aria-label="edit">
              <Box component='img' src={EditSVG}></Box>
            </IconButton>
          </label>
          <input ref={hiddenFileInput} accept=".png, .jpg, .jpeg" style={{ display: 'none' }} type="file" onChange={onChange}/>
        </Box>
  );
}

export default AvatarEdit;