import { put, call, takeLatest, ForkEffect } from 'redux-saga/effects';
import { IRecurrent, recurrentActions } from './recurrent-slice';
import { AxiosResponse } from 'axios';
import { createUpdateRecurrentsCall, deleteUserRecurrentsCall, getUserRecurrentsCall } from '../../api/recurrent-api';

function* watchCreateUpdateRecurrent({ payload }: { payload: {_id?: string, name: string, amount?: number, type: number,}}) {
  try {
    yield call(createUpdateRecurrentsCall, payload);
    yield put(recurrentActions.updateComponentState({
      isRecurrentsCreateLoading: false, 
      tagError: ''
    }))
    yield put(recurrentActions.toggleRecurrentModal(false));
    yield put(recurrentActions.getRecurrentAsync());
  } catch (err: any) {
    yield put(recurrentActions.updateComponentState({
      isRecurrentsCreateLoading: false, 
      recurrentError: err.response?.data?.errors?.[0] || err.response?.data?.message || 'Server error'
    }));
  }
}

function* watchUsersRecurrent() {
  try {
    const recurrentsResponse: AxiosResponse<IRecurrent[]> = yield call(getUserRecurrentsCall);
    yield put(recurrentActions.storeRecurrents(recurrentsResponse.data));
  } catch (err: any) {
    yield put(recurrentActions.updateComponentState({
      isRecurrentLoading: false, 
      recurrentError: err.response?.data?.errors?.[0] || err.response?.data?.message || 'Server error'
    }));
  }
}

function* watchDeleteRecurrent({payload}: {payload: string}) {
  try {
    yield call(deleteUserRecurrentsCall, payload);
    yield put(recurrentActions.getRecurrentAsync());
  } catch (err: any) {
    console.log('delete recurrent error', {error: err.response?.data?.errors?.[0] || err.response?.data?.message || 'Server error'})
  }
  
}

export function* watchRecurrentSagas(): Generator<ForkEffect, void> {
  yield takeLatest(recurrentActions.createUpdateRecurrentAsync, watchCreateUpdateRecurrent);
  yield takeLatest(recurrentActions.getRecurrentAsync, watchUsersRecurrent);
  yield takeLatest(recurrentActions.deleteRecurrentAsync, watchDeleteRecurrent)
}
const recurrentSagas = watchRecurrentSagas;
export default recurrentSagas;
