import { put, call, takeLatest, ForkEffect } from 'redux-saga/effects';
import { ISignupSlice, signupActions } from './signupSlice';
import { resetPasswordLinkCall, signUpCall, updatePasswordCall, validatePasswordLinkCall } from '../../api/signupApi';
import { NavigateFunction } from 'react-router-dom';


function* watchSignup({payload}: { payload: ISignupSlice}) {
  const { email, name, company, jobTitle } = payload;
  try{
      yield put(signupActions.updateComponentState({ error: '' }));
      yield call(signUpCall,email, name, company, jobTitle);
      yield put(signupActions.signup());
      // navigate('/login');
  } catch (err:any) {
      console.log(err)
      yield put(signupActions.updateComponentState({ 
        isPageLoading: false, 
        error: err.response?.data?.errors?.[0] || err.response?.data?.message || 'Server error'
      }));
  }
  
}

function* watchResendResetLink({payload}:{payload: {email: string}}) {
  try {
    yield put(signupActions.updateComponentState({ error: '' }));
    yield call(resetPasswordLinkCall,payload.email);
    yield put(signupActions.signup());
  } catch (err: any) {
    yield put(signupActions.updateComponentState({ 
      isPageLoading: false, 
      error:  err.response?.data?.errors?.[0] || err.response?.data?.message || 'Server error'
    }));
  }
}

function* watchForgetPasswordLink({payload}:{payload: {email: string}}) {
  try {
    yield put(signupActions.updateComponentState({ error: '' }));
    yield call(resetPasswordLinkCall,payload.email);
    yield put(signupActions.forgetPasswordLinkSuccess());
  } catch (err: any) {
    yield put(signupActions.updateComponentState({ 
      isForgetPasswordLoading: false,
      error: err.response?.data?.errors?.[0] || err.response?.data?.message || 'Server error'
    }));
  }
}

function* watchValidateResetPwLink({payload}:{payload: {token: string}}) {
  try {
    yield call(validatePasswordLinkCall, payload.token);
    yield put(signupActions.updateLinkValidity(true));
  } catch (err) {
    yield put(signupActions.updateLinkValidity(false));
  }
}

function* watchResetPassword({payload}:{payload: {password: string, token: string, navigate: NavigateFunction}}) {
  try {
    yield call(updatePasswordCall, payload.password, payload.token);
    yield put(signupActions.updateComponentState({ 
      isSetPasswordLoading: false,
      isSuccessPasswordReset: true,
    }));
    payload.navigate('/login');
  } catch (err: any) {
    yield put(signupActions.updateComponentState({ 
      isSetPasswordLoading: false, 
      passwordError: err.response?.data?.errors?.[0] || err.response?.data?.message || 'Server error'
    }));
  }
}


export function* watchAuthSagas(): Generator<ForkEffect, void> {
  yield takeLatest(signupActions.signupAsync, watchSignup);
  yield takeLatest(signupActions.resendResetLinkAsync, watchResendResetLink);
  yield takeLatest(signupActions.forgetPasswordResetLinkAsync, watchForgetPasswordLink);
  yield takeLatest(signupActions.validateResetPwLinkAsync, watchValidateResetPwLink);
  yield takeLatest(signupActions.setPasswordAsync, watchResetPassword);
}
const signupSagas = watchAuthSagas;
export default signupSagas;
