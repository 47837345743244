import { Box, Typography } from "@mui/material";
import { IExpense } from "../../redux/expense/expense-slice";
import { Theme, styled as materialStyled } from "@mui/material/styles";
import { theme } from "../../theme/theme";
import dayjs from "dayjs";
import DescriptionIcon from '@mui/icons-material/Description';
import { convertNumberToCurrenct, truncateText } from "../../utils/text-utils";
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';

export interface MobileExpenseRecordProps {
    expense: IExpense;
    onClick?: () => void;
}

const ContainerBox = materialStyled(Box)(({ theme }: { theme: Theme }) => ({
    backgroundColor: theme.palette.white.main,
    maxWidth:'100%',
    width: '100%',
    borderRadius:'5px',
    padding: '10px',
    border: `1px solid ${theme.palette.shades.blackBorder}`,  
    marginTop: 10,
  }));

export const MobileExpenseRecord = (props: MobileExpenseRecordProps) => {
    const recurrentTypeColor = (type: number) => {
        const typeObj: {[type:number]: string} = {
            0: '#4CD8DA',
            1: '#F9BB65',
            2: '#F28783'
        }
        return typeObj[type];
    }

    return (
        <ContainerBox display='flex' flexDirection="row" theme={theme} gap={1} onClick={props.onClick}>
            {props.expense.tags && <Box width='45px' height='40px' sx={{border: `0px 4px 32px 0px ${theme.palette.shades.shadow}`, 
                                                backgroundColor: props.expense.tags.color,
                                                borderRadius: '7px'}}></Box>}
            {props.expense.recurrentPayment && <StickyNote2OutlinedIcon sx={{ fontSize: '25px', color: recurrentTypeColor(props.expense.recurrentPayment.type)}}></StickyNote2OutlinedIcon>}

            <Box  display='flex' flexDirection="column" width={'100%'} gap={0.2}>
                <Box display='flex' flexDirection="row" justifyContent="space-between" width={'100%'}>
                    <Typography variant='meta' fontSize='14px' fontWeight='800'  textAlign='left' sx={{opacity: 0.8}}>
                        {truncateText(props.expense.name, 30)}
                    </Typography> 
                    <Typography variant='meta' fontSize='14px' fontWeight='400'  textAlign='left'  sx={{opacity: 1, minWidth: 90, textAlign: 'right'}}>
                        {convertNumberToCurrenct(props.expense.amount)} Rs
                    </Typography> 
                </Box>
                 
                {props.expense.tags && <Typography variant='meta' fontSize='14px' fontWeight='400'  textAlign='left'  sx={{opacity: 0.7}}>
                    Tag: {props.expense.tags.name}
                </Typography>}

                {props.expense.recurrentPayment && <Typography variant='meta' fontSize='14px' fontWeight='400'  textAlign='left'  sx={{opacity: 0.7}}>
                    Recurrent: {props.expense.recurrentPayment?.name}
                </Typography>}
                
                <Typography variant='meta' fontSize='14px' fontWeight='400'  textAlign='left'  sx={{opacity: 0.7}}>
                    Date: {dayjs(props.expense.expenseDate).format('YYYY-MM-DD')}
                </Typography>

                {props.expense.description && <Box display='flex' flexDirection="row" mt={1} gap={0.5}>
                    <DescriptionIcon sx={{display: 'inline', fontSize: '14px', marginTop: 0.2, opacity: 0.6}}></DescriptionIcon>
                    <Typography   variant='meta' fontSize='13px' fontWeight='400'  textAlign='left'  sx={{opacity: 0.6}}>
                            {truncateText(props.expense.description, 100)}
                    </Typography>
                </Box>}
                
                
            </Box>
        </ContainerBox>
    )
}