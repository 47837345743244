import { AxiosResponse } from "axios";
import { backendApiInstance } from "./backendApiInstance";
import { IRecurrent } from "../redux/recurrent/recurrent-slice";

export async function createUpdateRecurrentsCall(payload: {_id?: string, name: string, amount?: number, type: number,}): Promise<AxiosResponse<IRecurrent>> {
    return backendApiInstance.post('/recurrents/recurrents', payload);
}

export async function getUserRecurrentsCall() : Promise<AxiosResponse<IRecurrent[]>> {
    return backendApiInstance.get('/recurrents/recurrents');
}

export async function deleteUserRecurrentsCall(reccurentId: string) : Promise<AxiosResponse<IRecurrent>> {
    return backendApiInstance.delete(`/recurrents/${reccurentId}`);
}