import dayjs from 'dayjs';
import _  from 'lodash';

export function arraysEqual<T>(arr1: T[], arr2: T[]) {
  const sortedArr1 = _.sortBy(arr1);
  const sortedArr2 = _.sortBy(arr2);
  
  return _.isEqual(sortedArr1, sortedArr2);
}

export function getLastPassedDate(dateValue: number) {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // Month is zero-based, so add 1

  // Create a new Date object with the provided year, month, and date number
  let dateObject = new Date(`${currentYear}-${currentMonth}-${dateValue}`);
  
  // If the resulting date is beyond the end of the month, set it to the end of the month
  if (dateObject > new Date()) {
      dateObject = dayjs(dateObject).subtract(1, 'month').toDate();
  }
  return dateObject;
}