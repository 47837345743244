import React, { useState, type ReactElement, useEffect } from 'react';
import {TextField, Typography, Box, Theme, useTheme, Button} from '@mui/material';
import LoadingButton from "@mui/lab/LoadingButton";
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {styled as materialStyled} from "@mui/material/styles";
import * as _ from "lodash";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import { RootState } from '../../redux/store';
import { signupActions } from '../../redux/signup/signupSlice';


const CustomTextField = materialStyled(TextField)(({ theme }: { theme: Theme }) => ({
  width:'310px',
  '& .MuiInputBase-root':{
    height: '38px',
    borderRadius:'5px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '297px',
  },
}));

const CustomButton = materialStyled(LoadingButton)(({ theme }: { theme: Theme }) => ({
  borderRadius:'5px',
  marginBottom:'5px',
  width:'310px',
  height:'40px',
  padding:'12px 32px',
  gap:'10px',
  textAlign:'center',
  textTransform:'none',
  [theme.breakpoints.down('sm')]: {
    width: '297px',
  },
}));

const LogInBox = materialStyled(Box)(({ theme }: { theme: Theme }) => ({
  backgroundColor:'white',
  width:'374px',
  borderRadius:'10px',
  boxShadow: `0px 4px 32px 0px ${theme.palette.shades.shadow}`,
  [theme.breakpoints.down('sm')]: {
    width: 'calc(100vw - 20px)',
  },
}));


export default function Signup(): ReactElement {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { company, jobTitle, name, componentState, isUserCreated, email } = useAppSelector((state: RootState) => state.signup);

  const error = _.get(componentState, 'error', '');
  const isLoading = _.get(componentState, 'isPageLoading', false) as boolean;
  const [timer, setTimer] = useState(0);


  const formik = useFormik({
    initialValues: {
      name: name,
      email: '',
      jobTitle: jobTitle,
      company: company
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .required('Name is required'),
      email: yup
        .string()
        .email('Please enter a valid email address')
        .required('Email is required'),
      jobTitle: yup
        .string().optional(),
      company: yup
        .string().optional(),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: ({email, name, company, jobTitle}) => {
      dispatch(signupActions.signupAsync({email, name, company, jobTitle}));
      setTimer(120);
    },
  });

  const resendPassowd = () => {
    if (formik.values.email || email) {
      dispatch(signupActions.resendResetLinkAsync({email: formik.values.email || email}));
      setTimer(120);
    }
    
  }

  useEffect(() => {
    let intervalId: any;
    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [timer]);

  const clearUserCreated = () => {
    dispatch(signupActions.clearUserCreated());
  }

  const LoginPage = 
      (<LogInBox>
        <Typography mt={4} mb={1} variant='h5' gutterBottom 
              fontSize='20px' 
              fontWeight='700' textAlign='left' color="primary"
              sx={{marginLeft: '30px', opacity: 0.8}}>Create your account</Typography>
        <Box display='flex' flexDirection='column' justifyContent='space-around' alignItems='center' height='90%'>
          <Box>
            {error && <Typography variant='meta' component='p' gutterBottom textAlign='left' color='error' width='297px'>{error.toString().substring(0,80)}!</Typography>}
            <Box mb={2} display='flex' flexDirection='column'>
              <Typography mb={0.5} variant='meta' >Name <Typography component="span" color='primary'>&nbsp;*</Typography></Typography>
              <CustomTextField
                type='text'
                name="name"
                placeholder='name'
                fullWidth
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                variant="outlined"
                FormHelperTextProps={{style:{...theme.typography.meta}}}
              />
            </Box>
            <Box mb={2} display='flex' flexDirection='column'>
              <Typography mb={0.5} variant='meta' >Email <Typography component="span" color='primary'>&nbsp;*</Typography></Typography>
              <CustomTextField
                type='email'
                name="email"
                placeholder='username@mail.com'
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                variant="outlined"
                FormHelperTextProps={{style:{...theme.typography.meta}}}
              />
            </Box>
            <Box mb={2} display='flex' flexDirection='column'>
              <Typography mb={0.5} variant='meta' >Job Title</Typography>
              <CustomTextField
                name="jobTitle"
                placeholder='Ex: Software Engineer'
                fullWidth
                value={formik.values.jobTitle}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.jobTitle && Boolean(formik.errors.jobTitle)}
                helperText={formik.touched.jobTitle && formik.errors.jobTitle}
                variant="outlined"
                FormHelperTextProps={{style:{...theme.typography.meta}}}
              />
            </Box>
            <Box display='flex' flexDirection='column'>
              <Typography mb={0.5} variant='meta' >Company</Typography>
              <CustomTextField
                name="company"
                placeholder='Company'
                fullWidth
                value={formik.values.company}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.company && Boolean(formik.errors.company)}
                helperText={formik.touched.company && formik.errors.company}
                variant="outlined"
                FormHelperTextProps={{style:{...theme.typography.meta}}}
              />
            </Box>
          </Box>

          <Box mt={5} columnGap={5} display='flex' flexDirection='column' justifyContent='space-around'>
            <CustomButton
              loading={isLoading}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => formik.handleSubmit()}
            >
              Sign Up
            </CustomButton>
            <Box mb={5} textAlign="center">
              <Typography pt={2} component='span' sx={{ fontSize: 12, paddingRight: '3px' }}>I have an account</Typography>
              <Link style={{ textDecoration: 'none'}} to={'/login'}>
                <Typography variant='meta' sx={{ fontSize: 13, color: theme.palette.linkBlue, fontWeight: 800}}>Sign in</Typography>
              </Link>
            </Box>
          </Box>
        </Box>
      </LogInBox>);

  const Welcome = (
        <LogInBox>
          <Box sx={{padding: '20px'}}>
            <Typography mb={0} variant='h2' gutterBottom 
                fontSize='25px' 
                fontWeight='700' textAlign='center' color="primary"
                sx={{opacity: 0.9, color: theme.palette.appColors.red}}>Welcome, {formik.values.name}! 🎉</Typography>
            
            <Box mt={2} sx={{textAlign: 'center'}}>
              <Typography  variant='meta'
                fontSize='16px' 
                fontWeight='400' textAlign='left' color="primary"
                ><i>Take control of your finances and achieve your financial goals with Fintrack!</i>
              </Typography>
            </Box>
            <Box mt={4} sx={{textAlign: 'center'}}>
              <Typography  variant='meta'
                fontSize='15px' 
                fontWeight='400' textAlign='left' color="primary"
                >
                An account verification link has been sent to </Typography>
                <Typography mt={2} variant='meta'
                    fontSize='16px' 
                    fontWeight='500' textAlign='left' color="primary"
                    sx={{opacity: 0.9, marginLeft: '5px'}}>{formik.values.email || email}. </Typography>
            </Box>

            <Box mt={4} sx={{textAlign: 'center'}}>
                <Typography mt={2} variant='meta'
                  fontSize='15px' 
                  fontWeight='400' textAlign='left' color="primary"
                  >Please check your inbox and click on the <br/> link to reset <br/> your password</Typography>
            </Box>
          

            <Box mt={3} sx={{textAlign: 'center'}}>
              <Typography variant='meta'
                fontSize='14px' 
                fontWeight='400' textAlign='left' color="primary">If you do not recieve email,</Typography>
              {timer === 0 && <Button variant='text' sx={{marginBottom: '3px', color: theme.palette.linkBlue}}
                onClick={resendPassowd} disabled={timer > 0}>
                Resend
              </Button>}
              {timer > 0 && <Typography variant='meta' fontSize='13px'sx={{color: theme.palette.linkBlue, marginLeft: '5px', opacity: 0.7}} >
                Resend in {timer} seconds
                </Typography>}
            </Box>

            <Box sx={{textAlign: 'center', transform:'translate(0px, -5px)', }}>
              <Typography variant='meta'
                fontSize='12px' color="primary"
                sx={{opacity: 0.7}}>In case you lost this page, Please use the reset password flow to generate reset link</Typography>
            </Box>

            <Box mt={3} textAlign="center"> 
              <Link style={{ textDecoration: 'none'}} to={'/login'} onClick={clearUserCreated}>
                <Typography variant='meta' sx={{ fontSize: 13, color: theme.palette.linkBlue, fontWeight: 800}}>Sign in</Typography>
              </Link>
            </Box>
          </Box>
          
        </LogInBox>
      )

  return isUserCreated? Welcome : LoginPage;
}
