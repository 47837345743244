import React from 'react';
import {Navigate, Outlet, type RouteObject} from 'react-router-dom';
import {Roles} from '../utils/enums/Roles';
import Dashboard from '../pages/Dashboard/Dashboard';
import Expense from '../pages/Expense/Expense';
import ManageTag from '../pages/ManageTag/ManageTag';
import RecurrentPayments from '../pages/RecurrentPayments/RecurrentPayments';
import {LayoutType} from '../utils/enums/LayoutType';
import Login from '../pages/Login/Login';
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword';
import PrivateRoute from '../components/PrivateRouter/PrivateRouter';
import AppLayout from '../components/Layouts/AppLayout';
import {SIDEBAR_OPTIONS} from '../utils/sidebar-schema';
import {SidebarOption} from "../utils/enums/sidebar-option";
import ResetPassword from '../pages/ResetPassword/ResetPassword';
import Signup from '../pages/Signup/Signup';
import Profile from '../pages/Profile/Profile';
import Summary from '../pages/Summary/Summary';

const routes = (isLoggedIn: boolean, role?: Roles, option?: {[name: string]: any}): RouteObject[] => [
  {
    path: '/',
    element: (
      <PrivateRoute
        isLoggedIn={isLoggedIn}
        userRole={role}
        routerRoles={SIDEBAR_OPTIONS[SidebarOption.DASHBOARD].roles}
      />
    ),
    children: [
      { path: '/', element: <Navigate to={SIDEBAR_OPTIONS[SidebarOption.DASHBOARD].path} /> },
      { path: SIDEBAR_OPTIONS[SidebarOption.DASHBOARD].path, element: (<AppLayout layout={LayoutType.DASHBOARD}><Dashboard /></AppLayout>)},
    ],
  },
  {
    path: SIDEBAR_OPTIONS[SidebarOption.EXPENCES].path,
    element: (
      <PrivateRoute
        isLoggedIn={isLoggedIn}
        userRole={role}
        routerRoles={SIDEBAR_OPTIONS[SidebarOption.EXPENCES].roles}
      />
    ),
    children: [
      { path: SIDEBAR_OPTIONS[SidebarOption.EXPENCES].path, element: (<AppLayout layout={LayoutType.DASHBOARD}><Expense /></AppLayout>)}
    ],
  },
  {
    path: SIDEBAR_OPTIONS[SidebarOption.SUMMARY].path,
    element: (
      <PrivateRoute
        isLoggedIn={isLoggedIn}
        userRole={role}
        routerRoles={SIDEBAR_OPTIONS[SidebarOption.SUMMARY].roles}
      />
    ),
    children: [
      { path: SIDEBAR_OPTIONS[SidebarOption.SUMMARY].path, element: (<AppLayout layout={LayoutType.DASHBOARD}><Summary /></AppLayout>)}
    ],
  },
  {
    path: SIDEBAR_OPTIONS[SidebarOption.MANAGE_TAGS].path,
    element: (
      <PrivateRoute
        isLoggedIn={isLoggedIn}
        userRole={role}
        routerRoles={SIDEBAR_OPTIONS[SidebarOption.MANAGE_TAGS].roles}
      />
    ),
    children: [
      { path: SIDEBAR_OPTIONS[SidebarOption.MANAGE_TAGS].path, element: (<AppLayout layout={LayoutType.DASHBOARD}><ManageTag /></AppLayout>)}
    ],
  },
  {
    path: SIDEBAR_OPTIONS[SidebarOption.RECURENT_PAYMENT].path,
    element: (
      <PrivateRoute
        isLoggedIn={isLoggedIn}
        userRole={role}
        routerRoles={SIDEBAR_OPTIONS[SidebarOption.RECURENT_PAYMENT].roles}
      />
    ),
    children: [
      { path: SIDEBAR_OPTIONS[SidebarOption.RECURENT_PAYMENT].path, element: (<AppLayout layout={LayoutType.DASHBOARD}><RecurrentPayments /></AppLayout>)}
    ],
  },
  {
    path: '/profile',
    element: (
      <PrivateRoute
        isLoggedIn={isLoggedIn}
        userRole={role}
        routerRoles={[]}/>
    ),
    children: [
      { path: '/profile', element: (<AppLayout layout={LayoutType.DASHBOARD}><Profile /></AppLayout>)}
    ],
  },
  {
    path: '/login',
    element: !isLoggedIn ? <Outlet /> : <Navigate to="/dashboard" />,
    children: [
      { path: '/login', element: (<AppLayout layout={LayoutType.BLANKLAYOUT}><Login /></AppLayout>)}
    ],
  },
  {
    path: '/sign-up',
    element: !isLoggedIn ? <Outlet /> : <Navigate to="/dashboard" />,
    children: [
      { path: '/sign-up', element: (<AppLayout layout={LayoutType.BLANKLAYOUT}><Signup /></AppLayout>)}
    ],
  },
  {
    path: '/reset-password',
    element: !isLoggedIn ? <Outlet /> : <Navigate to="/login" />,
    children: [
      { path: '/reset-password', element: (<AppLayout layout={LayoutType.BLANKLAYOUT}><ResetPassword /></AppLayout>)}
    ],
  },
  {
    path: '/forgot-password',
    element: !isLoggedIn ? <Outlet /> : <Navigate to="/dashboard" />,
    children: [
      { path: '/forgot-password', element: (<AppLayout layout={LayoutType.BLANKLAYOUT}><ForgotPassword /></AppLayout>)}
    ],
  },
  {
    path: '*',
    element: <Navigate to="/login" />,
  }
];

export default routes;
