import FileResizer from "react-image-file-resizer";

const dataURLtoBlob = (dataURL: string): Blob => {
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const byteString = atob(parts[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uintArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uintArray[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type: contentType });
};

export const resizeFile = (file: File,maxWidth: number, maxHeight: number) => new Promise<File>(resolve => {
    FileResizer.imageFileResizer(file, maxWidth, maxHeight, 'JPEG', 100, 0,
    uri => {
        const blob = dataURLtoBlob(uri as string);
        const resizedFile = new File([blob], file.name, { type: 'image/jpeg' });
        resolve(resizedFile);
    }, 'base64' );
});

export const fileToBlob = (file: File): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (reader.result instanceof ArrayBuffer) {
          const blob = new Blob([new Uint8Array(reader.result)], { type: file.type });
          resolve(blob);
        } else {
          reject(new Error('Failed to convert file to blob'));
        }
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
};

