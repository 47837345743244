import { Box, Button, Theme } from "@mui/material";
import { ChangeEvent, useRef, useState } from "react";
import {styled as materialStyled} from "@mui/material/styles";
import AddIcon from '@mui/icons-material/Add';
import AlertDialog from "../AlertDialog/AlertDialog";


export interface ImageSelectBoxProps {
    width?: string,
    height?: string,
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ImageBox = materialStyled(Button)(({ theme }: { theme: Theme }) => ({
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.secondary.main,
    fontSize: 50,
    display: 'flex',
    alignItems:'center',
    justifyContent: 'center',
    borderRadius: 10
}));

export const ImageSelectBox = (props: ImageSelectBoxProps) => {
    const hiddenFileInput = useRef<HTMLInputElement>(null);
    const [openWarning, setOpenWarning] = useState(false);

    const handleFileUploadBtnClick = () => {
        if(hiddenFileInput && hiddenFileInput.current){
          hiddenFileInput.current.click();
        }
      };
    
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && file.type.startsWith('image/')) {
            props.onChange && props.onChange(event);
        } else {
            setOpenWarning(true);
            console.error('Please select a valid image file.');
        }
        };
    return (
        <>
            <Box sx={{width: props.width || 100, height: props.height || 100, margin: .7}}>
                <ImageBox sx={{width: props.width || 100, height: props.height || 100}} onClick={handleFileUploadBtnClick}>
                    <AddIcon fontSize="inherit"></AddIcon>
                </ImageBox>
                <input ref={hiddenFileInput} alt="image" accept="image/*" style={{ display: 'none' }} type="file" onChange={handleFileChange}/>
            </Box>
            <AlertDialog open={openWarning} 
                            title="Invalid File" 
                            description="Please select valid image file" 
                            closeButtonText="OK"
                            handleClose={() => setOpenWarning(false)}
                            hideConfirm></AlertDialog>
        </>
        
    );
}