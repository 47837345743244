import { AxiosResponse } from "axios";
import { backendApiInstance } from "./backendApiInstance";
import { ITag } from "../redux/tag/tag-slice";

export async function createUpdateTagCall(payload: {name: string, color: string}): Promise<AxiosResponse<ITag>> {
    return backendApiInstance.post('/tags/info', payload);
}

export async function getUserTagsCall() : Promise<AxiosResponse<ITag[]>> {
    return backendApiInstance.get('/tags/info');
}

export async function deleteUserTagsCall(tagId: string) : Promise<AxiosResponse<ITag>> {
    return backendApiInstance.delete(`/tags/${tagId}`);
}