import React, { type ReactElement, type ReactNode } from 'react';
import { LayoutType } from '../../utils/enums/LayoutType';
import BlankLayout from './BlankLayout';
import DashboardLayout from './DashboardLayout';

const Layouts = {
  [LayoutType.BLANKLAYOUT]: BlankLayout,
  [LayoutType.DASHBOARD]: DashboardLayout,
};

interface AppLayoutProps {
  layout: LayoutType;
  children: ReactNode;
}

function AppLayout({ layout, children }: AppLayoutProps): ReactElement {
  const Layout = Layouts[layout];

  return <Layout>{children}</Layout>;
}

export default AppLayout;
