import { Box, SxProps, Typography, Button } from "@mui/material";
import {styled as materialStyled, Theme} from "@mui/material/styles";
import { theme } from "../../theme/theme";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

export interface TagBoxProp {
    tagName: string;
    color: string;
    id: string;
    isEditable?: boolean;
    isNoneBordered?: boolean;
    description?: string;
    handleEdit?: (value: {name: string, color: string, id: string, description?: string}) => void;
    handleDelete?: (id: string, name: string) => void;
    onClick?: () => void;
}

const TagConstainer = materialStyled(Box,{
        shouldForwardProp: (prop) => prop !== "isNoneBordered" // <-- Here
    })
    (({ theme, isNoneBordered }: { theme: Theme, isNoneBordered: boolean }) => ({
    border: !isNoneBordered ? `1px solid ${theme.palette.shades.blackBorder}` : 'none' ,
    display: 'flex',
    borderRadius: 8,
    margin: 4
}));

const colorBoxStyle = (color: string): SxProps<Theme> => ({
    width: 20, 
    height: 20, 
    backgroundColor: color, 
    marginRight: 1, 
    borderRadius: 1,
    border: `0.5px solid ${theme.palette.shades.blackBorder}`,
})


export function TagBox({color, tagName, id, description, isEditable, isNoneBordered, handleDelete, handleEdit, onClick}: TagBoxProp) {
    const onHandleEdit = () => {
        if (isEditable && handleEdit) {
            handleEdit({name: tagName, color, id, description});
        }
    }

    return (
        <TagConstainer onClick={onClick} px={1} py={0.5} theme={theme} isNoneBordered={!!isNoneBordered} display="flex" gap="3px">
            <Box sx={colorBoxStyle(color)} onClick={onHandleEdit}/>
            <Typography variant="meta" sx={{cursor: 'pointer'}} onClick={onHandleEdit   }>
                {tagName}
            </Typography>
            {isEditable && <Button sx={{padding: 0, minWidth: '20px'}} onClick={() => handleDelete && handleDelete(id, tagName)}>
                    <DeleteOutlineIcon sx={{fontSize: '17px'}}/>
                </Button>}
        </TagConstainer>
    )
}