import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";

export interface ITag {
  _id: string,
  name: string,
  color: string,
  description?: string,
}

export interface ITagSlice {
  componentState: {[name: string]: undefined|string|boolean},
  tags?: ITag[];
  tagModalOpen: boolean;
  selectedTagInfo: ITag & {isUpdated: boolean}
}

const initialState: ITagSlice = {
  tags: undefined,
  componentState: {},
  tagModalOpen: false,
  selectedTagInfo: {name: '', color: '',_id: '', description: '', isUpdated: false}
};

const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    getTagAsync:(state) => {
      state.componentState.isTagsLoading = true;
    },
    loadTags: (state, action: PayloadAction<ITag[]>) => {
      state.tags = action.payload;
      state.componentState.isTagsLoading = false;
    },
    toggleTagModal: (state, action: PayloadAction<boolean>) => {
      state.tagModalOpen = action.payload;
    },
    createUpdateTagAsync: (state, action: PayloadAction<{name: string, color: string, _id?: string, description?: string}>) => {
      state.componentState.isTagsCreateLoading = true;
      state.componentState.tagError = '';
    },
    deleteTagAsync: (state, action: PayloadAction<string>) => {
    },
    updateComponentState: (state, action: PayloadAction<{[name: string]: undefined|string|boolean}>) => {
      state.componentState = {...state.componentState, ...action.payload};
    },
    updateSelectedTagInfo: (state, action: PayloadAction<ITag & {isUpdated: boolean}>) => {
      state.selectedTagInfo = action.payload;
    }
  }
});
  const tagPersistConfig = {
    key: 'tag',
    storage: storage,
    whitelist: []
  }

const tagActions = tagSlice.actions;
const tagReducer = persistReducer(tagPersistConfig, tagSlice.reducer);

export { tagActions, tagReducer };