import { put, call, takeLatest, ForkEffect } from 'redux-saga/effects';
import { authActions } from './authSlice';
import { signIn, signOut, SignInOutput, updatePassword, type UpdatePasswordInput } from '@aws-amplify/auth';
import { userActions } from '../user/userSlice';
import { signupActions } from '../signup/signupSlice';

interface LogInPayload{
  email: string;
  password: string;
}


function* watchLogIn({payload}: { payload: LogInPayload}) {
  const { email, password } = payload;
  try{
      yield put(authActions.updateComponentState({ error: '' }));
      const response: SignInOutput = yield call(signIn,{username: email, password});
      yield put(authActions.login());
      yield put(signupActions.updateComponentState({
        isSuccessPasswordReset: false,
      }));
      console.log('auth submit', response);
  } catch (err:any) {
      yield put(authActions.updateComponentState({ isPageLoading: false, error: err.message }));
      console.log('Auth error occured: ',err);
  }
  
}

function* watchLogOut() {
  try {
    yield call(signOut);
    yield put(authActions.logout());
    yield put(userActions.cleanUser());
  } catch (e) {
    console.log('Auth error occured: ',e);
  }
}

function* watchChangePassword({payload}: { payload: UpdatePasswordInput}) {
    const {newPassword,oldPassword} = payload;
    try {
      yield call(updatePassword, { oldPassword, newPassword });
      yield put(authActions.updateComponentState({ isChangePasswordLoading: false, isChangePasswordSuccess: true }));
    } catch (err: any) {
      yield put(authActions.updateComponentState({ isChangePasswordLoading: false, error: 'Incorrect Password' }));
    }
}


export function* watchAuthSagas(): Generator<ForkEffect, void> {
  yield takeLatest(authActions.logInAsync, watchLogIn);
  yield takeLatest(authActions.logOutAsync, watchLogOut);
  yield takeLatest(authActions.changePasswordAsync, watchChangePassword);
}
const authSagas = watchAuthSagas;
export default authSagas;
