import React, { useContext, createContext } from 'react';
import { RootState } from '../redux/store';
import { useAppSelector } from "../redux/hooks";


const AuthContext = createContext<any>({});

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: any) {
  const auth  = useAppSelector((state: RootState) => state.auth);
  const user = useAppSelector((state: RootState) => state.user);

  return (<AuthContext.Provider value={{isLogin:auth.isLogin, authChallenges: auth.authChallanges, user}}>{children}</AuthContext.Provider>);
}
