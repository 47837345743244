import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import { authActions } from '../redux/auth/authSlice';
import { store } from '../redux/store';



// Function to refresh the token using the refreshToken


const createAxiosInstance = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_CLOUD_URL,
  });

  // Request interceptor
  instance.interceptors.request.use(
    async (config) => {
      // Add the access token to the request headers if availablE
      const session = await fetchAuthSession();
      if (session.tokens?.idToken) {
        config.headers.Authorization = `Bearer ${session.tokens?.idToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );


  instance.interceptors.response.use(
    function(response) {
      // Check response status or data for conditions triggering logout
      // For example, check if the response status is unauthorized (401)
      if (response.status === 401) {
        // Perform logout action here
        store.dispatch(authActions.logOutAsync);
      }
      // Return the response for further processing
      return response;
    },
    function(error) {
      if (error.name === 'NotAuthorizedException') {
        store.dispatch(authActions.logOutAsync);
      }
      return Promise.reject(error);
    }
  );
  return instance;
}

 export const backendApiInstance = createAxiosInstance();