import { all, fork, AllEffect, ForkEffect } from 'redux-saga/effects';
import authSagas from './auth/authSaga';
import signupSagas from './signup/signupSaga';
import userSagas from './user/userSaga';
import tagSagas from './tag/tag-saga';
import expenseSagas from './expense/expense-saga';
import recurrentSagas from './recurrent/recurrent-saga';

export default function* rootSaga(): Generator<
  AllEffect<ForkEffect<void>>,
  void,
  unknown
> {
  yield all([
    fork(authSagas), 
    fork(signupSagas),
    fork(userSagas),
    fork(tagSagas),
    fork(expenseSagas),
    fork(recurrentSagas)
  ]);
}
