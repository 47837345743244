import { Box, Typography, Button } from "@mui/material";
import {styled as materialStyled, Theme} from "@mui/material/styles";
import { theme } from "../../theme/theme";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IRecurrent } from "../../redux/recurrent/recurrent-slice";
import dayjs from "dayjs";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import { convertNumberToCurrenct } from "../../utils/text-utils";

export interface RecurrentBoxProp {
    recurrent: IRecurrent,
    isEditable?: boolean,
    isNoneBordered?: boolean,
    width?: string,
    onlyMainInfo?: boolean
    handleEdit?: (value: {name: string, type: number, _id: string, amount: number}) => void;
    handleDelete?: (id: string, name: string) => void;
    onClick?: () => void;
}

const RecurrentConstainer = materialStyled(Box,{
        shouldForwardProp: (prop) => prop !== "isNoneBordered" // <-- Here
    })
    (({ theme, isNoneBordered, width}: { theme: Theme, isNoneBordered: boolean, width?: string }) => ({
    border: !isNoneBordered ? `1px solid ${theme.palette.shades.blackBorder}` : 'none' ,
    display: 'flex',
    width: width || '350px',
    borderRadius: 8,
    margin: 4,
    [theme.breakpoints.down('sm')]: {
        width: width || '100%'
    }
}));



export function RecurrentBox({recurrent, isEditable, isNoneBordered, 
                            onlyMainInfo, width, handleDelete, handleEdit, onClick}: RecurrentBoxProp) {

    const onHandleEdit = () => {
        if (isEditable && handleEdit) {
            handleEdit({name: recurrent.name, type:recurrent.type, _id: recurrent._id, amount: recurrent.amount});
        }
    }

    const recurrentType = (type: number) => {
        const typeObj: {[type:number]: string} = {
            0: 'Bill',
            1: 'Subscription',
            2: 'Other'
        }
        return typeObj[type];
    }

    const recurrentTypeColor = (type: number) => {
        const typeObj: {[type:number]: string} = {
            0: '#4CD8DA',
            1: '#F9BB65',
            2: '#F28783'
        }
        return typeObj[type];
    }

    return (
        <RecurrentConstainer onClick={onClick} px={1} py={0.5} theme={theme} width={width} isNoneBordered={!!isNoneBordered} display="flex" gap="3px">
            <Box display="flex" flexDirection="row" width="100%" justifyContent="center">
                <Box padding={onlyMainInfo ? 0 : 1} pr={onlyMainInfo ? 1 : 2}  onClick={onHandleEdit}>
                    <StickyNote2OutlinedIcon sx={{ fontSize: '25px', color: recurrentTypeColor(recurrent.type)}}></StickyNote2OutlinedIcon>
                </Box>
                
                <Box display="flex" flexDirection="column" width="100%"  onClick={onHandleEdit}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
                        <Typography variant="meta" fontWeight={onlyMainInfo ? "normal" : "bold"} sx={{
                            cursor: 'pointer',
                            fontSize: '13px', 
                            color: theme.palette.primary.main,
                            maxWidth: '150px', // Set the maximum width
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',}}>
                            {recurrent.name}
                        </Typography>
                        {recurrent?.amount > 0 && !onlyMainInfo && <Typography variant="meta" sx={{cursor: 'pointer', fontSize: '13px'}}>
                            {convertNumberToCurrenct(recurrent.amount)} Rs
                        </Typography>}
                    </Box>
                    {onlyMainInfo && <Box display="flex" flexDirection="row" alignItems="center">
                            <Box width='4px' height="4px" sx={{backgroundColor: recurrentTypeColor(recurrent.type), borderRadius: '50px', marginRight: '5px'}}></Box>
                            <Typography variant="meta" sx={{cursor: 'pointer',letterSpacing: '1px', fontSize: '10px', opacity: 0.6}} >
                                {recurrentType(recurrent.type)}
                            </Typography>
                    </Box>}
                    {recurrent.lastExpense && recurrent.paidLastMonth && !onlyMainInfo &&
                         <Box display="flex" flexDirection="row" justifyContent="space-between">
                            <Typography variant="meta" sx={{cursor: 'pointer', fontSize: '11px', opacity: 0.6}} >
                                Last payment: {dayjs(recurrent.lastExpense.expenseDate).format('YYYY-MM-DD')}
                            </Typography>
                            <Typography variant="meta" sx={{cursor: 'pointer', fontSize: '11px', opacity: 0.6}}>
                                {convertNumberToCurrenct(recurrent.lastExpense.amount)} Rs
                            </Typography>
                        </Box>}
                    {!onlyMainInfo && <Box mt={recurrent.lastExpense ? 0 : 0.5} display="flex" flexDirection="row" justifyContent="space-between">
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Box width='4px' height="4px" sx={{backgroundColor: recurrentTypeColor(recurrent.type), borderRadius: '50px', marginRight: '5px'}}></Box>
                            <Typography variant="meta" sx={{cursor: 'pointer', fontSize: '12px', opacity: 0.7}} >
                                {recurrentType(recurrent.type)}
                            </Typography>
                        </Box>
                        
                        {recurrent.paidLastMonth && <Typography variant="meta" sx={{cursor: 'pointer'}}>
                            <DoneAllIcon sx={{fontSize: '18px', color: theme.palette.shades.greenGlow }}></DoneAllIcon>
                        </Typography>}
                    </Box>}
                </Box>
                {isEditable && <Button sx={{marginLeft: 1, 
                    padding: 1, paddingLeft:2,  
                    minWidth: '20px', borderLeft: '1px solid ' + theme.palette.grey[300],
                    borderRadius: 0} } onClick={() => handleDelete && handleDelete(recurrent._id, recurrent.name)}>
                    <DeleteOutlineIcon sx={{fontSize: '17px'}}/>
                </Button>}
            </Box>
            
            
        </RecurrentConstainer>
    )
}