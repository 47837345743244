import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ITag } from "../tag/tag-slice"
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import { PaginationWrapper } from "../../utils/dtos/pagination-wrapper";
import dayjs, { Dayjs } from "dayjs";
import { DaysRange } from "../../utils/enums/days-range";
import { SummaryResponse } from "../../utils/dtos/summary-response.dto";
import { IRecurrent } from "../recurrent/recurrent-slice";

export interface IExpense {
    _id: string,
    userId: string,
    name: string,
    description?: string,
    amount: number,
    tags: ITag,
    recurrentPayment: IRecurrent,
    receiptImages?: [string],
    expenseDate: Date,
    createdAt: Date,
    updatedAt: Date,
}
export interface IExpenseSlice {
    componentState: {[name: string]: undefined|string|boolean},
    expense?: IExpense[];
    expenseModalOpen: boolean;
    expenseUpdateModalOpen: boolean,
    recordCount: number;
    pageCount: number;
    imageUrls?: string[];
    isExpenseFilterChange: boolean,
    expenseFilterParams: Partial<IExpenseParams>,
    expensesSummary: Partial<SummaryResponse>
}

const initialState: IExpenseSlice = {
    expense: undefined,
    componentState: {},
    expenseModalOpen: false,
    expenseUpdateModalOpen: false,
    recordCount: 0,
    pageCount: 0,
    imageUrls: [],
    expenseFilterParams: {
      daysRangeType: DaysRange.LAST_MONTH,
      fromDate: dayjs().subtract(7, 'days'),
      toDate: dayjs(),
      page: 0,
      size: 25
    },
    isExpenseFilterChange: false,
    expensesSummary: {}
};

const expenseSlice = createSlice({
    name: 'expense',
    initialState,
    reducers: {
      getExpenseAsync:(state) => {
        state.componentState.isTagsLoading = true;
      },
      toggleExpenseModal: (state, action: PayloadAction<boolean>) => {
        state.expenseModalOpen = action.payload;
      },
      toggleExpenseUpdateModal: (state, action: PayloadAction<boolean>) => {
        state.expenseUpdateModalOpen = action.payload;
      },
      createExpenseAsync: (state, action: PayloadAction<Partial<IExpenseReq> & {images?: File[], _id?: string, updateEnable?: boolean}>) => {
        state.componentState.isExpenseCreateLoading = true;
        state.componentState.expenseError = '';
        state.componentState.status = '';
      },
      fetchExpenseAsync: (state, action: PayloadAction<Partial<IExpenseParams>>) => {
        state.componentState.isExpenseFetchLoading = true;
        state.componentState.expenseFetchError = '';
      },
      deleteExpenseAsync: (state, action: PayloadAction<string>) => {
        state.componentState.isExpenseDeleteLoading = true;
      },
      updateComponentState: (state, action: PayloadAction<{[name: string]: undefined|string|boolean}>) => {
        state.componentState = {...state.componentState, ...action.payload};
      },
      storeExpenseRecords: (state, action: PayloadAction<PaginationWrapper<IExpense[]>>) => {
        state.expense = action.payload.records;
        state.pageCount = action.payload.recordCount;
        state.recordCount = action.payload.recordCount;
        state.componentState.isExpenseFetchLoading = false;
        state.componentState.expenseFetchError = '';
      },
      setFilterParams: (state, action: PayloadAction<Partial<IExpenseParams>>) => {
        state.expenseFilterParams = action.payload;
        state.isExpenseFilterChange = JSON.stringify(state.expenseFilterParams) !== JSON.stringify(initialState.expenseFilterParams)
      },
      resetFilterParams: (state) => {
        state.expenseFilterParams = initialState.expenseFilterParams;
        state.isExpenseFilterChange = false;
      },
      getImageUrlsAsync: (state, action: PayloadAction<string[]>) => {},
      updateImageUrls: (state, action: PayloadAction<string[]>) => {
        state.imageUrls = action.payload;
      },
      storeSummary: (state, action: PayloadAction<SummaryResponse>) => {
        state.expensesSummary = action.payload;
        state.componentState.isExpenseSummaryLoading = false;
      },
      loadSummaryAsync: (state, action: PayloadAction<Partial<IExpenseParams>>) => {
        state.componentState.isExpenseSummaryLoading = true;
      }
    }
  });
    const expensePersistConfig = {
      key: 'expense',
      storage: storage,
      whitelist: []
    }
  
  const expenseActions = expenseSlice.actions;
  const expenseReducer = persistReducer(expensePersistConfig, expenseSlice.reducer);
  
  export { expenseActions, expenseReducer };


  export interface IExpenseReq {
    name: string,
    description?: string,
    amount: number,
    tagId?: string,
    recurrentId?: string,
    expenseDate?: Date,
    receiptImages?: string[],
  }

  export interface IExpenseParams {
    daysRangeType: number,
    fromDate?: Date | Dayjs,
    toDate?: Date | Dayjs,
    tagIds?: string,
    recurrentIds?: string,
    page?: number,
    size?: number,
  }

