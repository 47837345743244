import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";

export interface ISignupSlice {
  isFirstLogin?: boolean;
  email: string;
  name?: string;
  jobTitle?: string;
  company?: string;
  componentState?: {[name: string]: undefined|string|boolean};
  isUserCreated?: boolean;
  isValidResetLink?: boolean;
}

const initialState: ISignupSlice = {
  isFirstLogin: false,
  email: '',
  name: '',
  jobTitle: '',
  company: '',
  componentState: {},
  isUserCreated: false,
  isValidResetLink: true,
};

const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    signup: (state) => {
      state.componentState!.isPageLoading = false;
      state.componentState!.error = '';
      state.isUserCreated = true;
    },
    removeFirstLogin: (state) => {
      state.isFirstLogin = false;
      state.email = '';
    },
    clearEmail:(state) => {
      state.email = '';
    },
    clearUserCreated: (state) => {
      state.isUserCreated = false;
    },
    updateLinkValidity: (state, action: PayloadAction<boolean>) => {
      state.isValidResetLink = action.payload;
      state.componentState!.isValidatePageLoading = false;
    },
    signupAsync: (state, action: PayloadAction<ISignupSlice>) => {
      state.componentState!.isPageLoading = true;
      state.email = action.payload.email;
      state.company = action.payload.company;
      state.name = action.payload.name;
      state.jobTitle = action.payload.jobTitle;
    },
    forgetPasswordLinkSuccess: (state) => {
      state.componentState!.isForgetPasswordLoading = false;
      state.componentState!.isResetLinkSentSuccess = true;
    },
    updateComponentState: (state, action: PayloadAction<{[name: string]: undefined|string|boolean}>) => {
      state.componentState = {...state.componentState, ...action.payload};
    },
    resendResetLinkAsync: (state, action: PayloadAction<{email: string}>) => {
      state.componentState!.isPageLoading = true;
    },
    validateResetPwLinkAsync: (state, action: PayloadAction<{token: string}>) => {
      state.componentState!.isValidatePageLoading = true;
    },
    setPasswordAsync: (state, action: PayloadAction<{password: string, token: string, navigate: NavigateFunction}>) => {
      state.componentState!.isSetPasswordLoading = true;
      state.componentState!.passwordError = '';
    },
    forgetPasswordResetLinkAsync: (state, action: PayloadAction<{email: string}>) => {
      state.componentState!.isForgetPasswordLoading = true;
    }
  },
});
  const signupPersistConfig = {
    key: 'signup',
    storage: storage,
    whitelist: ['isFirstLogin', 'email']
  }

const signupActions = signupSlice.actions;
const signupReducer = persistReducer(signupPersistConfig, signupSlice.reducer);

export { signupActions, signupReducer };