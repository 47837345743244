
export enum SidebarOption{
  DASHBOARD= 'Dashboard',
  MANAGE_TAGS='Manage Tags',
  RECURENT_PAYMENT='Recurent Payment',
  EXPENCES='Expences',
  SUMMARY='Summary',
  CREATE_EXPENSE='CreateExpense',
}

export enum SidebarOptionMobile{
  DASHBOARD= 'Dashboard',
  MANAGE_TAGS='Tags',
  RECURENT_PAYMENT='Recurent',
  EXPENCES='Expences',
  SUMMARY='Summary'
}