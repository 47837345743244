import React, { type ReactElement, useState } from 'react';
import {TextField, Typography, Box, InputAdornment, IconButton, Theme, useTheme, Alert, SxProps} from '@mui/material';
import LoadingButton from "@mui/lab/LoadingButton";
import { authActions } from '../../redux/auth/authSlice';
import { Link } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {styled as materialStyled} from "@mui/material/styles";
import * as _ from "lodash";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import { RootState } from '../../redux/store';
import { signupActions } from '../../redux/signup/signupSlice';
import { theme } from '../../theme/theme';


const CustomTextField = materialStyled(TextField)(({ theme }: { theme: Theme }) => ({
  width:'310px',
  '& .MuiInputBase-root':{
    height: '38px',
    borderRadius:'5px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '297px',
  },
}));

const CustomButton = materialStyled(LoadingButton)(({ theme }: { theme: Theme }) => ({
  borderRadius:'5px',
  marginBottom:'5px',
  width:'310px',
  height:'40px',
  padding:'12px 32px',
  gap:'10px',
  textAlign:'center',
  textTransform:'none',
  [theme.breakpoints.down('sm')]: {
    width: '297px',
  },
}));

const LogInBox = materialStyled(Box)(({ theme }: { theme: Theme }) => ({
  backgroundColor:'white',
  width:'374px',
  // height:'388px',
  borderRadius:'10px',
  boxShadow: `0px 4px 32px 0px ${theme.palette.shades.shadow}`,
  [theme.breakpoints.down('sm')]: {
    width: 'calc(100vw - 20px)',
  },
}));

const AlertStyles: SxProps = {
  position: 'absolute', 
  top: '10px', left: '50%', 
  transform:'translate(-50%)', 
  [theme.breakpoints.down('sm')] : {
    width: 'calc(100vw - 50px)'
  }
  
}


export default function Login(): ReactElement {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const {email, password, componentState} = useAppSelector((state: RootState) => state.auth);
  const {componentState: signupState} = useAppSelector((state: RootState) => state.signup);

  const error = _.get(componentState, 'error', false);
  const isLoading = _.get(componentState, 'isPageLoading', false) as boolean;
  const isPwdResetSuccess = _.get(signupState, 'isSuccessPasswordReset', false) as boolean;

  const formik = useFormik({
    initialValues: {
      email,
      password,
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email('Please enter a valid email address')
        .required('Email is required'),
      password: yup
        .string()
        .required('Password is required'),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: ({email, password}) => {
      dispatch(authActions.logInAsync({email, password}));
    },
  });

  const closeAlert = () => {
    dispatch(signupActions.updateComponentState({
      isSuccessPasswordReset: false,
    }));
  }

  return (
      <LogInBox>
        {isPwdResetSuccess && <Alert severity="success" onClose={closeAlert} sx={AlertStyles}>
          Successfully set the password
        </Alert>}
        <Typography mt={4} mb={2} variant='h5' gutterBottom 
              fontSize='20px' 
              fontWeight='700' textAlign='left' color="primary"
              sx={{marginLeft: '30px', opacity: 0.8}}>Sign in to your account</Typography>
        <Box display='flex' flexDirection='column' justifyContent='space-around' alignItems='center' height='90%'>
          <Box>
            <Typography variant='meta' hidden={!error} component='p' gutterBottom textAlign='center' color='error' width='297px'>Email or Password Incorrect!</Typography>
            <Box mb={2} display='flex' flexDirection='column'>
              <Typography mb={0.5} variant='meta' >Email <Typography component="span" color='primary'>&nbsp;*</Typography></Typography>
              <CustomTextField
                type='email'
                name="email"
                placeholder='username@mail.com'
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                variant="outlined"
                FormHelperTextProps={{style:{...theme.typography.meta}}}
              />
            </Box>
            <Box display='flex' flexDirection='column'>
              <Typography mb={0.5} variant='meta' >Password <Typography component="span" color='primary'>&nbsp;*</Typography></Typography>
              <CustomTextField
                name="password"
                placeholder='password'
                fullWidth
                type={showPassword ? 'text' : 'password'}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                variant="outlined"
                FormHelperTextProps={{style:{...theme.typography.meta}}}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseLeave={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                      >{showPassword ? <VisibilityOff /> : <Visibility />}</IconButton>
                    </InputAdornment>
                }}
              />
            </Box>
          </Box>

          <Box mt={4} columnGap={2} display='flex' flexDirection='column'>
            <CustomButton
              loading={isLoading}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => formik.handleSubmit()}
            >
              Sign in
            </CustomButton>
            <Box mb={2} textAlign="center">
              <Typography pt={2} component='span' sx={{ fontSize: 12, paddingRight: '3px' }}>I don't have an account</Typography>
              <Link style={{ textDecoration: 'none'}} to={'/sign-up'}>
                <Typography variant='meta' sx={{ fontSize: 13, color: theme.palette.linkBlue, fontWeight: 800}}>Sign up</Typography>
              </Link>
            </Box>
            <Box mb={3} display='flex' justifyContent='center'>
              <Link style={{ textDecoration: 'none'}} to={'/forgot-password'}>
                <Typography variant='meta' sx={{ fontSize: 13, color: theme.palette.linkBlue, fontWeight: 800}}>Forgot Password</Typography>
              </Link>
            </Box>
          </Box>
        </Box>
      </LogInBox>
  );
}
