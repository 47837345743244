import axios, { AxiosResponse } from 'axios';

export async function signUpCall(
  email: string,
  name?: string,
  company?: string,
  jobTitle?: string
): Promise<AxiosResponse> {
  const baseUrl = process.env.REACT_APP_CLOUD_URL

  const headers = {
    'Content-Type': 'application/json',
    'x-frontend-host': window.location.origin,
  };

  return axios.post(baseUrl + '/public/users/account', { email, name, company, jobTitle }, { headers });
}

export async function resetPasswordLinkCall(
  email: string
): Promise<AxiosResponse> {
  const baseUrl = process.env.REACT_APP_CLOUD_URL

  const headers = {
    'Content-Type': 'application/json',
    'x-frontend-host': window.location.origin,
  };

  return axios.post(baseUrl + '/public/users/reset-password-link', { email }, { headers });
}

export async function validatePasswordLinkCall(
  token: string,
): Promise<AxiosResponse> {
  const baseUrl = process.env.REACT_APP_CLOUD_URL;

  const headers = {
    'authorization': `${token}`
  };


  return axios.post(baseUrl + '/public/users/validate-password-link', undefined , { headers });
}

export async function updatePasswordCall(
  password: string,
  token: string,
): Promise<AxiosResponse> {
  const baseUrl = process.env.REACT_APP_CLOUD_URL

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': token
  };

  return axios.post(baseUrl + '/public/users/update-password', { password }, { headers });
}
