export const truncateText = (text?: string, length?: number) => {
    if (!length || !text || text.length <= length) return text;

    return text.substring(0, length).concat('...');
}

export const convertNumberToCurrenct = (number?: number) => {
    if (!number) {
        return number;
    }
    return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}