import { Box, Button, Modal, SxProps, Typography } from "@mui/material";
import { theme } from "../../theme/theme";
import { Theme } from "@emotion/react";
import { IExpense, expenseActions } from "../../redux/expense/expense-slice";
import { Close } from "@mui/icons-material";
import { TagBox } from "../TagBox/TagBox";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/hooks";
import { ImageViewBox } from "../ImageViewBox/ImageViewBox";
import AlertDialog from "../AlertDialog/AlertDialog";
import { RecurrentBox } from "../RecurrentBox/RecurrentBox";

export interface ExpenseViewModalProps {
    open: boolean;
    expense: IExpense;
    onClose?: () => void;
    onUpdate?: () => void;
}

const modalStyles: SxProps<Theme> = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    maxHeight: '95vh',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '15px',
    pt: 2,
    px: 4,
    pb: 3,
    scrollbarWidth: 'none', /* Firefox */
    '&::-webkit-scrollbar': {
      display: 'none', /* WebKit */
    },
    [theme.breakpoints.down('sm')] : {
        width: '90%',
    }
};

export function ExpenseViewModal(props: ExpenseViewModalProps) {
    const imageUrls = useAppSelector(state => state.expense.imageUrls)
    const dispatch = useDispatch();
    const [warningDialogOpen, setOpenWarning] = useState<boolean>(false);

    const toggleWarningDialog = () => {
        setOpenWarning(!warningDialogOpen);
    }

    const deleteExpenseRecord = () => {
        dispatch(expenseActions.deleteExpenseAsync(props.expense._id));
        toggleWarningDialog();
        props.onClose && props.onClose();
    }

    useEffect(() => {
        if (props.expense?.receiptImages) {
            dispatch(expenseActions.getImageUrlsAsync(props.expense.receiptImages))
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.expense?.receiptImages])

    return (
        <Modal
            open={props.open}
            aria-labelledby="create expense"
            aria-describedby="This modal use to create expense">
            <Box sx={{...modalStyles}}>
                <Close onClick={props.onClose} sx={{position: 'absolute', right: '10px', cursor:"pointer"}}/>
                <Typography variant='h5' 
                        fontSize='22px'  
                        fontWeight='600'   
                        textAlign='left' 
                        sx={{opacity: 0.8}}>
                    View Expense
                </Typography>

                <Box my={2} display='flex' flexDirection='column'>
                    <Typography mb={0.5} variant='meta' color={'gray'} fontSize="13px" fontWeight="bold">Short Description </Typography>
                    <Typography mb={0.2} variant='meta' color={'black'} fontSize="16px">{props.expense?.name}</Typography>
                </Box>

                <Box my={2} display='flex' flexDirection='column'>
                    <Typography mb={0.5} variant='meta' color={'gray'} fontSize="13px" fontWeight="bold">Amount </Typography>
                    <Typography mb={0.2} variant='meta' color={'black'} fontSize="16px">Rs. {props.expense?.amount?.toFixed(2)}</Typography>
                    
                </Box>

               {props.expense?.tags && <Box my={2} display='flex' flexDirection='column'>
                    <Typography mb={0.5} variant='meta' color={'gray'} fontSize="13px" fontWeight="bold">Tag belongs to</Typography>
                    <Box sx={{transform: 'translate(-10px)'}}>
                        <TagBox color={props.expense?.tags?.color} 
                            tagName={props.expense?.tags?.name} 
                            id={props.expense?.tags?._id}
                            isNoneBordered/>
                    </Box>
                    
                </Box>}

                {props.expense?.recurrentPayment && <Box my={2} display='flex' flexDirection='column'>
                    <Typography mb={0.5} variant='meta' color={'gray'} fontSize="13px" fontWeight="bold">Recurrent payment belongs to</Typography>
                    <Box sx={{transform: 'translate(-10px)'}}>
                        <RecurrentBox recurrent={props.expense?.recurrentPayment}
                                    width={'none'}
                                    isNoneBordered onlyMainInfo/>
                    </Box>
                    
                </Box>}

                <Box my={2} display='flex' flexDirection='column'>
                    <Typography mb={0.5} variant='meta' color={'gray'} fontSize="13px" fontWeight="bold">Expense Date </Typography>
                    {dayjs(props.expense?.expenseDate).format('YYYY-MM-DD HH:mm:ss')}
                </Box>

                {props.expense?.description && <Box my={2} display='flex' flexDirection='column'>
                    <Typography mb={0.5} variant='meta' color={'gray'} fontSize="13px" fontWeight="bold">Description </Typography>
                    {props.expense?.description}
                </Box>}

                {imageUrls && imageUrls?.length > 0 && <Box my={2} display='flex' flexDirection='column'>
                    <Typography mb={0.5} variant='meta' color={'gray'} fontSize="13px" fontWeight="bold">Reciept images </Typography>
                    <Box display='flex' flexDirection='row' flexWrap="wrap">
                        {imageUrls?.map(url=>(<ImageViewBox key={url} width="80px" height="100px" src={url} />))}
                    </Box>
                    
                </Box>}
                <Box display="flex" flexDirection="row" justifyContent="flex-end" gap="10px">
                    <Button variant="text" onClick={toggleWarningDialog}>Delete</Button>
                    {props.onUpdate && <Button variant="text"  onClick={props.onUpdate}>Update</Button>}
                </Box>
                <AlertDialog open={warningDialogOpen} title="Delete Expense Record" 
                            closeButtonText="Close"
                            description="Warning: Expense record will delete permanently"
                            agreeButtonText="Confirm"
                            handleClose={toggleWarningDialog}
                            handleSubmit={deleteExpenseRecord}/>
            </Box>
        </Modal>
    );
}