import { Box, SxProps, Tab, Tabs } from "@mui/material";
import { SIDEBAR_OPTIONS } from "../../utils/sidebar-schema";
import { SidebarOption } from "../../utils/enums/sidebar-option";
import { useNavigate } from "react-router-dom";
import { theme } from "../../theme/theme";
import { expenseActions } from "../../redux/expense/expense-slice";
import { useAppDispatch } from "../../redux/hooks";

export interface MobileTabsProps {
}

const addIconStyle: SxProps = {
    border: '2px solid', 
    borderColor:  theme.palette.primary.main, 
    borderRadius: '50px', 
    width: '35px', height: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

export const MobileTabs = (props: MobileTabsProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const getMobileTabs = () => {
        return [
          SIDEBAR_OPTIONS[SidebarOption.DASHBOARD],
          SIDEBAR_OPTIONS[SidebarOption.EXPENCES],
          SIDEBAR_OPTIONS[SidebarOption.CREATE_EXPENSE],
          SIDEBAR_OPTIONS[SidebarOption.RECURENT_PAYMENT],
          SIDEBAR_OPTIONS[SidebarOption.SUMMARY],
        ]
    }

    const isMobileValidTabPath = () => {
        const location = window.location.pathname; 
        return getMobileTabs().map(item => item.path).includes(location);
      }

    const mobileTabPath = () => {
        const location = window.location.pathname;
        return isMobileValidTabPath() && location;
    }

    const handleSidebarClick = (path:string) => {
        navigate(path, { replace: true });
    }

    const onClickAddExpense = () => {
        dispatch(expenseActions.toggleExpenseModal(true));
    }   


    return (
        <Tabs value={mobileTabPath()} aria-label="Fintrac mobile tab view" >
        {
          getMobileTabs().map(item => {
            if (item.path === '') {
                return <Tab key={item.path} 
                            icon={
                                <Box sx={addIconStyle}>
                                    <item.icon sx={{fontSize: '30px', 
                                        fontWeight: 'normal', 
                                        color: theme.palette.primary.main}} />
                                </Box>
                            } 
                            onClick={() => {onClickAddExpense()}}
                            sx={{minWidth: '0px', minHeight: '0px', fontSize: '15px',
                            paddingLeft: 1.5,paddingRight: 1.5}} />
            }
            return <Tab key={item.path} 
                        icon={<item.icon sx={{fontSize: '20px'}} />} 
                        label={item.mobileName} 
                        value={item.path}
                        onClick={() => {handleSidebarClick(item.path)}}
                        sx={{minWidth: '0px', minHeight: '0px', fontSize: '12px',
                        paddingLeft: 1.5,paddingRight: 1.5}} />
            })
        }
      </Tabs>
    )
}