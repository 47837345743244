import React, { useCallback, useEffect, useState } from 'react';
import { Box, Button, Checkbox, CircularProgress, MenuItem, Modal, Select, SelectChangeEvent, SxProps, Theme, Typography, useMediaQuery } from '@mui/material';
import {styled as materialStyled} from "@mui/material/styles";
import { theme } from '../../theme/theme';
import { DatePicker } from '@mui/x-date-pickers';
import { dayRange } from '../../utils/day-ranges';
import dayjs, { Dayjs } from 'dayjs';
import { TagBox } from '../TagBox/TagBox';
import { useAppSelector } from '../../redux/hooks';
import { useDispatch } from 'react-redux';
import { tagActions } from '../../redux/tag/tag-slice';
import { DaysRange } from '../../utils/enums/days-range';
import { IExpenseParams, expenseActions } from '../../redux/expense/expense-slice';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import { RecurrentBox } from '../RecurrentBox/RecurrentBox';
import { recurrentActions } from '../../redux/recurrent/recurrent-slice';
import { Close } from '@mui/icons-material';
import { arraysEqual } from '../../utils/common-utils';

export interface ExpenseFilterProps {
  isLoading?: boolean;
  refreshAction?: () => void;
  onClose?: () => void;
}

const CustomeSelect = materialStyled(Select)(({padding}:{padding: number})=>({
  padding: padding,
  width: '200px',
  "& .MuiSelect-select": {
  padding: padding,
  },
  "& .MuiSelect-icon": {
  padding: padding,   
  },
  borderRadius: 10,
  [theme.breakpoints.down('sm')]: {
    width: 'calc(100vw - 100px)'
  }
}));

const StyledDatePicker = materialStyled(DatePicker)(({ theme }: { theme: Theme }) => ({
width:'200px',
'& .MuiInputBase-root':{
  height: '35px',
  borderRadius:'10px',
},
[theme.breakpoints.down('sm')]: {
  width: '100%'
}
}));

const StyledButton = materialStyled(Button)(({ theme }: { theme: Theme }) => ({
  height: '35px',
  borderRadius:'10px',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
  }));

  const modalStyles: SxProps<Theme> = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    borderRadius: '10px',
  };

export const ExpenseFilter = (props: ExpenseFilterProps) => {
  const tags = useAppSelector(state => state.tag.tags);
  const recurrents = useAppSelector(state => state.recurrent.recurrents);
  const filter = useAppSelector(state => state.expense.expenseFilterParams);
  const {daysRangeType, tagIds, fromDate, toDate, recurrentIds} = filter;
  const dispatch = useDispatch();

  const isCustomRange = () => (daysRangeType === DaysRange.CUSTOM_RANGE);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isRecurrentSelectAll, setIsRecSelectAll] = useState(false);
  const [isTagSelectAll, setIsTagSelectAll] = useState(false);

  const setValues = useCallback((values: Partial<IExpenseParams>) => {
    dispatch(expenseActions.setFilterParams({...filter, ...values}));
  }, [dispatch, filter]);

  const handleTagSelectChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    if (value.includes('all')) {
      onTagSelectAllClick();
      return;
    }
    const tagIdList = (value as string[])?.filter(val => val);
    const allTagIds = tags?.map(item=>item._id);
    setIsTagSelectAll(arraysEqual(tagIdList, allTagIds || []));
    setValues({tagIds: tagIdList?.join(',')});
  };

  const handleRecurrentSelectChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    if (value.includes('all')) {
      onRecurrentSelectAllClick();
      return;
    }
    const recurrentIdList = (value as string[])?.filter(val => val);
    const allRecIds = recurrents?.map(item=>item._id);
    setIsRecSelectAll(arraysEqual(recurrentIdList, allRecIds || []));
    setValues({recurrentIds: recurrentIdList?.join(',')});

    
  };

  const tagSelectTextRender = (selected: string[]) => {
    const tagsMap = new Map();
    if (tags) {
      for (const tag of tags) {
        tagsMap.set(tag._id, tag.name);
      }
      return selected.map(id=>tagsMap.get(id)).join(',');
    }
    return '';
  }

  const recurrentSelectTextRender = (selected: string[]) => {
    const recurrentsMap = new Map();
    if (recurrents) {
      for (const recurrent of recurrents) {
        recurrentsMap.set(recurrent._id, recurrent.name);
      }
      return selected.map(id=>recurrentsMap.get(id)).join(',');
    }
    return '';
  }


  const resetFilterParams = () => {
    dispatch(expenseActions.resetFilterParams());
  }


  useEffect(() => {
      dispatch(tagActions.getTagAsync());
      dispatch(recurrentActions.getRecurrentAsync());
  }, [dispatch]);

  const onRecurrentSelectAllClick = () => {
    const recurrentIdList = recurrents?.map(item=>item._id);
    const isSelectedAll = arraysEqual(recurrentIdList||[],recurrentIds?.split(',')||[]);
    setIsRecSelectAll(!!!isSelectedAll)
    if (isSelectedAll) {
      setValues({recurrentIds: undefined});
      return
    }
    setValues({recurrentIds: recurrentIdList?.join(',')});
  }

  const onTagSelectAllClick = () => {
    const tagIdList = tags?.map(item=>item._id);
    const isSelectedAll = arraysEqual(tagIdList||[],tagIds?.split(',')||[]);
    setIsTagSelectAll(!!!isSelectedAll)
    if (isSelectedAll) {
      setValues({tagIds: undefined});
      return
    }
    setValues({tagIds: tagIdList?.join(',')});
  }


  const filterView = (
      <Box display='flex' flexDirection='row' flexWrap='wrap' gap='10px' sx={{[theme.breakpoints.down('sm')]: {
          flexDirection: 'column'
        }}}>
      <Box mt={1} display='flex' flexDirection='column'>
        <Typography mb={0.5} variant='meta' color={'gray'} >Days range </Typography>
        <CustomeSelect
            type='text'
            value={daysRangeType}
            placeholder='Select a tag'
            fullWidth
            variant="outlined"
            padding={3}
            onChange={value => setValues({daysRangeType: value.target.value as number})}
        >
            {Object.entries(dayRange)?.map(([key, value]) => (
                <MenuItem key={key} value={value}>{key}</MenuItem>
            ))}
        </CustomeSelect>
      </Box>
      {isCustomRange() && <Box mt={1} display='flex' flexDirection='column'>
        <Typography mb={0.5} variant='meta' color={'gray'} >From Date </Typography>
        <StyledDatePicker
            name="expenseDate" 
            defaultValue={dayjs()}
            disableFuture={true}
            value={fromDate as Dayjs}
            maxDate={(toDate as Dayjs).subtract(1, 'days')}
            onChange={date => setValues({fromDate: date as Dayjs})}/>
      </Box>}
      {isCustomRange() &&<Box mt={1} display='flex' flexDirection='column'>
        <Typography mb={0.5} variant='meta' color={'gray'} >To Date </Typography>
        <StyledDatePicker
            name="expenseDate" 
            defaultValue={dayjs()}
            disableFuture={true}
            value={toDate as Dayjs}
            minDate={(fromDate as Dayjs).add(1, 'days')}
            onChange={date => setValues({toDate: date as Dayjs})}
            />
      </Box>}
      <Box mt={1} display='flex' flexDirection='column'>
        <Typography mb={0.5} variant='meta' color={'gray'} >Select Tags </Typography>
        <CustomeSelect
              type='text'
              name="tagId"
              placeholder='Select a tag'
              fullWidth
              variant="outlined"
              multiple
              padding={3}
              value={tagIds?.split(',') || []}
              onChange={value => handleTagSelectChange(value as SelectChangeEvent<string[]>)}
              renderValue={(selected) => tagSelectTextRender(selected as string[])}
          >
            <MenuItem value="all" sx={{paddingLeft: 0}}>
              <Checkbox checked={isTagSelectAll} />
              <Typography variant='meta' color={'primary'} fontSize="13px" fontWeight="500">Select all</Typography>
            </MenuItem>
            {tags?.map(tag => (
                <MenuItem key={tag._id} value={tag._id} style={{padding: 0}}>
                    <Checkbox checked={(tagIds || '').indexOf(tag._id) > -1} />
                    <TagBox id={tag._id} 
                            color={tag.color} 
                            tagName={tag.name}
                            isNoneBordered={true}/>
                </MenuItem>
            ))}
          </CustomeSelect>
      </Box>

      <Box mt={1} display='flex' flexDirection='column'>
        <Typography mb={0.5} variant='meta' color={'gray'} >Select Recurrents </Typography>
        <CustomeSelect
              type='text'
              name="recurrentIds"
              placeholder='Select a recurrent'
              fullWidth
              variant="outlined"
              multiple
              padding={3}
              value={recurrentIds?.split(',') || []}
              onChange={value => handleRecurrentSelectChange(value as SelectChangeEvent<string[]>)}
              renderValue={(selected) => recurrentSelectTextRender(selected as string[])}
          >
            <MenuItem value="all" sx={{paddingLeft: 0}}>
              <Checkbox checked={isRecurrentSelectAll} />
              <Typography variant='meta' color={'primary'} fontSize="13px" fontWeight="500">Select all</Typography>
            </MenuItem>
            {recurrents?.map(recurrent => (
                <MenuItem key={recurrent._id} value={recurrent._id} style={{padding: 0}}>
                    <Checkbox checked={(recurrentIds || '').indexOf(recurrent._id) > -1} />
                    <RecurrentBox recurrent={recurrent}
                            width='none'
                            onlyMainInfo
                            isNoneBordered/>
                </MenuItem>
            ))}
          </CustomeSelect>
      </Box>
      {props?.refreshAction && <Box mt={1} display='flex' flexDirection='row' alignItems={'end'} 
            sx={{[theme.breakpoints.down('sm')]: {display: 'none'}}}
            onClick={props?.refreshAction}>
        <StyledButton startIcon={<RefreshIcon/>} variant='outlined' color="info"> Refresh</StyledButton>
      </Box>}
      <Box mt={isMobile ? 2 : 1} display='flex' flexDirection='row' alignItems={'end'}
        onClick={resetFilterParams}>
        <StyledButton startIcon={<CloseIcon/>} variant='outlined' color="error"> Clear {isMobile ? 'filter': ''}</StyledButton>
      </Box>
      {props.isLoading && <Box mt={1} display='flex' flexDirection='row' alignItems={'end'}>
        <Box mb={0.5} display='flex' flexDirection='row' alignItems={'center'} gap={1}>
            <CircularProgress size={30} thickness={6}/>
            <Typography variant='meta' color="primary">Loading...</Typography>
        </Box>
        
      </Box>}
    </Box>
  )

  return !isMobile ? filterView : (
    <Modal
            open={true}
            aria-labelledby="create expense"
            aria-describedby="This modal use to create expense">
            <Box sx={{...modalStyles}}>
                <Close onClick={props.onClose} sx={{position: 'absolute', right: '10px', cursor:"pointer"}}/>
                <Typography variant='h5' 
                        fontSize='22px'  
                        fontWeight='600'   
                        textAlign='left' 
                        sx={{opacity: 0.8}}>
                    Filter
                </Typography>
                <Box>
                  {filterView}
                </Box>
            </Box>
    </Modal>
  )
}