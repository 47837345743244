import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { persistReducer } from 'redux-persist'
import storage from "redux-persist/lib/storage";

export interface IUser {
  _id: string;
  email: string;
  name?: string;
  jobTitle?: string;
  company?: string;
  profileImage?: string;
  financialMonthStartDate?: number;
}

export interface IUserSlice extends Required<IUser> {
  componentState: {[name: string]: undefined|string|boolean},
  signedProfileUrl: string
}

const initialState: IUserSlice = {
  _id: '',
  email: '',
  name: '',
  jobTitle: '',
  company: '',
  profileImage: '',
  componentState: {},
  signedProfileUrl: '',
  financialMonthStartDate: 1,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserAsync:(state) => {
      state.componentState.isPageLoading = true;
    },
    loadUser: (state, action: PayloadAction<IUser>) => {
      state.componentState.isPageLoading = false;
      state.componentState.isUpdatePageLoading = false;
      state._id = action.payload._id;
      state.company = action.payload.company || '';
      state.email = action.payload.email;
      state.name = action.payload.name || '';
      state.jobTitle = action.payload.jobTitle || '';
      state.profileImage = action.payload.profileImage || '';
      state.financialMonthStartDate = action.payload.financialMonthStartDate || 1;
    },
    addUserImage: (state, action: PayloadAction<URL>) => {
      state.signedProfileUrl = action.payload.toString();
    },
    updateComponentState: (state, action: PayloadAction<{[name: string]: undefined|string|boolean}>) => {
      state.componentState = {...state.componentState, ...action.payload};
    },
    updateUserAsync: (state, action: PayloadAction<Partial<IUser> & {image?: File}>) => {
      state.componentState.error = '';
      state.componentState.isUpdatePageLoading = true;
    },
    cleanUser: (state) => {
      state.componentState = {};
      state._id = initialState._id;
      state.company = initialState.company;
      state.email = initialState.email;
      state.name = initialState.name;
      state.jobTitle = initialState.jobTitle;
      state.profileImage = initialState.profileImage;
      state.financialMonthStartDate = initialState.financialMonthStartDate;
    }
  }
});
  const signupPersistConfig = {
    key: 'user',
    storage: storage,
    whitelist: ['email', 'name', 'signedProfileUrl']
  }

const userActions = userSlice.actions;
const userReducer = persistReducer(signupPersistConfig, userSlice.reducer);

export { userActions, userReducer };