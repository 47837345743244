import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Theme, Typography, useMediaQuery } from '@mui/material';
import {SxProps, styled as materialStyled} from "@mui/material/styles";
import { Divider } from '@mui/material';
import { useDispatch } from 'react-redux';
import { theme } from '../../theme/theme';
import { ExpenseFilter } from '../../components/Filters/ExpenseFilter';
import { useAppSelector } from '../../redux/hooks';
import { IExpense, IExpenseParams, expenseActions } from '../../redux/expense/expense-slice';
import dayjs, { Dayjs } from 'dayjs';
import { debounce } from 'lodash';
import { expenseTableRows } from '../../utils/data-table-rows';
import { TagBox } from '../../components/TagBox/TagBox';
import RefreshIcon from '@mui/icons-material/Refresh';
import _ from 'lodash';
import { MobileExpenseRecord } from '../../components/MobileExpenseRecord/MobileExpenseRecord';
import { convertNumberToCurrenct, truncateText } from '../../utils/text-utils';
import { ExpenseViewModal } from '../../components/ExpenseViewModal/ExpenseViewModal';
import { CreateExpense } from '../../components/CreateExpence/CreateExpence';
import { RecurrentBox } from '../../components/RecurrentBox/RecurrentBox';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltOffOutlinedIcon from '@mui/icons-material/FilterAltOffOutlined';

const ContainerBox = materialStyled(Box)(({ theme }: { theme: Theme }) => ({
  backgroundColor: theme.palette.white.main,
  maxWidth:'100%',
  width: '100%',
  borderRadius:'20px',
  padding: '1px 20px 20px',
  boxShadow: `0px 4px 32px 0px ${theme.palette.shades.shadow}`,
  [theme.breakpoints.down('sm')]: {
    padding: '1px 15px 15px',
    paddingBottom: '0px',
    overflowX: 'hidden',
    minWidth: 'calc(100vw - 30px)',
    borderRadius:'0px',
    height: `calc(100vh - 124px)`,
    marginTop: '1px',
  }
  
}));

const filterIconStyle = (theme: Theme): SxProps => ({
  color: theme.palette.black.main,
  opacity: 0.7,
  marginTop: 1,
  [theme.breakpoints.up('sm')]: {
    display: 'none'
  }
})

const StyledButton = materialStyled(Button)(({ theme }: { theme: Theme }) => ({
    display: 'flex',
    height: '25px',
    borderRadius:'7px',
    marginBottom: '10px',
    marginLeft: '5px',
    padding: '7px'
  }));

function Expense() {
  const filter = useAppSelector(state => state.expense.expenseFilterParams);
  const {expense: expenses, recordCount, componentState, expenseUpdateModalOpen, isExpenseFilterChange} = useAppSelector(state => state.expense);
  const {daysRangeType, tagIds, fromDate, toDate, page, size, recurrentIds } = filter;

  const isLoading = _.get(componentState, 'isExpenseFetchLoading', false);

  const dispatch = useDispatch();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isExpenseViewOpen, setViewModalOpen] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState<IExpense>({} as IExpense);

  const openViewExpense = (expense: IExpense) => {
    setSelectedExpense(expense);
    setViewModalOpen(true);
  }

  const closeViewModal = () => {
    setSelectedExpense({} as IExpense);
    setViewModalOpen(false);
  }

  const openUpdateExpense = () => {
    setViewModalOpen(false);
    dispatch(expenseActions.toggleExpenseUpdateModal(true));
  }

  const closeUpdateModal = () => {
    setSelectedExpense({} as IExpense);
    dispatch(expenseActions.toggleExpenseUpdateModal(false));
  }

  const debouncedFetchExpense = debounce(() => {
    dispatch(expenseActions.fetchExpenseAsync({
      daysRangeType: daysRangeType, 
      fromDate: (fromDate as Dayjs)?.toDate(),
      toDate: (toDate  as Dayjs)?.toDate(),
      tagIds: tagIds,
      recurrentIds: recurrentIds,
      page: page || 0,
      size: size || 25,
    }));
  }, 1000);

  const setValues = (values: Partial<IExpenseParams>) => {
    dispatch(expenseActions.setFilterParams({...filter, ...values}))
  };

  const onPageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    setValues({page: page});
  }

  const onRowsPerPageChange: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
    const size = event.target.value;
    setValues({size: parseInt(size)})
  };

  useEffect(() => {
    debouncedFetchExpense();
    return () => {
      // Ensure the debounced function is canceled when the component unmounts
      debouncedFetchExpense.cancel();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [daysRangeType,tagIds, fromDate, toDate, page, size,recurrentIds]);

  const refreshAction = () => {
    debouncedFetchExpense()
  }

  const resetFilterParams = () => {
    dispatch(expenseActions.resetFilterParams());
  }

  return (
    <ContainerBox>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Box  display='flex' flexDirection='row' justifyContent='flex-start'>
          <Typography mt={2} variant='h5' fontSize='28px' fontWeight='600' 
                    letterSpacing='1px' textAlign='left' mb={1} color="primary"
                    sx={{[theme.breakpoints.down('sm')]: {fontSize: '23px'}}}>
              Expenses
          </Typography>
          
          {isLoading && !isLargeScreen && <Box mb={1.5} ml={1} display='flex' flexDirection='row' alignItems={'end'}>
            <CircularProgress size={25} thickness={6}/>
          </Box>}

          {!isLoading && !isLargeScreen &&<Box mt={1} display='flex' flexDirection='row' alignItems={'end'}
            onClick={refreshAction}>
            <StyledButton startIcon={<RefreshIcon/>} variant='outlined' color="info"> Refresh</StyledButton>
          </Box>}

          {isExpenseFilterChange && !isLargeScreen && <Box mt={1} display='flex' flexDirection='row' alignItems={'end'}
            onClick={resetFilterParams}>
            <StyledButton startIcon={<FilterListIcon/>} variant='outlined' color="error"> Clear</StyledButton>
          </Box>}

        </Box>
        <Button sx={filterIconStyle(theme)} onClick={() => setFilterOpen(!isFilterOpen)}>
          {isFilterOpen ? <FilterAltOffOutlinedIcon/> : <FilterAltOutlinedIcon/>}
        </Button>
        
      </Box>
      
      {isLargeScreen && <Divider/>}
      {(isLargeScreen || isFilterOpen) && <ExpenseFilter refreshAction={refreshAction} 
                                                          isLoading={isLoading as boolean &&  isLargeScreen}
                                                          onClose={() => setFilterOpen(!isFilterOpen)}/>}

      {isLargeScreen &&<TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {expenseTableRows.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, padding: '8px', 
                  paddingRight: column.id === 'amount' ? '25px' : '8px' }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {expenses && <TableBody>
            {expenses
              .map((row) => {
                return (
                  <TableRow hover key={row._id} onClick={() => openViewExpense(row)}>
                    <TableCell align={'left'} sx={{padding: '8px'}}>
                      {dayjs(row.createdAt).format('YYYY-MM-DD')}
                    </TableCell>
                    <TableCell align={'left'} sx={{padding: '8px'}}>
                      {truncateText(row.name, 50)}
                    </TableCell>
                    <TableCell align={'right'} sx={{padding: '8px', paddingRight: '25px'}}>
                      {convertNumberToCurrenct(row.amount)}
                    </TableCell>
                    <TableCell align={'left'} sx={{padding: '8px', transform: 'translate(-10px)'}}>
                      {row.tags && <TagBox id={row.tags._id} 
                            color={row.tags.color} 
                            tagName={row.tags.name}
                            isNoneBordered={true}/>}
                      {
                        row.recurrentPayment && <RecurrentBox recurrent={row.recurrentPayment}
                                                              width={'none'}
                                                              isNoneBordered onlyMainInfo/>
                      }
                    </TableCell>
                    <TableCell align={'left'} sx={{padding: '8px'}}>
                      {dayjs(row.expenseDate).format('YYYY-MM-DD')}
                    </TableCell>
                    <TableCell align={'left'} sx={{padding: '8px'}}>
                      {truncateText(row.description, 100)}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>}
        </Table>
      </TableContainer>}

      {!isLargeScreen && (
        <Box sx={{overflowY: 'auto', height: `calc(${window.innerHeight}px - 238px)`}}>
           {expenses?.map(ex => <MobileExpenseRecord key={ex._id} 
                                                      expense={ex} 
                                                      onClick={() => openViewExpense(ex)}/>)}
        </Box>
      )}

      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={recordCount}
        rowsPerPage={size || 10}
        page={page || 0}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        sx={{'& .MuiTablePagination-root':{ 
          padding: 0,
          display: 'flex',
          alignItems: 'flex-end',
          flexDirection:'column'
        },
        [theme.breakpoints.down('sm')] : {
          '& .MuiTablePagination-selectLabel': {display: 'none'}
        }}}
      />
      {isExpenseViewOpen && <ExpenseViewModal expense={selectedExpense} open={true} onClose={closeViewModal} onUpdate={openUpdateExpense}/>}
      {expenseUpdateModalOpen && <CreateExpense expense={selectedExpense} open={true} onClose={closeUpdateModal} updateEnable/>}
    </ContainerBox>
  );
}

export default Expense;