import React, { type ReactElement, useState, useEffect } from 'react';
import {TextField, Typography, Box, InputAdornment, IconButton, Theme, useTheme, CircularProgress} from '@mui/material';
import LoadingButton from "@mui/lab/LoadingButton";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {styled as materialStyled} from "@mui/material/styles";
import * as _ from "lodash";
import {useAppDispatch, useAppSelector} from "../../redux/hooks";
import { RootState } from '../../redux/store';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { signupActions } from '../../redux/signup/signupSlice';
import BlankLayout from '../../components/Layouts/BlankLayout';


const CustomTextField = materialStyled(TextField)(({ theme }: { theme: Theme }) => ({
  width:'310px',
  '& .MuiInputBase-root':{
    height: '38px',
    borderRadius:'5px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '297px',
  },
}));

const CustomButton = materialStyled(LoadingButton)(({ theme }: { theme: Theme }) => ({
  borderRadius:'5px',
  marginBottom: '10px',
  width:'310px',
  height:'40px',
  gap:'10px',
  textAlign:'center',
  textTransform:'none',
  [theme.breakpoints.down('sm')]: {
    width: '297px',
  },
}));

const ResetPwBox = materialStyled(Box)(({ theme }: { theme: Theme }) => ({
  backgroundColor:'white',
  width:'374px',
  borderRadius:'10px',
  boxShadow: `0px 4px 32px 0px ${theme.palette.shades.shadow}`,
  [theme.breakpoints.down('sm')]: {
    width: 'calc(100vw - 20px)',
  },
}));


export default function ResetPassword(): ReactElement {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { componentState: sinupState, isValidResetLink} = useAppSelector((state: RootState) => state.signup);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');

  const navigate = useNavigate();
  
  const error = _.get(sinupState, 'passwordError', false);
  const isValidatePageLoading = _.get(sinupState, 'isValidatePageLoading', false) as boolean;
  const isLoading = _.get(sinupState, 'isSetPasswordLoading', false) as boolean;

  const validationSchema = yup.object().shape({
    password: yup.string()
        .min(8, 'Password must be 8 characters long')
        .matches(/[0-9]/, 'Password requires a number')
        .matches(/[a-z]/, 'Password requires a lowercase letter')
        .matches(/[A-Z]/, 'Password requires an uppercase letter')
        .matches(/[^\w]/, 'Password requires a symbol')
        .required('Password is required'),
    confirmPassword: yup.string()
      .oneOf([yup.ref('password')], 'Passwords must match')
      .required('Confirm Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: ({password}) => {
      if (token) {
        dispatch(signupActions.setPasswordAsync({password, token, navigate}));
      }
    },
  });

  useEffect(() => {
    if (token) {
      dispatch(signupActions.validateResetPwLinkAsync({token}))
    } else {
      dispatch(signupActions.updateLinkValidity(false))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  const RestPassword = (
      <ResetPwBox>
        <Typography mt={4} mb={1} variant='h5' gutterBottom 
              fontSize='20px' 
              fontWeight='700' textAlign='left' color="primary"
              sx={{marginLeft: '30px', opacity: 0.8}}>Reset Password</Typography>
        <Box mt={2} display='flex' flexDirection='column' justifyContent='space-around' alignItems='center' height='90%'>
          <Box>
            <Typography variant='meta' hidden={!error} component='p' gutterBottom textAlign='left' color='error' width='297px'>{error}</Typography>
            <Box mb={2} display='flex' flexDirection='column'>
              <Typography mb={0.5} variant='meta' >Password <Typography component="span" color='primary'>&nbsp;*</Typography></Typography>
              <CustomTextField
                type={showPassword ? 'text' : 'password'}
                name="password"
                placeholder='password'
                fullWidth
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                variant="outlined"
                FormHelperTextProps={{style:{...theme.typography.meta}}}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseLeave={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                      >{showPassword ? <VisibilityOff /> : <Visibility />}</IconButton>
                    </InputAdornment>
                }}
              />
            </Box>
            <Box display='flex' flexDirection='column'>
              <Typography mb={0.5} variant='meta' >Confirm Password <Typography component="span" color='primary'>&nbsp;*</Typography></Typography>
              <CustomTextField
                name="confirmPassword"
                placeholder='confirm password'
                fullWidth
                type={showConfirmPassword ? 'text' : 'password'}
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                variant="outlined"
                FormHelperTextProps={{style:{...theme.typography.meta}}}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                        onMouseLeave={(event: React.MouseEvent<HTMLButtonElement>) => event.preventDefault()}
                      >{showConfirmPassword ? <VisibilityOff /> : <Visibility />}</IconButton>
                    </InputAdornment>
                }}
              />
            </Box>
          </Box>

          <Box mt={4} columnGap={5} display='flex' flexDirection='column' justifyContent='space-around'>
            <CustomButton
              loading={isLoading}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => formik.handleSubmit()}
            >
              Reset Password
            </CustomButton>
          </Box>
        </Box>
        <Box mb={3} textAlign="center">
          <Typography component='span' sx={{ fontSize: 13, paddingRight: '3px', opacity: 0.9 }}>I need to go back to</Typography>
          <Link style={{ textDecoration: 'none'}} to={'/login'}>
            <Typography variant='meta' sx={{ fontSize: 13, color: theme.palette.linkBlue, fontWeight: 800}}>Login</Typography>
          </Link>
        </Box>
      </ResetPwBox>
  );

  const ExpiredMessage = (
    <ResetPwBox >
      <Box sx={{padding: '20px'}}>
        <Typography mb={1} variant='h5' gutterBottom 
                fontSize='20px' 
                fontWeight='700' textAlign='left' color="primary"
                sx={{ opacity: 0.9}}>Link Expired</Typography>

        <Box mt={2} sx={{textAlign: 'left'}}>
          <Typography  variant='meta'
            fontSize='14px' 
            fontWeight='100' textAlign='left' color="primary"
            sx={{opacity: 0.9}}>
            An account verification link has been expired, Please obtain new link.</Typography>
        </Box>

        <Box mt={3} >
          <Typography  variant='meta'
            fontSize='14px' 
            fontWeight='100' textAlign='left' color="primary"
            sx={{opacity: 0.9}}>I need to go back to</Typography>
          <Link style={{ textDecoration: 'none', marginLeft: '5px'}} to={'/login'}>
            <Typography variant='meta' sx={{ fontSize: 13, color: theme.palette.linkBlue, fontWeight: 800}}>Sign in</Typography>
          </Link>
        </Box>
      </Box>
    </ResetPwBox>
  )

  const Loading = <BlankLayout><CircularProgress size={80}/></BlankLayout>

  if (isValidatePageLoading) {
    return Loading;
  }

  if (isValidResetLink) {
    return RestPassword;
  }

  return ExpiredMessage;
}
