/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d82f2a4f-d307-43c0-bf49-126397c76a2e",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_AQfkxhHsh",
    "aws_user_pools_web_client_id": "6goba8lu4hi7rsbg14u86ioe7n",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "NAME",
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_user_files_s3_bucket": "fintrack-dev-image-bucket",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
