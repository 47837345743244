import { getUrl } from 'aws-amplify/storage';

export async function getImageUrl(fileName: string, option?: {accessLevel: 'guest'|'private'|undefined}) {
    const getUrlResult = await getUrl({
        key: fileName,
        options: {
          accessLevel: option?.accessLevel, // id of another user, if `accessLevel` is `guest`
          validateObjectExistence: false,  // defaults to false
          expiresIn: 20, // validity of the URL, in seconds. defaults to 900 (15 minutes) and maxes at 3600 (1 hour)
          useAccelerateEndpoint: false, // Whether to use accelerate endpoint.
        },
      });
    return getUrlResult;
}

