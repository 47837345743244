import React, { type ReactElement, type ReactNode } from 'react';
import {Box, styled as materialStyled} from "@mui/material";
import {Container, Theme, Typography} from "@mui/material";
import Logo from "../../assets/img/logo.png";

interface BlankLayoutProps {
  children: ReactNode;
}

const CustomContainer = materialStyled(Container)(({ theme }: { theme: Theme }) => ({
  backgroundColor:theme.palette.secondary.main,
  height:'100vh',
  display:'flex',
  flexDirection:'column',
  justifyContent:'center',
  alignItems: 'center',
  paddingBottom:'15px',
  gap: '10px'
}));

const AppName = materialStyled(Typography)(({ theme }: { theme: Theme }) => ({
  color:theme.palette.shades.logo,
  fontWeight:700,
}));

const FooterNote = materialStyled(Typography)(({ theme }: { theme: Theme }) => ({
  textAlign:'start',
  color: `${theme.palette.info.main}`,
  marginLeft: '5px'
}));

function BlankLayout({ children }: BlankLayoutProps): ReactElement {
  return (
    <CustomContainer maxWidth={false}>
      <Box display='flex' gap='10px' flexDirection='column'>
        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'start', justifyContent: 'start'}}>
          <Box component='img' src={Logo} sx={{width: '18px',marginTop:'5px', marginRight: '10px'}}/>
          <AppName variant='meta' sx={{ fontSize: '25px'}}>Fintrack</AppName>
        </Box>
      
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{children}</Box>
        <FooterNote variant='subtitle1'>{new Date().getFullYear()} Arky Technologies. All rights reserved.</FooterNote>
      </Box>
      
    </CustomContainer>
  );
}

export default BlankLayout;
