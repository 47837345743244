import dayjs from "dayjs";

export interface Column {
    id: string;
    label: string;
    minWidth?: number;
    align?: 'right' | 'left';
    format?: (value: number | Date) => string;
}

export const expenseTableRows: Column[] = [
    {
        id: 'expenseDate',
        label: 'Payment Date',
        minWidth: 130,
        align: 'left',
        format: (date: number|Date) => dayjs((date as Date)).format('YYYY-MM-DD')
    },
    {
        id: 'name',
        label: 'Name',
        minWidth: 150,
        align: 'left',
    },
    {
        id: 'amount',
        label: 'Amount',
        minWidth: 120,
        align: 'right',
        format: (value: number|Date) => (value as number).toFixed(2)
    },
    {
        id: 'tags',
        label: 'Tag/Recurrent',
        minWidth: 180,
        align: 'left',
    },
    {
        id: 'createdAt',
        label: 'Created Date',
        minWidth: 150,
        align: 'left',
        format: (date: number|Date) => dayjs((date as Date)).format('YYYY-MM-DD')
    },
    
    {
        id: 'description',
        label: 'Description',
        minWidth: 170,
        align: 'left',
    },
]