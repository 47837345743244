import React, { useEffect, useState } from 'react';
import {Theme, styled as materialStyled, useTheme} from "@mui/material/styles";
import { Box, CircularProgress, Divider, SxProps, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Add from '@mui/icons-material/Add';
import { useAppSelector } from '../../redux/hooks';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import AlertDialog from '../../components/AlertDialog/AlertDialog';
import { recurrentActions } from '../../redux/recurrent/recurrent-slice';
import { RecurrentBox } from '../../components/RecurrentBox/RecurrentBox';
import { RecurrentType } from '../../utils/enums/recurrent-type';
import { theme } from '../../theme/theme';
import { convertNumberToCurrenct } from '../../utils/text-utils';
import { getLastPassedDate } from '../../utils/common-utils';

const ContainerBox = materialStyled(Box)(({ theme }: { theme: Theme }) => ({
  backgroundColor:'white',
  maxWidth:'800px',
  borderRadius:'20px',
  boxShadow: `0px 4px 32px 0px ${theme.palette.shades.shadow}`,
  padding: '20px',
  [theme.breakpoints.down('sm')]: {
    minWidth: 'calc(100vw - 30px)',
    borderRadius:'0px',
    height: `calc(100vh - 124px)`,
    marginTop: '1px',
    paddingTop: '10px',
    display: 'flex',
    flexDirection: 'column',
  }
}));

const CustomButton = materialStyled(LoadingButton)(({ theme }: { theme: Theme }) => ({
  borderRadius:'10px',
  marginBottom:'5px',
  height:'43px',
  padding:'12px 32px',
  gap:'10px',
  textAlign:'center',
  textTransform:'none',
  [theme.breakpoints.down('sm')]: {
    marginBottom:'0px',
  }
}));

const recurrentSummaryStyle = (): SxProps => ({
  width: '300px',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
})

function RecurrentPayments() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const recurrentModalOpen = useAppSelector(state => state.recurrent.recurrentModalOpen);
  const {recurrents, componentState} = useAppSelector(data => data.recurrent);
  const { financialMonthStartDate } = useAppSelector(state => state.user);

  const isRecurrentLoading = _.get(componentState, 'isRecurrentLoading', false);
  const [deleteRecurrent, setDeleteRecurrent] = useState({name: '', id: '', isOpen: false});
  const [estimatedAmount, setEstimatedAmount] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);

  const openModal = () => {
    dispatch(recurrentActions.toggleRecurrentModal(true));
  }

  const handleUpdate = ({name, amount, _id, type}: {_id: string, name: string, amount: number, type: number,}) => {
    dispatch(recurrentActions.updateSelectedRecurrentInfo({name, amount, _id, type, isUpdated: true}));
    dispatch(recurrentActions.toggleRecurrentModal(true));
  }

  const handleDeleteRecurrent = (id: string, name: string) => {
    setDeleteRecurrent({name: name, id: id, isOpen: true})
  }

  const handleDeleteRecurrentWarningClose = () => {
    setDeleteRecurrent({name: '', id: '', isOpen: false})
  }

  const handleDeleteRecurrentWarningAgree = () => {
    dispatch(recurrentActions.deleteRecurrentAsync(deleteRecurrent.id));
    setDeleteRecurrent({name: '', id: '', isOpen: false});
  }

  useEffect(() => {
    dispatch(recurrentActions.getRecurrentAsync());
  },[dispatch]);

  useEffect(() => {
    const filteredRecurrents = recurrents?.filter(item => item.amount>0);
    if (filteredRecurrents && filteredRecurrents.length > 0) {
        const estimatedCalAmount = filteredRecurrents.map(item => item.amount).reduce((x,y) => x+y) || 0;
        setEstimatedAmount(estimatedCalAmount);
    }
    
    const lastFinanceMonthStartDatePassDate = getLastPassedDate(financialMonthStartDate)
    const filteredPatdRecurrents = recurrents?.filter(item => item.lastExpense)
          .filter(item => new Date(item.lastExpense!.expenseDate) >= lastFinanceMonthStartDatePassDate);
    if (filteredPatdRecurrents && filteredPatdRecurrents.length > 0) {
      const paidCalAmount = filteredPatdRecurrents.map(item => item.lastExpense?.amount || 0).reduce((x,y) => x+y) || 0;
      setPaidAmount(paidCalAmount);
    }
  },[recurrents, financialMonthStartDate])

  useEffect(() => {
    if (!recurrentModalOpen) {
      dispatch(recurrentActions.updateSelectedRecurrentInfo({name: '', type: RecurrentType.BILL, _id: '',amount: 0, isUpdated: false}));
    }
    
  },[recurrentModalOpen, dispatch]);


  return (
    <React.Fragment>
      <ContainerBox>
          <Typography variant='h5' fontSize='28px' fontWeight='600' 
                      letterSpacing='1px' textAlign='left' mb={1} color="primary"
                      sx={{[theme.breakpoints.down('sm')]: {fontSize: '23px'}}}>
              Recurrent Payment
          </Typography>
          <Divider sx={{borderColor: `${theme.palette.secondary.dark}50`, marginBottom: '5px'}}/>

          <Box sx={{[theme.breakpoints.down('sm')]: {
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center'
                }}}>
            <CustomButton
                  sx={{marginTop: 2, marginBottom: 1, display: 'flex'}}
                  startIcon={<Add/>}
                  loading={false}
                  variant="outlined"
                  color="primary"
                  onClick={openModal}>
              Create recurrent payment
            </CustomButton>
          </Box>
          <Box mt={1} sx={recurrentSummaryStyle()}>
            <Typography variant='meta' fontSize='15px' fontWeight='600' textAlign='left' mb={1} color="primary">
                Recurrent Summary
            </Typography>
            {estimatedAmount > 0 && <Box px={1} mt={1} display='flex' flexDirection="row" justifyContent="space-between" sx={{opacity: 0.8}}>
              <Typography variant='meta' fontSize='13px' fontWeight='500' textAlign='left' color="primary">
                  Estimated amount:
              </Typography>
              <Typography variant='meta' fontSize='13px' fontWeight='500' textAlign='left' color="primary">
                  {convertNumberToCurrenct(estimatedAmount)} Rs
              </Typography>
            </Box>}
            {paidAmount > 0 && <Box px={1} mt={0.3} display='flex' flexDirection="row" justifyContent="space-between" sx={{opacity: 0.8}}>
              <Typography variant='meta' fontSize='13px' fontWeight='500' textAlign='left'color="primary">
                  Last paid amount:
              </Typography>
              <Typography variant='meta' fontSize='13px' fontWeight='500' textAlign='left' color="primary">
                  {convertNumberToCurrenct(paidAmount)} Rs
              </Typography>
            </Box>}
          </Box>
          <Box display='flex' flexWrap='wrap' mt={3} 
              sx={{wordWrap: 'normal', flexGrow: 1, overflowY: 'auto'}}>
            {recurrents?.map(recurrent => <RecurrentBox key={recurrent._id} 
                                                        recurrent={recurrent} 
                                                        handleDelete={handleDeleteRecurrent}
                                                        isEditable={true}
                                                        handleEdit={handleUpdate}/>)}
            {isRecurrentLoading && <Box ml={2} mt={1}><CircularProgress size={30}/></Box>}
          </Box>
        <AlertDialog open={deleteRecurrent.isOpen} 
                    title='Warning: Delete Recurrent payment'
                    description={`Are you sure, you want to delete recurrent payment: ${deleteRecurrent.name}`}
                    handleClose={handleDeleteRecurrentWarningClose}
                    handleSubmit={handleDeleteRecurrentWarningAgree}
                    closeButtonText='Close'
                    agreeButtonText='Delete'/>
      </ContainerBox>
    </React.Fragment>
    
  );
}

export default RecurrentPayments;